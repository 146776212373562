#introContact{
	height: 40.88541666666667vw;
	#left,
	#right{
		width: 50%;
		height: 100%;
		float: left;
	}
	#left{
		#map{
			width: 100%;
			height: 100%;
		}
	}
	#right{
		overflow: hidden;
		background-size: cover;
		video{
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}


#formContact{
	background-image: url(../images/bg-contact.png);
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center center;
	padding: 7.395833333333334vw 0 6.197916666666667vw 0;
	.circle-d{
		position: absolute;
		top: 3.9583333333333335vw;
		right: 13.177083333333334vw;
	}
	.wrap{
		position: relative;
		margin: 0 auto;
		max-width: 81.66666666666667vw;
		.vague-rond{
			position: absolute;
			left: 0;
			bottom: 15.052083333333334vw;
		}
		h1{
			color: $bleu-p;
			font-size: 6.770833333333334vw;
			font-family: $r-black;
			line-height: 100%;
			letter-spacing: 0.01em;
			margin: 0 0 2.0833333333333335vw 0;
			opacity: 0.25;
			text-transform: uppercase;
		}
		.contenu{
			background: $bleu-f;
			position: relative;
			padding: 4.791666666666667vw 0 5.104166666666667vw;
			#left{
				width: 24.739583333333336vw;
				padding: 0 5.208333333333334vw 0 4.0625vw;
				h4{
					color: $bleu-p;
					font-size: 1.5625vw;
					font-family: $s-reg;
					line-height: 100%;
					letter-spacing: 0.01em;
					margin: 0 0 1.5625vw 0;
				}
				p{
					color: #fff;
					font-size: 0.8854166666666667vw;
					font-family: $r-reg;
					line-height: 1.09375vw;
					letter-spacing: 0.01em;
				}
				#coords{
					margin: 25px 0 0 0;
					a{
							display: block;
							color: #fff;
						}
					span{
						display: block;
						color: #fff;
						font-size: 0.8854166666666667vw;
						font-family: $r-reg;
						line-height: 1.09375vw;
						letter-spacing: 0.01em;
					}

					#social{
						h5{
							display: block;
							color: $bleu-p;
							font-size: 0.8854166666666667vw;
							font-family: $r-med;
							line-height: 1.09375vw;
							letter-spacing: 0.01em;
							text-transform: uppercase;
							margin: 1.3020833333333335vw 0 0 0;
						}
						ul{
							display: flex;
							margin: 0.625vw 0 0 0;
							li{
								position: relative;
								border: 2px solid #fff;
								width: 1.5104166666666667vw;
								height: 1.5104166666666667vw;
								border-radius: 1.5104166666666667vw;
								& + li{
									margin: 0 0 0 0.5208333333333334vw;
								}
								a{
									position: relative;
									display: block;
									width: 100%;
									height: 100%;
									color: #fff;
									i{
										position: absolute;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%);
									}
								}
							}
						}
					}
				}
			}
			#right{
				background: $bleu-t;
				position: absolute;
				top: 50%;
				right: 3.8020833333333335vw;
				transform: translate(0, -50%);
				width: calc(100% - 24.739583333333336vw - 3.8020833333333335vw);
				#rightInt{
					padding: 5.260416666666667vw 4.479166666666667vw 4.322916666666667vw;
					.gform_wrapper{

						form{
							&:after{
								display: table;
								content: "";
								clear: both;
							}
							.validation_error{
								color: #ff0000;
								margin: 0 0 1.5625vw 0;
							}
							.gform_body{
								ul{
									margin: 0;
									padding: 0;
									width: 100%;
									li{
										width: 100%;
										clear: both;
										padding: 0;
										margin: 0;
										& + li{
											margin: 1.8229166666666667vw 0 0 0;
										}
										&.gfield_error{

											.ginput_container{
												input,
												select,
												textarea{
													border-bottom: 1px solid #ff0000 !important;
												}
											}
										}
										label{
											color: $bleu-p;
											font-size: 1.3541666666666667vw;
											font-family: $s-reg;
											line-height: 100%;
											letter-spacing: 0.01em;
											margin: 0 0 4px 0;
											.gfield_required{
												display: none;
											}
										}
										.ginput_container{
											width: 100%;
											.validation_message{
												display: none;
											}
											input,
											select,
											textarea{
												background: transparent;
												width: 100%;
												border: none;
												outline: 0;
												border-bottom: 1px solid #fff;
												padding: 0.78125vw 0;
												color: #fff;
												font-size: 1.5625vw;
												font-family: $r-reg;
												line-height: 100%;
												letter-spacing: 0.01em;

												&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
												  color: #fff;
												}
												&::-moz-placeholder { /* Firefox 19+ */
												  color: #fff;
												}
												&:-ms-input-placeholder { /* IE 10+ */
												  color: #fff;
												}
												&:-moz-placeholder { /* Firefox 18- */
												  color: #fff;
												}
											}
											input{

											}
											select{
												option{
													color: #000;
												}
											}
											textarea{

											}
										}
									}
								}
							}
							.gform_footer{
								display: table;
								float: right;
								position: relative;
								&:after{
									display: block;
									content: "";
									background: $bleu-p;
									position: absolute;
									width: 100%;
									height: 2px;
									bottom: 0px;
								}
								input{
									position: relative;
									display: table;
									color: $bleu-p;
									font-size: 1.09375vw;
									font-family: $r-med;
									letter-spacing: 0.01em;
									text-transform: uppercase;
									clear: both;
									transition-property: color;
									transition-duration: 0.3s;
									transition-delay: 0.3s;
									z-index: 1;
									cursor: pointer;
									padding: 0;
									background: transparent;
									border: none;
									margin: 15px 0 0 0;
									outline: 0;
									transition: 0.4s;
									padding: 10px;
									&:hover{
										background: $bleu-p;
										color: #fff;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	#urgenceContact{
		margin: 12.5vw 0 0 0;
		.goutte{
			position: absolute;
			left: -.78125vw;
			bottom: 3.3333333333333335vw;
			z-index: 9;
		}
		.corpsWrap{
			width: 100%;
			max-width: calc(100% - (9.010416666666668vw));
			margin: 0 auto;
			height: 100%;
			float: right;
			.contenu{
				position: relative;
				height: 35.78125vw; 
				@media screen and (max-width: 1280px){
					height: auto;
				}
				#left{
					background: #fff;
					width: 65.36458333333334vw;
					height: calc(100% - 1.8229166666666667vw);
					@media screen and (max-width: 1280px){
						height: 620px;
						padding: 60px 0;
					}
					@media screen and (max-width: 1200px){
						width: 80%;
					}
					@media screen and (max-width: 767px){
						width: 100%;
					}
					@media screen and (max-width: 580px){
					
						 height: 720px;
					}

					   
					.leftInt{
						position: absolute;
						top: 50%;
						left: 4.114583333333334vw;
						transform: translate(0, -50%);
						max-width: 34.375vw;
						@media screen and (max-width: 1280px){
							position: relative;
							top: initial;
							transform: initial;
						}
						@media screen and (max-width: 1200px){
							max-width: 70%;
						}
						@media screen and (max-width: 767px){
							max-width: 80%;
						}
						h3{
							color: $color-text;
							font-size: 4.0625vw;
							font-family: $s-reg;
							letter-spacing: 0.01em;
							line-height: 4.0625vw;
							@media screen and (max-width: 1280px){
								font-size: 52px;
								line-height: 52px;
							}
						}
						p{
							color: $color-text;
							font-size: 0.8854166666666667vw;
							font-family: $r-reg;
							letter-spacing: 0.01em;
							line-height: 1.3541666666666667vw;
							margin: 3.4375vw 0 4.583333333333334vw 0;
							@media screen and (max-width: 1280px){
								font-size: 15px;
								line-height: 24px;
								margin: 40px 0 60px 0;
							}
						}
						a{
							position: relative;
							display: table;
							color: $bleu-p;
							font-size: 1.09375vw;
							font-family: $r-med;
							letter-spacing: 0.01em;
							text-transform: uppercase;
							clear: both;
							transition-property: color;
							transition-duration: 0.3s;
							transition-delay: 0.3s;
							z-index: 1;
							height: 1.6145833333333335vw;
							@media screen and (max-width: 1280px){
								font-size: 14px;
								height: 20px;
							}
							&:before{
								background: $bleu-p;
								position: absolute;
								display: table;
								content: "";
								bottom: -0.625vw;
								width: 0px;
								height: 0px;
								transition-property: width, height;
								transition-duration: 0.3s, 0.3s;
								transition-delay: 0.3s, 0.3s;
								z-index: -1;
								left: 50%;
								transform: translate(-50%, 0);
							}
							&:after{
								background: $bleu-p;
								position: absolute;
								display: table;
								content: "";
								bottom: -0.625vw;
								width: 100%;
								height: 4px;
								transition-property: width;
								transition-duration: 0.3s;
								@media screen and (min-width: 1920px){
									height: 0.20833333333333334vw;
								}
							}
							&:hover{
								color: #fff;
								&:before{
									width: calc(100% + 1.5625vw);
									height: calc(100% + (0.625vw * 2));
								}
								&:after{
									width: 0;
								}
							}
						}
					}
				}
				#right{

					background-image: url(../images/camion.png);
					background-size: auto 100%;
					background-position: left center;
					background-repeat: no-repeat;
					position: absolute;
					width: 49.739583333333336vw;
					height: 33.85416666666667vw;
					right: 0;
					bottom: 0;
					@media screen and (max-width: 1200px){
// 						transform: translateX(14vw);
					}
					@media screen and (max-width: 767px){
						display: none;
						    width: 50vw;
    				height: 50vw;
					}
										@media screen and (max-width: 580px){
						display: none;
						    width: 60vw;
    				height: 60vw;
					}
				}
			}
		}
	}
}