#introServ{
	padding: 7.916666666666667vw 0 18.697916666666668vw 0;
	min-height: 58.07291666666667vw;
	.vague-rond{
		position: absolute;
		top: 4.166666666666667vw;
		right: calc(45.520833333333336vw - (15.15625vw / 2));
		z-index: 2;
	}
	.circle-d{
		position: absolute;
		left: 4.84375vw;
		bottom: 8.072916666666668vw;
	}
	&:after{
		background: $bleu-t;
		position: absolute;
		display: block;
		content: "";
		width: 45.520833333333336vw;
		height: 100%;
		top: 0;
		right: 0;
	}
	.contenu{
		padding: 0 0 0 4.84375vw;
		max-width: 34.375vw;
		h1{
			max-width: 25.572916666666668vw;
			color: $bleu-f;
			font-size: 4.0625vw;
			font-family: $s-reg;
			line-height: 4.0625vw;
			margin: 0 0 2.8645833333333335vw 0;
			letter-spacing: 0.01em;
		}
		p{
			color: $bleu-f;
			font-size: 0.8854166666666667vw;
			font-family: $r-reg;
			line-height: 1.3541666666666667vw;
			letter-spacing: 0.01em;
			& + p{
				margin: 1.0416666666666667vw 0 0 0;
			}
		}
	}

	#introImg{
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		width: 53.75vw;
		height: 33.38541666666667vw;
		right: 0;
		bottom: 9.895833333333334vw;
		z-index: 1;
	}
}

#buyBroken{
	background-color: $bleu-f;
	background-image: url(../images/glace-broken.png);
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center bottom;
	height: 39.79166666666667vw;
	.goutte-w{
		position: absolute;
		right: 4.84375vw;
		top: calc(-15.46875vw + 1.5104166666666667vw);
		z-index: 3;
	}
	.contenu{
		position: relative;
		padding: 7.03125vw 0 0 0;

		h1{
			color: #fff;
			font-size: 4.0625vw;
			font-family: $s-reg;
			line-height: 4.0625vw;
			letter-spacing: 0.01em;
			float: left;
			width: calc(100% - 45.520833333333336vw);
			padding: 0 0 0 8.854166666666668vw;
		}
		#right{
			float: left;
			width: 100%;
			max-width: 45.520833333333336vw;
			p{
				max-width: 36.19791666666667vw;
				color: #f4f4f4;
				font-size: 0.8854166666666667vw;
				font-family: $r-reg;
				line-height: 1.3541666666666667vw;
				letter-spacing: 0.01em;
				& + p{
					margin: 1.0416666666666667vw 0 0 0;
				}
			}
			span{
				position: relative;
				display: table;
				color: $bleu-p;
				font-size: 1.09375vw;
				font-family: $r-med;
				letter-spacing: 0.01em;
				text-transform: uppercase;
				clear: both;
				transition-property: color;
				transition-duration: 0.3s;
				transition-delay: 0.3s;
				z-index: 1;
				height: 1.6145833333333335vw;
				cursor: pointer;
				margin: 1.8229166666666667vw 0 0 0;
				@media screen and (max-width: 1280px){
					font-size: 14px;
					height: 20px;
				}
				@media screen and (max-width: 991px){
					
				}
// 				&:before{
// 					background: $bleu-p;
// 					position: absolute;
// 					display: table;
// 					content: "";
// 					bottom: -0.625vw;
// 					width: 0px;
// 					height: 0px;
// 					transition-property: width, height;
// 					transition-duration: 0.3s, 0.3s;
// 					transition-delay: 0.3s, 0.3s;
// 					z-index: -1;
// 					left: 50%;
// 					transform: translate(-50%, 0);
// 					@media screen and (max-width: 1280px){
// 						bottom: -7px;
// 					}
				// }
				&:after{
					background: $bleu-p;
					position: absolute;
					display: table;
					content: "";
					bottom: -0.625vw;
					width: 100%;
					height: 4px;
					transition-property: width;
					transition-duration: 0.3s;
					@media screen and (min-width: 1920px){
						height: 0.20833333333333334vw;

					}
					@media screen and (max-width: 1280px){
						bottom: -7px;
					}
				}
				&:hover{
					color: #fff;
					&:before{
						width: calc(100% + 1.5625vw);
						height: calc(100% + (0.625vw * 2));
						@media screen and (max-width: 1280px){
							width: 179px;
							height: 36px;
						}
					}
					&:after{
						width: 0;
					}
				}
			}
		}
	}
}

#conseil{
	    @include bp('l'){
          padding: 0 0 0!important;
    margin-bottom: 10vw;
    }
	position: relative;
	padding: 9.895833333333334vw 0 15.416666666666668vw 0;
	z-index: 2;
	#conseilImg{
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
		position: absolute;
		top: -2.8125vw;
		left: 0;
		width: 49.63541666666667vw;
		height: calc(100% + 2.8125vw);
		z-index: 2;

		.vague-bleu{
			position: absolute;
			right: -4.0625vw;
			bottom: 8.333333333333334vw;
			width: 15.104166666666668vw;
			height: 9.479166666666668vw;
		}
	}
	#right{
		float: right;
		width: 100%;
		max-width: calc(100% - 49.63541666666667vw);
		.contenu{
			max-width: calc(100% - (9.010416666666668vw * 2));
			margin: 0 auto;
			@include bp('l'){
				max-width: 90%;
				
			}
// 			text-align: right;
			h1{
				color: $bleu-f;
				font-size: 4.0625vw;
				font-family: $s-reg;
				line-height: 4.0625vw;
				letter-spacing: 0.01em;
				
				float: right;
				clear: both;
				margin: 0 0 3.385416666666667vw 0;
			}
			p{
				max-width: 36.19791666666667vw;
				color: $bleu-f;
				font-size: 0.8854166666666667vw;
				font-family: $r-reg;
				line-height: 1.3541666666666667vw;
				letter-spacing: 0.01em;
				clear: both;
				& + p{
					margin: 1.0416666666666667vw 0 0 0;
					    
				}
			}
		}
	}
}

#aboutService{
	background: $bleu-t;
	position: relative;
	padding: 14.614583vw 0 0;
	margin-top: -5vw;
	.circle-d{
		position: absolute;
		top: 5.052083333333334vw;
		right: 4.84375vw;
	}
	&:before{
		background-image: url(../images/vague-bg.png);
		background-size: 100% auto;
		background-repeat: no-repeat;
		background-position: center top;
		position: absolute;
		display: block;
		content: "";
		        top: -13vw;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.wrap{
		width: 100%;
		max-width: 90.98958333333334vw;
		float: right;
		position: relative;
		#servListContent{
			width: calc(100% - 20.677083333333336vw);
			float: left;
			left: 20.677083333333336vw;

			.slick-list{

				.slick-track{

					.slick-slide{
					@media screen and (max-width: 991px){
									padding-top:2vw;
									    min-height:inherit;
								}
						background: #fff;
						padding: 7.447916666666667vw 0 6.927083333333334vw;
						outline: 0 !important;
						min-height: 41vw;
						.itemTitle{
// 							    max-width: 19.760417vw;
							position: absolute;
							top: 50%;
							left: 79px;
							transform: translate(0, -50%);
							width: 100%;
					
							span{
								color: $bleu-p;
								font-size: 6.770833333333334vw;
								font-family: $r-black;
								line-height: 6.770833333333334vw;
								letter-spacing: 0.01em;
								text-transform: uppercase;
								word-wrap: break-word;
								display: block;
								width: 100%;
								opacity: 0.25;
							}
						}
						.itemInt{
							position: relative;
							max-width: 40.57291666666667vw;
							margin: 0 0 0 16.458333333333336vw;
							z-index: 1;

							h1{
								color: $bleu-f;
								font-size: 4.0625vw;
								font-family: $s-reg;
								line-height: 4.0625vw;
								margin: 0 0 60px 0;
								letter-spacing: 0.01em;
																@media screen and (max-width: 991px){
									margin-bottom:20px;
								}
							}
							p{
								color: $bleu-f;
								font-size: 0.8854166666666667vw;
								font-family: $r-reg;
								line-height: 1.3541666666666667vw;
								letter-spacing: 0.01em;
								& + p{
									margin: 1.0416666666666667vw 0 0 0;
								}
							}
							a{
								position: relative;
								display: table;
								color: $bleu-p;
								font-size: 1.09375vw;
								font-family: $r-med;
								letter-spacing: 0.01em;
								text-transform: uppercase;
								clear: both;
								transition-property: color;
								transition-duration: 0.3s;
								transition-delay: 0.3s;
								z-index: 1;
								height: 1.6145833333333335vw;
								cursor: pointer;
								margin: 1.8229166666666667vw 0 0 0;
								@media screen and (max-width: 1280px){
									font-size: 14px;
									height: 20px;
								}
								@media screen and (max-width: 991px){
									
								}
// 								&:before{
// 									background: $bleu-p;
// 									position: absolute;
// 									display: table;
// 									content: "";
// 									bottom: -0.625vw;
// 									width: 0px;
// 									height: 0px;
// 									transition-property: width, height;
// 									transition-duration: 0.3s, 0.3s;
// 									transition-delay: 0.3s, 0.3s;
// 									z-index: -1;
// 									left: 50%;
// 									transform: translate(-50%, 0);
// 									@media screen and (max-width: 1280px){
// 										bottom: -7px;
// 									}
// 								}
								&:after{
									background: $bleu-p;
									position: absolute;
									display: table;
									content: "";
									bottom: -0.625vw;
									width: 100%;
									height: 4px;
									transition-property: width;
									transition-duration: 0.3s;
									@media screen and (min-width: 1920px){
										height: 0.20833333333333334vw;

									}
									@media screen and (max-width: 1280px){
										bottom: -7px;
									}
								}
								&:hover{
								
									&:before{
										width: calc(100% + 1.5625vw);
										height: calc(100% + (0.625vw * 2));
										@media screen and (max-width: 1280px){
											width: 179px;
											height: 36px;
										}
									}
									&:after{
										width: 0;
									}
								}
							}
						}
					}
				}
			}
		}
		#servList{
			background: transparent;
			width: calc(20.677083333333336vw + 3.385416666666667vw);
			position: absolute;
			float: left;
			top: 50%;
			left: 0;
			transform: translate(0, -50%);
			.slick-arrow{
				display: none !important;
			}
			.slick-list{
				.slick-track{
					.slick-slide{
						background: $bleu-f;
						outline: 0 !important;
						border: none !important;
						z-index: 999 !important;
						width: calc(100% - 3.385416666666667vw) !important;
						transition-property: width;
						transition-duration: 0.4s;
						span{
							font-family: sabloReg;
							background: transparent;
							display: block;
							width: 100%;
							padding: 2.2395833333333335vw 0 2.2395833333333335vw 2.3958333333333335vw;
							transition-property: background, width, color;
							transition-duration: 0.4s, 0.4s, 0.4s;
							line-height: 100%;
							font-size: 1.5625vw;
							letter-spacing: 0.01em;
							color: $bleu-t;
							cursor: pointer;
						}
						&.slick-current{
							width: calc(100%) !important;
							span{
								background: $bleu-p;
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
}

#financement{
	background: $bleu-t;
	padding: 15vw 0 13.28125vw 0;
	.goutte{
		position: absolute;
		top: -1.5104166666666667vw;
		left: -.78125vw;
	}
	.vague-rond-bleu{
		position: absolute;
		right: 15.677083333333334vw;
		bottom: 4.635416666666667vw;
	}
	.contenu{
		position: relative;
		max-width: 57.395833333333336vw;
		margin: 0 0 0 12.96875vw;
		h1{
			max-width: 47.708333333333336vw;
			color: $bleu-p;
			opacity: 0.25;
			font-size: 6.770833333333334vw;
			font-family: $r-black;
			line-height: 6.770833333333334vw;
			letter-spacing: 0.01em;
			text-transform: uppercase;
		}
		p{
			max-width: 49.79166666666667vw;
			position: absolute;
			color: #fff;
			font-size: 1.3541666666666667vw;
			line-height: 2.03125vw;
			font-family: $s-reg;
			letter-spacing: 0.01em;
			top: 50%;
			right: 0;
			transform: translate(0, -50%);
			z-index: 1;
			text-align: right;
		}
		img{
			position: absolute;
			display: block;
			width: 15.78125vw;
			height: auto;
			top: 50%;
			right: -20.416666666666668vw;
			transform: translate(0, -50%);
		}
	}
}

#urgenceServ{
	background: $bleu-t;
	padding: 0 0 0 0;
	.corpsWrap{
		width: 100%;
		max-width: calc(100% - (9.010416666666668vw));
		margin: 0 auto;
		height: 100%;
		float: right;
		.contenu{
			position: relative;
			height: 35.78125vw; 
			@media screen and (max-width: 1280px){
				height: auto;
			}
			#left{
				background: #fff;
				width: 65.36458333333334vw;
				height: calc(100% - 1.8229166666666667vw);
				@media screen and (max-width: 1280px){
					height: auto;
					padding: 60px 0;
					padding-bottom: 28vw;
				}
				@media screen and (max-width: 1200px){
					width: 80%;
				}
								@media screen and (max-width: 992px){
					width: 100%;
					padding-bottom: 39vw;
				
				}

				@media screen and (max-width: 767px){
					width: 100%;
				
				}

				    
				.leftInt{
					position: absolute;
					top: 50%;
					left: 4.114583333333334vw;
					transform: translate(0, -50%);
					max-width: 34.375vw;
					@media screen and (max-width: 1280px){
						position: relative;
						top: initial;
						transform: initial;
					}
					@media screen and (max-width: 1200px){
						max-width: 70%;
					}
					@media screen and (max-width: 767px){
						max-width: 80%;
					}
					h3{
						color: $color-text;
						font-size: 4.0625vw;
						font-family: $s-reg;
						letter-spacing: 0.01em;
						line-height: 4.0625vw;
						@media screen and (max-width: 1280px){
							font-size: 52px;
							line-height: 52px;
						}
					}
					p{
						color: $color-text;
						font-size: 0.8854166666666667vw;
						font-family: $r-reg;
						letter-spacing: 0.01em;
						line-height: 1.3541666666666667vw;
						margin: 3.4375vw 0 4.583333333333334vw 0;
						@media screen and (max-width: 1280px){
							font-size: 15px;
							line-height: 24px;
							margin: 40px 0 60px 0;
						}
					}
					a{
						position: relative;
						display: table;
						color: $bleu-p;
						font-size: 1.09375vw;
						font-family: $r-med;
						letter-spacing: 0.01em;
						text-transform: uppercase;
						clear: both;
						transition-property: color;
						transition-duration: 0.3s;
						transition-delay: 0.3s;
						z-index: 1;
						height: 1.6145833333333335vw;
						@media screen and (max-width: 1280px){
							font-size: 14px;
							height: 20px;
						}
						&:before{
							background: $bleu-p;
							position: absolute;
							display: table;
							content: "";
							bottom: -0.625vw;
							width: 0px;
							height: 0px;
							transition-property: width, height;
							transition-duration: 0.3s, 0.3s;
							transition-delay: 0.3s, 0.3s;
							z-index: -1;
							left: 50%;
							transform: translate(-50%, 0);
						}
						&:after{
							background: $bleu-p;
							position: absolute;
							display: table;
							content: "";
							bottom: -0.625vw;
							width: 100%;
							height: 4px;
							transition-property: width;
							transition-duration: 0.3s;
							@media screen and (min-width: 1920px){
								height: 0.20833333333333334vw;
							}
						}
						&:hover{
							&:before{
								width: calc(100% + 1.5625vw);
								height: calc(100% + (0.625vw * 2));
							}
							&:after{
								width: 0;
							}
						}
					}
				}
			}
			#right{
				background-image: url(../images/camion.png);
				background-size: auto 100%;
				background-position: left center;
				background-repeat: no-repeat;
				position: absolute;
				width: 49.739583333333336vw;
				height: 34.85416666666667vw;
				right: 0;
				bottom: 0;
				@media screen and (max-width: 1200px){
// 					transform: translateX(14vw);
				}
				@media screen and (max-width: 767px){
					display: none;
				}
			}
		}
	}
}

#necessaire{
	background: $bleu-t;
	padding: 15.625vw 0 5.989583333333334vw 0;
	.vague{
		position: absolute;
		top: 3.385416666666667vw;
		left: 9.010416666666668vw;
	}
	.wrap{
		text-align: center;
		max-width: 60.677083333333336vw;
		margin: 0 auto;
		h1{
			color: #fff;
			font-size: 4.0625vw;
			font-family: $s-reg;
			line-height: 4.0625vw;
			letter-spacing: 0.01em;
			max-width: 47.395833333333336vw;
			margin: 0 auto 3.59375vw;
		}
		p{
			color: #fff;
			font-size: 0.8854166666666667vw;
			font-family: $r-reg;
			line-height: 1.3541666666666667vw;
			letter-spacing: 0.01em;
		}
	}
}

#distributeurs{
	padding: 3.125vw 0 3.385416666666667vw 0;
	.contenu{
		margin: 0 auto;
		max-width: 42.91666666666667vw;

		.secTitle{
			position: relative;
			display: table;
			margin: 0 auto 2.0833333333333335vw;
			width: 100%;
			text-align: center;
			&:before{
				background: $bleu-f;
				position: absolute;
				display: block;
				content: "";
				top: 50%;
				left: 0;
				width: 100%;
				height: 1px;
				transform: translate(0, -50%);
			}
			span{
				background: #fff;
				position: relative;
				color: $bleu-f;
				z-index: 2;
				line-height: 100%;
				font-size: 0.9895833333333334vw;
				font-family: $r-bold;
				text-transform: uppercase;
				padding: 0 0.625vw;
			}
		}
		#listDistributeurs{
			display: flex;
    width: 100%;
    justify-content: space-between;
    
    align-items: center;
			.dist-by-dist{
				width: 30%;
				float: left;
				img{
					display: block;
					max-width: 90%;
				}
				&:nth-child(1){

				}
				&:nth-child(2){
					img{
						margin: 0 auto;
					}
				}
				&:nth-child(3){
					img{
						float: right;
					}
				}
			}
		}
	}
}