ul{
	li{
		a{
			br{
				display: none !important;
			}
		}
	}
}

#supportHead{
	display: none;
	width: 100%;
	height: 6.36vw;
	transition-property: height;
	transition-duration: 0.4s;
	@media screen and (min-width: 1920px){
		height: 7.291666666666667vw;
	}
	@media screen and (max-width: 991px){
		height: 9vw;
		display: block;
	}
	@media screen and (max-width: 767px){
height: 15vw;
		
	}
	@media screen and (max-width: 580px){
// 		height: 65px;
	}
	@media screen and (max-width: 420px){
		height: 50px;
	}
	&.head-is-Scroll{
		display: block;
		height: 100px;
		@media screen and (min-width: 1920px){
			height: 5.208333333333334vw;
		}
		@media screen and (max-width: 767px){
			height: 80px;
		}
		@media screen and (max-width: 580px){
			height: 65px;
		}
		@media screen and (max-width: 420px){
			height: 50px;
		}
	}
	&.notFront{
		display: block !important;
	}
}
header{
	position: relative;
	background: $bleu-f;
	display: block;
	width: 100%;
	height: 7.3vw;
	z-index: 999999 !important;
	transition-property: height;
	transition-duration: 0.4s;
	&.notFront{
		position: fixed;
	}
	@media screen and (min-width: 1920px){
		height: 7.291666666666667vw;
	}
	@media screen and (max-width: 991px){
		height: 9vw;
		position:fixed;
	}
	@media screen and (max-width: 767px){
		height: 15vw;
	
		position: fixed;
	}

	#wrapHead{
		max-width: 90.365vw;
		margin: 0 auto;
		height: 100%;
		display:flex;
		#logo{
			position: relative;
			float: left;
			height: 100%;
			width:30vw;
			a{
				position: absolute;
				display: table;
				width:  6.72vw !important;;
				height: calc(100% - 44px);
				top: 50%;
				transform: translate(0, -50%);
				@media screen and (min-width: 1920px){
					width:  7.760416666666667vw !important;
				}
				@media screen and (max-width: 991px){
					width:  129px !important;
				}
				@media screen and (max-width: 767px){
					width:  100px !important;
				}
				@media screen and (max-width: 580px){
					width: 80px !important;
				}
				@media screen and (max-width: 420px){
					width: 70px !important;
				}
				img{
					display: block;
					width: 100%;
					height: 5.22vw;
				@media screen and (max-width: 767px){
					 width: 16vw!important;
    				height: 12vw;
				}
				}
			}
		}
		nav{
			width:68vw;
			position: relative;
			float: left;
			height: 100%;
			.navInt{
				display: flex;
				width:100%;
				height: 100%;
				ul{
					display: table-cell;
					&#headMenuShort{
						display: none;
						padding: 0 15px 0 0;
						@media screen and (min-width: 1920px){
							padding: 0 0.78125vw 0 0;
						}
						@media screen and (max-width: 1580px){
							display: block;
						}
						@media screen and (max-width: 1235px){
							display: none;
						}
						li{
							position: relative;
							padding: 0 11px !important;
							float: right;
							height: 100%;
							line-height: 6.8vw;
							@media screen and (min-width: 1920px){
								padding: 0 0.5729166666666667vw !important;
// 								line-height: 7.291666666666667vw;
							}
							/*&:after{
								background: $bleu-p;
								position: absolute;
								display: block;
								content: "";
								width: 0px;
								height: 30px;
								left: 0;
								bottom: 0;
								opacity: 0;
								transform: translateY(0);
								transition-property: width, height, opacity, transform;
								transition-duration: 0.4s, 0.4s, 0.4s, 0.4s;
								transition-delay: 0s, 0.4s, 0s, 0.4s;
								@media screen and (min-width: 1920px){
									height: 1.5625vw;
								}
							}
							&:hover{
								&:after{
									width: 100%;
									height: calc(100% + 12px);
									opacity: 1;
									transform: translateY(12px);
								}
							}*/
							&.current_page_item{
								&:after{
									background: $bleu-p;
									position: absolute;
									display: block;
									content: "";
									width: 100%;
									height: calc(100% + 12px);
									left: 0;
									bottom: 0;
									opacity: 1;
									transform: translateY(12px);
								}
							}
							a{
								position: relative;
								display: block;
								width: 100%;
								height: 100%;
								z-index: 1;
							}

							ul{
								background: $bleu-f;
								display: none;
								position: absolute;
								z-index: 9;
								padding: 20px;
								width: 200%;
								li{
									line-height: 100% !important;
									padding: 0 !important;
									float: initial !important;
									& + li{
										margin: 20px 0 0 0;
									}
									a{
										
									}
								}
							}
							&:hover{
								ul{
									display: block;
								}
							}
						}
					}
					&#headMenuShortIpad{
						display: none;
						padding: 0 15px 0 0;
						@media screen and (min-width: 1920px){
							padding: 0 0.78125vw 0 0;
						}
						@media screen and (max-width: 1235px){
							display: block;
						}
						@media screen and (max-width: 991px){
							display: none;
						}
						li{
							position: relative;
							padding: 0 11px !important;
							float: right;
							height: 100%;
							line-height: 6.8vw;
							@media screen and (min-width: 1920px){
								line-height: 7.291666666666667vw;
								padding: 0 0.5729166666666667vw !important;
							}
							/*&:after{
								background: $bleu-p;
								position: absolute;
								display: block;
								content: "";
								width: 0px;
								height: 30px;
								left: 0;
								bottom: 0;
								opacity: 0;
								transform: translateY(0);
								transition-property: width, height, opacity, transform;
								transition-duration: 0.4s, 0.4s, 0.4s, 0.4s;
								transition-delay: 0s, 0.4s, 0s, 0.4s;
								@media screen and (min-width: 1920px){
									height: 1.5625vw;
								}
							}
							&:hover{
								&:after{
									width: 100%;
									height: calc(100% + 12px);
									opacity: 1;
									transform: translateY(12px);
								}
							}*/
							&.current_page_item{
								&:after{
									background: $bleu-p;
									position: absolute;
									display: block;
									content: "";
									width: 100%;
									height: calc(100% + 12px);
									left: 0;
									bottom: 0;
									opacity: 1;
									transform: translateY(12px);
								}
							}
							a{
								position: relative;
								display: block;
								width: 100%;
								height: 100%;
								z-index: 1;
							}

							ul{
								background: $bleu-f;
								display: none;
								position: absolute;
								z-index: 9;
								padding: 20px;
								width: 200%;
								li{
									line-height: 100% !important;
									padding: 0 !important;
									float: initial !important;
									& + li{
										margin: 20px 0 0 0;
									}
									a{
										
									}
								}
							}
							&:hover{
								ul{
									display: block;
								}
							}
						}
					}
					&#headMenu{
						    width: 62vw;
						padding: 0 15px 0 0;
					
						@media screen and (min-width: 1920px){
							padding: 0 0.78125vw 0 0;
						}
						@media screen and (max-width: 991px){
							display: none;
						}
						li{
							position: relative;
							line-height: 7.5vw;
							padding: 0 11px !important;
							float: right;
							height: 100%;
							@media screen and (min-width: 1920px){
								line-height: 6.8vwvw;
								padding: 0 0.5729166666666667vw !important;
							}
							&.current_page_item{
								&:after{
									background: $bleu-p;
									position: absolute;
									display: block;
									content: "";
									width: 100%;
									height: calc(100% + 12px);
									left: 0;
									bottom: 0;
									opacity: 1;
									transform: translateY(12px);
								}
							}
							/*&:after{
								background: $bleu-p;
								position: absolute;
								display: block;
								content: "";
								width: 0px;
								height: 30px;
								left: 0;
								bottom: 0;
								opacity: 0;
								transform: translateY(0);
								transition-property: width, height, opacity, transform;
								transition-duration: 0.4s, 0.4s, 0.4s, 0.4s;
								transition-delay: 0s, 0.4s, 0s, 0.4s;
								@media screen and (min-width: 1920px){
									height: 1.5625vw;
								}
							}
							&:hover{
								&:after{
									width: 100%;
									height: calc(100% + 12px);
									opacity: 1;
									transform: translateY(12px);
									@media screen and (min-width: 1920px){
										height: calc(100% + 0.625vw);
										transform: translateY(0.625vw);
									}
								}
							}*/
							a{
								position: relative;
								display: block;
								width: 100%;
								height: 100%;
								z-index: 1;
								font-size:0.7vw;
							}
						}
					}
					&#menuPartRight{
						    position: relative;
						    align-items: center;
    display: flex;
    width: 6.22vw;
    position: relative;
    height: 100%;
    vertical-align: middle;
						@media screen and (min-width: 1920px){
							width: 9.427083333333334vw;
						}
						@media screen and (max-width: 991px){
							width:20vw;
							position: absolute;
							display: flex;
							width: auto;
							top: 50%;
							right: 0;
							transform: translate(0, -50%);
						}
// 						@media screen and (max-width: 580px){
// 							width: 100%;
// 						}
						li{
							@media screen and (max-width: 580px){
								margin: 0 !important;
							}
							& + li{
								margin: 0 0 0 1.81vw;
								@media screen and (min-width: 1920px){
									margin: 0 0 0 0.9375vw;
								}
							}

							&.socialUnit{
								line-height: 1.5vw !important;
								@media all and (max-width: 992px){
									       line-height: 30px!important;
						}
						@media screen and (max-width: 580px){
							display:none;
						}
								
								}
// 								@media screen and (max-width: 580px){
// 									display: none;
// 								}
								a{
									display: block;
									width: 1.63vw;
									height: 1.63vw;
									border: 0.13vw solid #fff;
									border-radius: 1.63vw;
									text-align: center;
									font-size: 0.86vw !important;
									@media screen and (max-width: 992px){
									    display: block;
										width: 36px;
										height: 36px;
										border: 3px solid #fff;
										border-radius: 36px;
										text-align: center;
										font-size: 19px!important;}
									@media all and (max-width: 1920px){

									@media screen and (min-width: 1920px){
										font-size: 0.9895833333333334vw !important;
										width: 1.875vw;
										height: 1.875vw;
										border: 0.15625vw solid #fff;
										border-radius: 1.875vw;
									}
								}
							}
							&#lang{
								@media screen and (max-width: 991px){
									position: relative;
									width: 25px;
									height: 36px;
									margin-left:27px;
								}
// 								@media screen and (max-width: 580px){
// 									position: absolute;
// 									top: 50%;
// 									right: 0;
// 									transform: translate(0, -50%);
// 								}
								a{
									@media screen and (max-width: 991px){
										display: none;
									}
								}
								#hamMenu{
									position: absolute;
									display: none;
									top: 50%;
									width: 25px;
									height: 20px;
									transform: translate(0, -50%) rotate(0deg);
									cursor: pointer;
									transition-property: transform;
									transition-duration: 0.4s;
									@media screen and (max-width: 991px){
										display: block;
									}

									span{
										background: #fff;
										position: absolute;
										width: 100%;
										height: 2px;
										&:nth-child(1){
											top: 0;
											left: 0;
											transition-property: top, transform;
											transition-duration: 0.4s, 0.4s;
											transition-delay: 0.4s, 0.4s;
										}
										&:nth-child(2){
											top: 50%;
											left: 0;
											transform: translate(0, -50%);
											transition-property: width;
											transition-duration: 0.4s;
											transition-delay: 0.4s;
										}
										&:nth-child(3){
											left: 0;
											bottom: 0;
											transition-property: bottom, transform;
											transition-duration: 0.4s, 0.4s;
											transition-delay: 0.4s, 0.4s;
										}
									}
									&.active{
										transform: translate(0, -50%) rotate(360deg);
										span{
											&:nth-child(1){
												top: 50%;
												transform: translate(0, -50%) rotate(45deg);
											}
											&:nth-child(2){
												width: 0;
											}
											&:nth-child(3){
												bottom: 50%;
												transform: translate(0, 50%) rotate(-45deg);
											}
										}
									}
								}
							}
						}
					}

					li{
						line-height: 36px;
						float: left;
						@media screen and (min-width: 1920px){
							line-height: 1.875vw;

						}
						&#search{
							@media screen and (max-width: 580px){
								position: absolute;
								width: calc(100% - 25px);
								top: 50%;
								left: 0;
								transform: translate(0, -50%);
							}
							span{
								background-image: url(../images/loupe.svg);
								background-size: 100% 100%;
								background-position: center center;
								background-repeat: no-repeat;
								display: block;
								width: 31px;
								height: 31px;
								cursor: pointer;
								@media screen and (min-width: 1920px){
									width: 1.6145833333333335vw;
									height: 1.6145833333333335vw;
								}
								@media screen and (max-width: 580px){
									float: right;
									margin: 0 20px 0 0;
									width: 25px;
									height: 25px;
								}
							}
						}
						a{
							color: #fff;
							font-size: 0.77vw;
							font-family: $r-bold;
							letter-spacing: 0.01em;
							text-transform: uppercase;
							transition: 0.4s;
							@media screen and (min-width: 1920px){
								font-size: 0.7291666666666667vw;
							}
							&:hover{
								color: $bleu-p !important;
							}

							i{
								padding: 0 !important;
							}
						}

					}
				}
			}
		}
	}
	&.head-is-Scroll{
		position: fixed;
				top: 0;
		left: 0;}
// 		top: 0;
// 		left: 0;
// // 		height: 100px;
// 		@media screen and (min-width: 1920px){
// 			height: 5.208333333333334vw;
// 		}
// 		@media screen and (max-width: 767px){
// 			height: 80px;
// 		}
// 		@media screen and (max-width: 580px){
// 			height: 65px;
// 		}
// 		@media screen and (max-width: 420px){
// 			height: 50px;
// 		}
// 		#wrapHead{
// 			max-width: 90.365vw;
// 			margin: 0 auto;
// 			height: 100%;
// 			#logo{
// 				position: relative;
// 				float: left;
// 				height: 100%;
// 				a{
// 					position: absolute;
// 					display: table;
// 					width:  120px !important;
// 					top: 50%;
// 					transform: translate(0, -50%);
// 					@media screen and (min-width: 1920px){
// 						width: 6.25vw !important;
// 					}
// 					@media screen and (max-width: 991px){
// 						width: 110px !important;
// 					}
// 					@media screen and (max-width: 767px){
// 						width: 100px !important;
// 					}
// 					@media screen and (max-width: 580px){
// 						width: 80px !important;
// 					}
// 					@media screen and (max-width: 420px){
// 						width: 70px !important;
// 					}
// 					img{
// 						display: block;
// 					}
// 				}
// 			}
// 			nav{
// 				position: relative;
// 				float: left;
// 				height: 100%;
// 				.navInt{
// 					display: table;
// 					width: 100%;
// 					height: 100%;
// 					ul{
// 						display: table-cell;
// 						&#headMenuShort{
// 							display: none;
// 							padding: 0 15px 0 0;
// 							@media screen and (min-width: 1920px){
// 								padding: 0 0.78125vw 0 0;
// 							}
// 							@media screen and (max-width: 1580px){
// 								display: block;
// 							}
// 							@media screen and (max-width: 1235px){
// 								display: none;
// 							}
// 							li{
// 								position: relative;
// 								padding: 0 11px !important;
// 								float: right;
// 								height: 100%;
// 								line-height: 100px;
// 								@media screen and (min-width: 1920px){
// 									padding: 0 0.5729166666666667vw !important;
// 									line-height: 5.208333333333334vw;
// 								}
// 								/*&:after{
// 									background: $bleu-p;
// 									position: absolute;
// 									display: block;
// 									content: "";
// 									width: 0px;
// 									height: 30px;
// 									left: 0;
// 									bottom: 0;
// 									opacity: 0;
// 									transform: translateY(0);
// 									transition-property: width, height, opacity, transform;
// 									transition-duration: 0.4s, 0.4s, 0.4s, 0.4s;
// 									transition-delay: 0s, 0.4s, 0s, 0.4s;
// 									@media screen and (min-width: 1920px){
// 										height: 1.5625vw;
// 									}
// 								}
// 								&:hover{
// 									&:after{
// 										width: 100%;
// 										height: calc(100% + 12px);
// 										opacity: 1;
// 										transform: translateY(12px);
// 									}
// 								}*/
// 								&.current_page_item{
// 									&:after{
// 										background: $bleu-p;
// 										position: absolute;
// 										display: block;
// 										content: "";
// 										width: 100%;
// 										height: calc(100% + 12px);
// 										left: 0;
// 										bottom: 0;
// 										opacity: 1;
// 										transform: translateY(12px);
// 									}
// 								}
// 								a{
// 									position: relative;
// 									display: block;
// 									width: 100%;
// 									height: 100%;
// 									z-index: 1;
// 								}

// 								ul{
// 									background: $bleu-f;
// 									display: none;
// 									position: absolute;
// 									z-index: 9;
// 									padding: 20px;
// 									width: 200%;
// 									li{
// 										line-height: 100% !important;
// 										padding: 0 !important;
// 										float: initial !important;
// 										& + li{
// 											margin: 20px 0 0 0;
// 										}
// 										a{
											
// 										}
// 									}
// 								}
// 								&:hover{
// 									ul{
// 										display: block;
// 									}
// 								}
// 							}
// 						}
// 						&#headMenuShortIpad{
// 							display: none;
// 							padding: 0 15px 0 0;
// 							@media screen and (min-width: 1920px){
// 								padding: 0 0.78125vw 0 0;
// 							}
// 							@media screen and (max-width: 1235px){
// 								display: block;
// 							}
// 							@media screen and (max-width: 991px){
// 								display: none;
// 							}
// 							li{
// 								position: relative;
// 								padding: 0 11px !important;
// 								float: right;
// 								height: 100%;
// 								line-height: 100px;
// 								@media screen and (min-width: 1920px){
// 									line-height: 5.208333333333334vw;
// 									padding: 0 0.5729166666666667vw !important;
// 								}
// 								/*&:after{
// 									background: $bleu-p;
// 									position: absolute;
// 									display: block;
// 									content: "";
// 									width: 0px;
// 									height: 30px;
// 									left: 0;
// 									bottom: 0;
// 									opacity: 0;
// 									transform: translateY(0);
// 									transition-property: width, height, opacity, transform;
// 									transition-duration: 0.4s, 0.4s, 0.4s, 0.4s;
// 									transition-delay: 0s, 0.4s, 0s, 0.4s;
// 									@media screen and (min-width: 1920px){
// 										height: 1.5625vw;
// 									}
// 								}
// 								&:hover{
// 									&:after{
// 										width: 100%;
// 										height: calc(100% + 12px);
// 										opacity: 1;
// 										transform: translateY(12px);
// 									}
// 								}*/
// 								&.current_page_item{
// 									&:after{
// 										background: $bleu-p;
// 										position: absolute;
// 										display: block;
// 										content: "";
// 										width: 100%;
// 										height: calc(100% + 12px);
// 										left: 0;
// 										bottom: 0;
// 										opacity: 1;
// 										transform: translateY(12px);
// 									}
// 								}
// 								a{
// 									position: relative;
// 									display: block;
// 									width: 100%;
// 									height: 100%;
// 									z-index: 1;
// 								}

// 								ul{
// 									background: $bleu-f;
// 									display: none;
// 									position: absolute;
// 									z-index: 9;
// 									padding: 20px;
// 									width: 200%;
// 									li{
// 										line-height: 100% !important;
// 										padding: 0 !important;
// 										float: initial !important;
// 										& + li{
// 											margin: 20px 0 0 0;
// 										}
// 										a{
											
// 										}
// 									}
// 								}
// 								&:hover{
// 									ul{
// 										display: block;
// 									}
// 								}
// 							}
// 						}
// 						&#headMenu{
// 							padding: 0 15px 0 0;
// 							@media screen and (min-width: 1920px){
// 								padding: 0 0.78125vw 0 0;
// 							}
// 							@media screen and (max-width: 1580px){
// 								display: none;
// 							}
// 							li{
// 								position: relative;
// 								padding: 0 11px !important;
// 								float: right;
// 								height: 100%;
// 								line-height: 100px;
// 								@media screen and (min-width: 1920px){
// 									line-height: 5.208333333333334vw;
// 									padding: 0 0.5729166666666667vw !important;
// 								}
// 								/*&:after{
// 									background: $bleu-p;
// 									position: absolute;
// 									display: block;
// 									content: "";
// 									width: 0px;
// 									height: 30px;
// 									left: 0;
// 									bottom: 0;
// 									opacity: 0;
// 									transform: translateY(0);
// 									transition-property: width, height, opacity, transform;
// 									transition-duration: 0.4s, 0.4s, 0.4s, 0.4s;
// 									transition-delay: 0s, 0.4s, 0s, 0.4s;
// 									@media screen and (min-width: 1920px){
// 										height: 1.5625vw;
// 									}
// 								}
// 								&:hover{
// 									&:after{
// 										width: 100%;
// 										height: calc(100% + 12px);
// 										opacity: 1;
// 										transform: translateY(12px);
// 										@media screen and (min-width: 1920px){
// 											height: calc(100% + 0.625vw);
// 											transform: translateY(0.625vw);
// 										}
// 									}
// 								}*/
// 								&.current_page_item{
// 									&:after{
// 										background: $bleu-p;
// 										position: absolute;
// 										display: block;
// 										content: "";
// 										width: 100%;
// 										height: calc(100% + 12px);
// 										left: 0;
// 										bottom: 0;
// 										opacity: 1;
// 										transform: translateY(12px);
// 									}
// 								}
// 								a{
// 									position: relative;
// 									display: block;
// 									width: 100%;
// 									height: 100%;
// 									z-index: 1;
// 								}
// 							}
// 						}
// 						&#menuPartRight{
// 							position: relative;
// 							width: 181px;
// 							vertical-align: middle;
// 							@media screen and (min-width: 1920px){
// 								width: 9.427083333333334vw;
// 							}
// 							@media screen and (max-width: 991px){
// 								position: absolute;
// 								display: table;
// 								width: auto;
// 								top: 50%;
// 								right: 0;
// 								transform: translate(0, -50%);
// 							}
// 							@media screen and (max-width: 580px){
// 								width: 100%;
// 							}
// 							li{
// 								@media screen and (max-width: 580px){
// 									margin: 0 !important;
// 								}
// 								& + li{
// 									margin: 0 0 0 18px;
// 									@media screen and (min-width: 1920px){
// 										margin: 0 0 0 0.9375vw;
// 									}
// 								}

// 								&.socialUnit{
// 									line-height: 30px !important;
// 									@media screen and (min-width: 1920px){
// 										line-height: 1.5625vw !important;
// 									}
// 									@media screen and (max-width: 580px){
// 										display: none;
// 									}
// 									a{
// 										display: block;
// 										width: 36px;
// 										height: 36px;
// 										border: 3px solid #fff;
// 										border-radius: 36px;
// 										text-align: center;
// 										font-size: 19px !important;
// 										@media screen and (min-width: 1920px){
// 											font-size: 0.9895833333333334vw !important;
// 											width: 1.875vw;
// 											height: 1.875vw;
// 											border: 0.15625vw solid #fff;
// 											border-radius: 1.875vw;
// 										}
// 									}
// 								}
// 								&#lang{
// 									@media screen and (max-width: 991px){
// 										position: relative;
// 										width: 25px;
// 										height: 36px;
// 									}
// 									@media screen and (max-width: 580px){
// 										position: absolute;
// 										top: 50%;
// 										right: 0;
// 										transform: translate(0, -50%);
// 									}
// 									a{
// 										@media screen and (max-width: 991px){
// 											display: none;
// 										}
// 									}
// 									#hamMenu{
// 										position: absolute;
// 										display: none;
// 										top: 50%;
// 										width: 25px;
// 										height: 20px;
// 										transform: translate(0, -50%) rotate(0deg);
// 										cursor: pointer;
// 										transition-property: transform;
// 										transition-duration: 0.4s;
// 										@media screen and (max-width: 991px){
// 											display: block;
// 										}

// 										span{
// 											background: #fff;
// 											position: absolute;
// 											width: 100%;
// 											height: 2px;
// 											&:nth-child(1){
// 												top: 0;
// 												left: 0;
// 											}
// 											&:nth-child(2){
// 												top: 50%;
// 												left: 0;
// 												transform: translate(0, -50%);
// 											}
// 											&:nth-child(3){
// 												left: 0;
// 												bottom: 0;
// 											}
// 										}
// 										&.active{
// 											transform: translate(0, -50%) rotate(360deg);
// 											span{
// 												&:nth-child(1){
// 													top: 50%;
// 													transform: translate(0, -50%) rotate(45deg);
// 												}
// 												&:nth-child(2){
// 													width: 0;
// 												}
// 												&:nth-child(3){
// 													bottom: 50%;
// 													transform: translate(0, 50%) rotate(-45deg);
// 												}
// 											}
// 										}
// 									}
// 								}
// 							}
// 						}

// 						li{
// 							line-height: 36px;
// 							float: left;
// 							@media screen and (min-width: 1920px){
// 								line-height: 1.875vw;
// 							}
// 							&#search{
// 								@media screen and (max-width: 580px){
// 									position: absolute;
// 									width: calc(100% - 25px);
// 									top: 50%;
// 									left: 0;
// 									transform: translate(0, -50%);
// 								}
// 								span{
// 									background-image: url(../images/loupe.svg);
// 									background-size: 100% 100%;
// 									background-position: center center;
// 									background-repeat: no-repeat;
// 									display: block;
// 									width: 31px;
// 									height: 31px;
// 									cursor: pointer;
// 									@media screen and (min-width: 1920px){
// 										width: 1.6145833333333335vw;
// 										height: 1.6145833333333335vw;
// 									}
// 									@media screen and (max-width: 580px){
// 										float: right;
// 										margin: 0 20px 0 0;
// 										width: 25px;
// 										height: 25px;
// 									}
// 								}
// 							}
// 							a{
// 								color: #fff;
// 								font-size: 14px;
// 								font-family: $r-bold;
// 								letter-spacing: 0.01em;
// 								text-transform: uppercase;
// 								@media screen and (min-width: 1920px){
// 									font-size: 0.7291666666666667vw;
// 								}

// 								i{
// 									padding: 0 !important;
// 								}
// 							}

// 						}
// 					}
// 				}
// 			}
// 		}
// 	}
}


#menuPartRight{
	li{
		a{
			transition: 0.4s;
			&:hover{
				color: $bleu-p !important;
				border-color: $bleu-p !important;
			}
		}
	}
}