@keyframes imgmove {

	0%{
		transform: translate(0, 0);

	}
	50%{
		transform: translate(-2.208333333333334vw, 2.208333333333334vw);
	}
	100%{
		transform: translate(0, 0);

	}
}
@keyframes imgmoveS {

	0%{
		transform: translate(0, -50%);

	}
	25%{
		transform: translate(5px, -45%);
	}
	50%{
		transform: translate(0, -50%);
	}
	75%{
		transform: translate(-5px, -45%);
	}
	100%{
		transform: translate(0, -50%);
	}
}


html,
body{
	margin: 0;
	padding: 0;
}


body{
	overflow-x: hidden !important;
}


h1,
h2,
h3,
h4,
h5,
h6{
	margin: 0;
}

p{
	margin: 0;
}

*{
	outline: 0 !important;
}

ul{
	margin: 0;
	padding: 0;
	list-style: none;
	li{

	}
}

a{
	text-decoration: none !important;
}

section{
	position: relative;
	z-index: 1;
	clear: both;
}

.clear{
	clear: both;
}

.corps{
	position: relative;
	display: block;
	max-width: $corps;
	margin: 0 auto;
}
.corpsSide{
	position: relative;
	display: block;
	max-width: $corps-side;
	margin: 0;
}


// Effects
.effect{
	&.x{
		transform: translateX(1000%);
	}
	&.xInverse{
		transform: translateX(-1000%);
	}
	transition-property: transform;
	transition-duration: 0.4s;
	&.isVisible{
		transform: translateX(0);
	}
}


#loader{
	background: $bleu-f;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	transition-property: opacity;
	transition-duration: 0.4s;
	z-index: 20;
	img{
		display: block;
		height: 200px;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		@media screen and (max-width: 991px){
			height: 150px
		}
		@media screen and (max-width: 767px){
			height: 100px;
		}
		@media screen and (max-width: 580px){
			height: 90px;
		}
		@media screen and (max-width: 420px){

		}
	}
}
#wrapperPage{
// 	opacity: 0;
// 	transition-property: opacity;
// 	transition-duration: 0.4s;
}


#popOld{
	background: rgba(105, 229, 244, 0.8);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	z-index: 9999;
	#contenu{
		background: $bleu-f;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 70%;
		height: auto;
		padding: 100px 0;
		transform: translate(-50%, -50%);
		@media screen and (max-height: 900px){
			top: 0;
			transform: translate(-50%, 0);
		}
		#forClose{
			position: absolute;
			width: 25px;
			height: 20px;
			top: 34px;
			right: 39px;
			cursor: pointer;
			&:before{
				background: #fff;
				position: absolute;
				display: block;
				content: "";
				width: 100%;
				height: 1px;
				top: 50%;
				left: 50%;
				transform: translate(0, -50%) rotate(-45deg);
			}
			&:after{
				background: #fff;
				position: absolute;
				display: block;
				content: "";
				width: 100%;
				height: 1px;
				top: 50%;
				left: 50%;
				transform: translate(0, -50%) rotate(45deg);
			}
		}
		.gform_wrapper{
			margin: 0;
			max-width: calc(100% - 200px);
			margin: 0 auto;
			form{
				.validation_error{
					color: #ff0000;
					letter-spacing: 0.01em;
					margin: 0 0 20px 0;
					padding: 0 10px;
				}
				.gform_body{
					ul{
						li{
							float: left;
							width: 50%;
							clear: initial;
							padding: 0 10px;
							margin: 0 0 30px;
							label{
								color: $bleu-p;
								font-size: 26px;
								font-family: $s-reg;
								line-height: 21px;
								letter-spacing: 0.01em;
								margin: 0;
								.gfield_required{
									display: none;
								}
							}
							.ginput_container{
								input,
								select{
									background: transparent;
									border: none;
									border-bottom: 1px solid #fff;
									width: 100%;
									padding: 15px 0;
									outline: 0;
									color: #fff;
									font-size: 17px;
									font-family: $r-reg;
									letter-spacing: 0.01em;
									&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
										color: #fff;
									}
									&::-moz-placeholder { /* Firefox 19+ */
										color: #fff;
									}
									&:-ms-input-placeholder { /* IE 10+ */
										color: #fff;
									}
									&:-moz-placeholder { /* Firefox 18- */
										color: #fff;
									}
								}
								select{
									option{
										background-color:#002131;
										color: #fff;
									}
								}
							}
							.validation_message{
								display: none;
							}
							&#field_1_10,
							&#field_1_11{
								clear: both;
								width: 100%;
							}
							&.gfield_error{
								.ginput_container{
									input,
									select{
										border-color: #ff0000 !important;
									}
								}
							}
						}
						&:after{
							display: table;
							content: "";
							clear: both;
						}
					}
				}
				.gform_footer{
					position: relative;
					margin: 20px 0 0 0;
					display: table;
					float: right;
					clear: both;
					width: auto;
					input{
						background: transparent;
						position: relative;
						display: table;
						color: $bleu-p;
						font-size: 1.09375vw;
						font-family: $r-med;
						letter-spacing: 0.01em;
						text-transform: uppercase;
						clear: both;
						transition-property: color;
						transition-duration: 0.3s;
						transition-delay: 0.3s;
						z-index: 1;
						height: 1.6145833333333335vw;
						border: none;
						outline: 0;
						margin: 0;
						@media screen and (max-width: 1280px){
							font-size: 14px;
							height: 20px;
						}
						@media screen and (max-width: 991px){
							float: right;
						}
					}
					&:before{
						background: $bleu-p;
						position: absolute;
						display: table;
						content: "";
						bottom: -4vw;
						width: 0px;
						height: 0px;
						transition-property: width, height;
						transition-duration: 0.3s, 0.3s;
						transition-delay: 0.3s, 0.3s;
						z-index: -1;
						left: 50%;
						transform: translate(-50%, 0);
					}
					&:after{
						background: $bleu-p;
						position: absolute;
						display: table;
						content: "";
						bottom: -0.425vw;
						width: 100%;
						height: 4px;
						transition-property: width;
						transition-duration: 0.3s;
						@media screen and (min-width: 1920px){
							height: 0.20833333333333334vw;
						}
					}
					&:hover{
						color: #fff;
						&:before{
							width: calc(100% + 1.5625vw);
							height: calc(100% + (0.625vw * 2));
						}
						&:after{
							width: 0;
						}
					}
				}
			}
		}
	}
}


#popReserve{
	background: rgba(105, 229, 244, 0.8);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: none;
	z-index: 9999;
	#contenu{
		background: $bleu-f;
		position: absolute;
		top: 50%;
		left: 50%;
		width: 70%;
		height: auto;
		padding: 100px 0;
		transform: translate(-50%, -50%);
		@media screen and (max-height: 900px){
			top: 0;
			transform: translate(-50%, 0);
		}
		#forClosea{
			position: absolute;
			width: 25px;
			height: 20px;
			top: 34px;
			right: 39px;
			cursor: pointer;
			&:before{
				background: #fff;
				position: absolute;
				display: block;
				content: "";
				width: 100%;
				height: 1px;
				top: 50%;
				left: 50%;
				transform: translate(0, -50%) rotate(-45deg);
			}
			&:after{
				background: #fff;
				position: absolute;
				display: block;
				content: "";
				width: 100%;
				height: 1px;
				top: 50%;
				left: 50%;
				transform: translate(0, -50%) rotate(45deg);
			}
		}
		.gform_wrapper{
			margin: 0;
			max-width: calc(100% - 200px);
			margin: 0 auto;
			form{
				.validation_error{
					color: #ff0000;
					letter-spacing: 0.01em;
					margin: 0 0 20px 0;
					padding: 0 10px;
				}
				.gform_body{
					ul{
						li{
							float: left;
							width: 50%;
							clear: initial;
							padding: 0 10px;
							margin: 0 0 30px;
							&#field_2_7{
								width: 100%;
								clear: both;
							}
							&#field_2_1{
								padding: 0 0 0 10px;
							}
							&#field_2_2{
								padding: 0 10px;
							}
							label{
								color: $bleu-p;
								font-size: 26px;
								font-family: $s-reg;
								line-height: 21px;
								letter-spacing: 0.01em;
								margin: 0;
								.gfield_required{
									display: none;
								}
							}
							.ginput_container{
								input,
								textarea{
									background: transparent;
									border: none;
									border-bottom: 1px solid #fff;
									width: 100%;
									outline: 0;
									color: #fff;
									font-size: 17px;
									font-family: $r-reg;
									letter-spacing: 0.01em;
									overflow: hidden;
									padding: 15px 15px 15px 0;
									&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
										color: #fff;
									}
									&::-moz-placeholder { /* Firefox 19+ */
										color: #fff;
									}
									&:-ms-input-placeholder { /* IE 10+ */
										color: #fff;
									}
									&:-moz-placeholder { /* Firefox 18- */
										color: #fff;
									}
								}
								select{
									option{
										color: #000;
									}
								}
								textarea{

								}
							}
							.validation_message{
								display: none;
							}
							&#field_1_10,
							&#field_1_11{
								clear: both;
								width: 100%;
							}
							&.gfield_error{
								.ginput_container{
									input,
									select,
									textarea{
										border-color: #ff0000 !important;
									}
								}
							}
						}
						&:after{
							display: table;
							content: "";
							clear: both;
						}
					}
				}
				.gform_footer{
					position: relative;
					margin: 20px 0 0 0;
					display: table;
					float: right;
					clear: both;
					width: auto;
					input{
						background: transparent;
						position: relative;
						display: table;
						color: $bleu-p;
						font-size: 1.09375vw;
						font-family: $r-med;
						letter-spacing: 0.01em;
						text-transform: uppercase;
						clear: both;
						transition-property: color;
						transition-duration: 0.3s;
						transition-delay: 0.3s;
						z-index: 1;
						height: 1.6145833333333335vw;
						border: none;
						outline: 0;
						margin: 0;
						@media screen and (max-width: 1280px){
							font-size: 14px;
							height: 20px;
						}
						@media screen and (max-width: 991px){
							float: right;
						}
					}
					&:before{
						background: $bleu-p;
						position: absolute;
						display: table;
						content: "";
						bottom: -4vw;
						width: 0px;
						height: 0px;
						transition-property: width, height;
						transition-duration: 0.3s, 0.3s;
						transition-delay: 0.3s, 0.3s;
						z-index: -1;
						left: 50%;
						transform: translate(-50%, 0);
					}
					&:after{
						background: $bleu-p;
						position: absolute;
						display: table;
						content: "";
						bottom: -0.425vw;
						width: 100%;
						height: 4px;
						transition-property: width;
						transition-duration: 0.3s;
						@media screen and (min-width: 1920px){
							height: 0.20833333333333334vw;
						}
					}
					&:hover{
						color: #fff;
						&:before{
							width: calc(100% + 1.5625vw);
							height: calc(100% + (0.625vw * 2));
						}
						&:after{
							width: 0;
						}
					}
				}
			}
		}
	}
}



#singCallAction{
	background: $bleu-t;
	padding: 8.333333333333334vw 6.510416666666667vw 22.708333333333336vw;
	.vague{
		position: absolute;
		right: 0;
		bottom: 10.208333333333334vw;
	}
	.partAction{
		float: left;
		width: 50%;
		@media screen and (max-width: 767px){
			width: 100%;
			float: initial;
			& + .partAction{
				margin: 40px 0 0 0;
			}
		}
		.partInt{
			max-width: 31.25vw;
			margin: 0 auto;
			text-align: center;
			@media screen and (max-width: 767px){
				max-width: 80%;
			}
			h3{
				color: #fff;
				font-size: 4.0625vw;
				font-family: $s-reg;
				letter-spacing: 0.01em;
				line-height: 4.0625vw;
				@media screen and (max-width: 767px){
					font-size: 31px;
					line-height: 31px;
				}
			}
			a,
			span{
				position: relative;
				display: table;
				color: $bleu-p;
				font-size: 1.09375vw;
				font-family: $r-med;
				letter-spacing: 0.01em;
				text-transform: uppercase;
				clear: both;
				transition-property: color;
				transition-duration: 0.3s;
				transition-delay: 0.3s;
				z-index: 1;
				height: 1.6145833333333335vw;
				cursor: pointer;
				margin: 2.0833333333333335vw auto 0;
				@media screen and (max-width: 1280px){
					font-size: 14px;
					height: 20px;
				}
				&:before{
					background: $bleu-p;
					position: absolute;
					display: table;
					content: "";
					bottom: -0.625vw;
					width: 0px;
					height: 0px;
					transition-property: width, height;
					transition-duration: 0.3s, 0.3s;
					transition-delay: 0.3s, 0.3s;
					z-index: -1;
					left: 50%;
					transform: translate(-50%, 0);
				}
				&:after{
					background: $bleu-p;
					position: absolute;
					display: table;
					content: "";
					bottom: -0.625vw;
					width: 100%;
					height: 4px;
					transition-property: width;
					transition-duration: 0.3s;
					@media screen and (min-width: 1920px){
						height: 0.20833333333333334vw;
					}
				}
				&:hover{
					color: #fff;
					&:before{
						width: calc(100% + 1.5625vw);
						height: calc(100% + (0.625vw * 2));
					}
					&:after{
						width: 0;
					}
				}
			}
			&#left{

			}
			&#right{

			}
		}
	}
}

#similar{
	background: $bleu-t;
	position: relative;
	padding: 0 0 11.979166666666668vw 0;
	.goutte{
		position: absolute;
		left: 0;
		bottom: 30.625vw;
	}
	#title{
		background: $bleu-f;
		position: absolute;
		padding: 6.770833333333334vw 8.333333333333334vw;
		width: 100%;
		max-width: 53.75vw;
		top: -14.010416666666668vw;
		z-index: 1;
		@media screen and (max-width: 767px){
			max-width: 70%;
		}
		h1{
			color: #fff;
			font-size: 4.0625vw;
			font-family: $s-reg;
			letter-spacing: 0.01em;
			line-height: 4.0625vw;
			text-align: right;
			@media screen and (max-width: 767px){
				font-size: 31px;
				line-height: 31px;
			}
		}
	}
	#similarList{

		.corpsSide{
			background: #fff;
			float: right;
			width: 100%;
			max-width: calc(100% - 13.177083333333334vw);
			padding: 7.65625vw 0;
			@media screen and (max-width: 767px){
				padding: 80px 0;
			}
			.corpInt{
				width: 100%;
				max-width: 69.58333333333334vw;
				margin: 0 auto;

				.sim-by-sim{
					float: left;
					width: 33.3333333333%;
					margin: 0 0 2.0833333333333335vw 0;
					.simInt{
						display: block;
						width: 100%;
						max-width: 19.947916666666668vw;
						position: relative;
						@media screen and (max-width: 991px){
							max-width: 90%;
							margin: 0 auto;
						}
						@media screen and (max-width: 580px){
// 							max-width: 100%;
						}
						.spasImg{
							background-size: contain;
							background-position: center center;
							background-repeat: no-repeat;
							width: 100%;
							height: 19.947916666666668vw;
							@media screen and (max-width: 991px){
								height: 250px;
							}
							@media screen and (max-width: 767px){
								height: 200px;
							}
							@media screen and (max-width: 420px){
								height: 175px;
							}
						}
						.contenu{
							text-align: center;
							margin: 1.0416666666666667vw 0 0 0;
							h5{
								color: $bleu-f;
								font-size: 0.9895833333333334vw;
								font-family: $r-bold;
								letter-spacing: 0.01em;
								@media screen and (max-width: 1280px){
									font-size: 14px;
								}
							}

							span{
								display: block;
								&.year{
									color: $bleu-f;
									font-size: 0.9895833333333334vw;
									font-family: $r-reg;
									letter-spacing: 0.01em;
									@media screen and (max-width: 1280px){
										font-size: 14px;
									}
								}
								&.price{
									color: $bleu-p;
									font-size: 0.9895833333333334vw;
									font-family: $r-bold;
									letter-spacing: 0.01em;
									@media screen and (max-width: 1280px){
										font-size: 14px;
									}
								}
							}
						}
					}
					&:nth-child(1){
						.prodInt{

						}
					}
					&:nth-child(2){
						.prodInt{
							margin: 0 auto;
						}
					}
					&:nth-child(3){
						.prodInt{
							float: right;
						}
					}
					@media screen and (max-width: 991px){
						width: 50%;
						.prodInt{
							float: initial !important;
							margin: 0 auto !important;
							max-width: 90%;
						}

					}
					@media screen and (max-width: 580px){
						width: 100%;
						.prodInt{
							float: initial !important;
							margin: 0 auto !important;
							max-width: 90%;
						}

					}
				}
			}
		}
	}
}

textarea{
	overflow: hidden !important;
}


#navPanelMobile{
	top:9vw;
	height:100vh;
	background: $bleu-f;
	position: fixed;
	width: 100%;
	display: none;
	z-index: 999999999999;
	overflow-y: scroll;
	&::-webkit-scrollbar {
	    width: 2px;

	}

	&::-webkit-scrollbar-track {
	    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	}

	&::-webkit-scrollbar-thumb {
	  background-color: $bleu-p;
	  outline: 0;
	}
	@media screen and (max-width:767px){
	    		top:15vw;
	    }

	ul{
		border-bottom: 2px solid #fff;
		li{
			&.current_page_item{
				background: $bleu-p;
			}
			a{
				display: block;
				color: #fff;
				font-size: 18px;
				font-family: $r-bold;
				letter-spacing: 0.01em;
				text-transform: uppercase;
				padding: 15px 25px;
			}
		}
	}
	#adresseForNav{
		margin: 40px 0 0 0;
		a{
							display: block;
							color: #fff;
							



						}
		span{
			display: block;
			color: #fff;
			padding: 0 25px;
			text-align: center;
			width: 100%;
			font-size: 18px;
			font-family: $r-bold;
			letter-spacing: 0.01em;
		}
	}

	#social{
		display: table;
		margin: 0px auto 0;
		ul{
			border: none !important;
			li{
				float: left;
				& + li{
					margin: 0 0 0 0;
				}
				a{
					font-size: 60px;
					color: #fff;
				}
			}
			&:after{
				display: table;
				content: "";
				clear: both;
			}
		}
	}
}


// Design
.vague{
	background-image: url(../images/vague.svg);
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	width: 15.104166666666668vw;
	height: 9.479166666666668vw;
	animation-name: imgmove;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}
.vague-bleu{
	background-image: url(../images/vague-bleu.svg);
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	width: 15.104166666666668vw;
	height: 9.479166666666668vw;
	animation-name: imgmove;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}

.goutte{
	background-image: url(../images/goutte.png);
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	width: 11.614583333333334vw;
	height: 15.46875vw;
	animation-name: imgmove;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}
.goutte-w{
	background-image: url(../images/goutte-blanche.png);
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	width: 11.614583333333334vw;
	height: 15.46875vw;
	animation-name: imgmove;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}
.goutte-bleu{
	background-image: url(../images/goutte-bleu.png);
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	width: 11.614583333333334vw;
	height: 15.46875vw;
	animation-name: imgmove;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}
.vague-rond-bleu{
	background-image: url(../images/vague-rond-bleu.svg);
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	width: 15.416666666666668vw;
	height: 6.614583333333334vw;
	animation-name: imgmove;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}
.vague-rond{
	background-image: url(../images/vague-rond.svg);
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	width: 15.15625vw;
	height: 6.510416666666667vw;
	animation-name: imgmove;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}
.circle-d{
	background-image: url(../images/circle.svg);
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	width: 14.114583333333334vw;
	height: 1.25vw;
	animation-name: imgmove;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}
.circle-d-f{
	background-image: url(../images/circle-f.svg);
	background-size: 100% 100%;
	background-position: center center;
	background-repeat: no-repeat;
	display: block;
	width: 14.114583333333334vw;
	height: 1.25vw;
	animation-name: imgmove;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}

// breadcum
#breads{
	@include bp('l'){
		    font-size: 15px!important;
	}
	margin: 3.385416666666667vw 0 0 9vw;
	font-size: .885vw
}

#warn{

	h1{

		font-size: 20px !important;
	}
}


.gform_confirmation_message{
	color: #fff;
	font-size: 30px;
	font-family: $s-reg;
	padding: 0 60px;
	@media screen and (max-width: 580px){
		padding: 0 40px;
	}
	@media screen and (max-width: 420px){
		padding: 0 20px;
	}
}



// PRODUCT HOVER
.prodInt,
.sim-by-sim,
.spa-by-spa{

	.spasImg{
		position: relative;
		&:after{
			background: $bleu-p;
			position: absolute;
			display: block;
			content: "";
			opacity: 0;
			top: 0;
			left: 0;
			width: 100%; height: 100%;
			transition: 0.4s;
		}

	}
	&:hover{
		.spasImg{
			&:after{
				opacity: 0.5;
			}
		}
	}
}

.new{
	z-index: 9;
	span{
		font-family: $s-reg;
	}
}



#quatrecentquatre{
	padding: 150px 60px;
	@media screen and (max-width: 580px){
		padding: 100px 40px;
	}
	h1{
		text-align: center;
		color: #000;
		font-size: 40px;
		font-family: $s-reg;
		letter-spacing: 0.01em;
		line-height: 42px;
	}
}
        /*------------------------------------*\
            MY CSS
        \*------------------------------------*/

#promotions .slick-arrow, #promoMobile .slick-arrow{
	 	display:none!important;
	 }


.slick-next:before {
    content: '>';
    font-size: 16vw;
    font-family: monospace;
    color: #6dd6e7;
    line-height: 0;
    position: relative;
    right: 5vw;
    opacity: 0.8;
}

.slick-prev:before {
    content: '<';
    font-size: 16vw;
    font-family: monospace;
    color: #6dd6e7;
    line-height: 0;
    position: relative;
    opacity: 0.8;
        left: 2vw;
}

/*------------------------------------*\
    ONGLETS
\*------------------------------------*/

.tab.active {
	z-index:-1;
	color:black!important;

}
.tab{
	z-index:9;
	width:20vw!important;
	cursor: pointer;
}

.tab_content.open {
    opacity: 1;
    z-index: 5;
    transition: .3s;
}

.tab_content .tab_title {
    /*transform: scale(0.8);*/
    opacity: 0;
    transition: .2s ease-out;
}

.tab_content .tab_text {
    transform: scale(1);
    opacity: 0;
    z-index:-1;
    /*transition: .2s ease-in-out;*/
}

.tab_content.open .tab_title,
.tab_content.open .tab_text {
    transform: scale(1);
    opacity: 1;
    z-index: 9;
    transition: .4s ease-in-out;
}


/*------------------------------------*\
    DROPDOWN
\*------------------------------------*/

.dropdown{
		display:none!important;
	@include bp('m') {
	display:none;
	}
	top:0vw;
	display:flex;
	position:absolute;
	h4{
	margin-right:1vw;
	font-size: 1.5vw;
	@media screen and (max-width: 580px){
		font-size: 3vw;
	}
}
	option{
	font-size: 1.5vw;
	}
	select{
	font-size: 1.5vw;
	@media screen and (max-width: 580px){
		font-size: 3vw;
	}
	}
	
}


/*------------------------------------*\
    ARROW
\*------------------------------------*/

.arrow{
	@include bp('m'){
    transform: none;
    transition: all .2s;
    position: absolute;
    z-index: 8;
    right: 2.2vw;
    top: 1.8vw;
    border-left: 2vw solid transparent;
    border-right: 2vw solid transparent;
    border-top: 2vw solid #a2a2a2;
    pointer-events: none;
    }
}

.arrow_container.open .arrow{
    transform: rotate(180deg);
}


/*------------------------------------*\
    EDGE
\*------------------------------------*/

@supports (-ms-ime-align:auto) {
	#intro .partIntro#right video{
		height:auto!important;
		width:auto!important;

	}
	#montre #listNewSpas #right{
		top: 15% !important;
	}


}
