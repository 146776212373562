footer{
	background: $bleu-f;
	height: 19.6875vw;
	@media screen and (max-width: 767px){
		height: auto;
		padding: 60px 0;
	}
	#wrapFooter{
		position: relative;
		max-width: 90.3125vw;
		margin: 0 auto;
		height: 100%;
		#footBottom{
			position: absolute;
			bottom: 0;
			width: 100%;
			height: 5.208333333333334vw;
			@media screen and (max-width: 767px){
				position: relative;
				bottom: initial;

			}
			p{
				position: absolute;
				top: 50%;
				left: 0;
				transform: translate(0, -50%);
				@media screen and (max-width: 767px){
					position: relative;
					top: initial;
					transform: initial;
				}
			}
			a{
				position: absolute;
				top: 50%;
				right: 0;
				transform: translate(0, -50%);
				@media screen and (max-width: 767px){
					position: relative;
					top: initial;
					transform: initial;
				}
				img{
					margin: 0 0 0 0.9895833333333334vw;
				}
			}
			p,
			a{
				color: #fff;
				font-size: 0.625vw;
				font-family: 'Open Sans', sans-serif;
				letter-spacing: 0.01em;
				@media screen and (max-width: 1380px){
					font-size: 11px;
				}
				@media screen and (max-width: 767px){
					margin: 30px auto 0;
					text-align: center;
					display: table;
				}
			}
		}
		#logo{
			position: absolute;
			top: 4.166666666666667vw;
			left: 0;
			@media screen and (max-width: 767px){
				position: relative;
				top: initial;
			}
			a{

				img{
					display: block;
					width: auto;
					height: 7.447916666666667vw;
					@media screen and (max-width: 767px){
						height: 80px;
						margin: 0 auto;
					}
				}
			}
		}
		#infos{
			position: absolute;
			top: 3.854166666666667vw;
			right: 0;
			width: 100%;
			max-width: 61.35416666666667vw;
			height: 8.072916666666668vw;
			@media screen and (max-width: 1380px){
				height: 120px;
			}
			@media screen and (max-width: 767px){
				position: relative;
				top: initial;
				max-width: 100%;
				height: auto;
			}
			.partInfo{
				position: absolute;
				top: 0;
				@media screen and (max-width: 767px){
					position: relative;
					top: initial;
					left: initial !important;
					margin: 30px auto 0;
					display: table;
					text-align: center;
				}
				&#coords{
					left: 0;
					span{
						display: block;
						max-width: 11.458333333333334vw;
						color: #fff;
						font-size: 0.8854166666666667vw;
						font-family: $r-reg;
						letter-spacing: 0.01em;
						line-height: 1.09375vw;
						@media screen and (max-width: 1380px){
							font-size: 12px;
							max-width: 180px;
							line-height: 13px;
						}
					}

					#social{
						ul{
							display: flex;
							margin: 0.625vw 0 0 0;
							li{
								position: relative;
								border: 2px solid #fff;
								width: 1.5104166666666667vw;
								height: 1.5104166666666667vw;
								border-radius: 10.5104166666666667vw;
								@media screen and (max-width: 1280px){
									width: 20px;
									height: 20px;
								}
								& + li{
									margin: 0 0 0 0.5208333333333334vw;
								}
								a{
									position: relative;
									display: block;
									width: 100%;
									height: 100%;
									color: #fff;
									
									i{
										position: absolute;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%);
									}
								}
								&:hover{
									border-color: $bleu-p;
									a{
										color: $bleu-p;
									}
								}
							}
						}
					}
				}
				&#store{
					left: 20.729166666666668vw;
				}
				&#information{
					left: 41.145833333333336vw;
				}
				&#accordD{
					right: 0;
					img{
						display: block;
						width: auto;
						height: 3.854166666666667vw;
						@media screen and (max-width: 767px){
							height: 50px;
						}
					}
				}
				ul{

					li{

						a{
							display: block;
							color: #fff;
							font-size: 0.8854166666666667vw;
							font-family: $r-reg;
							letter-spacing: 0.01em;
							line-height: 1.09375vw;
							&:hover{
								color: $bleu-p;
							}
							@media screen and (max-width: 1380px){
								font-size: 12px;
								line-height: 13px;
							}
						}
					}
				}
				a{
							display: block;
							color: #fff;
							font-size: 0.8854166666666667vw;
							font-family: $r-reg;
							letter-spacing: 0.01em;
							line-height: 1.09375vw;
							&:hover{
								color: $bleu-p;
							}
							@media screen and (max-width: 1380px){
								font-size: 12px;
								line-height: 13px;
							}
						}
				h5{
					color: $bleu-p;
					font-size: 1.09375vw;
					line-height: 1.09375vw;
					letter-spacing: 0.01em;
					margin: 0 0 0.5208333333333334vw 0;
					font-family: $s-reg;
					@media screen and (max-width: 1380px){
						font-size: 14px;
						line-height: 15px;
					}
				}
			}
		}
	}
}