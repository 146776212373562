/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	's': 		580px,
	'm': 		 767px,
	'l': 		 992px,
	'xl': 		1199px,
	'xxl': 		1440px
);

/// @require $breakpoints
@mixin bp($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media all and (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
 
  // If the key doesn't exist in the map
  @else {
    @warn "No value `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}


// Usage:
// @include bp('s') {}


/* =======================================================================
Mixin center
========================================================================== */
@mixin center{
            display: flex;
    justify-content: center;
    align-items: center;
        margin: auto;
}


/* =======================================================================
Font-face
========================================================================== */

// Roboto
@font-face {
    font-family: 'robotoBold';
    src: url(../fonts/roboto/Roboto-Bold.ttf);
}
@font-face {
    font-family: 'robotoBlack';
    src: url(../fonts/roboto/Roboto-Black.ttf);
}
@font-face {
    font-family: 'robotoReg';
    src: url(../fonts/roboto/Roboto-Regular.ttf);
}
@font-face {
    font-family: 'robotoItalic';
    src: url(../fonts/roboto/Roboto-Italic.ttf);
}
@font-face {
    font-family: 'robotoMed';
    src: url(../fonts/roboto/Roboto-Medium.ttf);
}


// Sablo
@font-face {
    font-family: 'sabloReg';
    src: url(../fonts/sablo/sablo-reg.ttf);
}

/* =======================================================================
Variables
========================================================================== */
// Composant
$corps: 1170px;
$corps-side: calc(100% - 8.958333333333334vw);

// Color
$bleu-f: #002131;
$bleu-p: #69e5f4;
$bleu-t: #3990ae;
$color-text: #0b374a;

// Fonts
// Sablo
$s-reg: 'sabloReg';

// Roboto
$r-bold: 'robotoBold';
$r-black: 'robotoBlack';
$r-reg: 'robotoReg';
$r-italic: 'robotoItalic';
$r-med: 'robotoMed';