

/*********** GLOBAL ************/

#garantieOldbuy .corpsSide #oldBuy .contenu span::before, #singCallAction .partAction .partInt a::before, #singCallAction .partAction .partInt span::before, .contenu a::before{
  display: none !important;
}
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt a:hover,
#garantieOldbuy .corpsSide #oldBuy .contenu span:hover,
#loadMore:hover,
#productPageUsage #products #left form input[type=submit]:hover,
#urgenceHome .corpsWrap .contenu #left .leftInt a:hover,
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide a:hover,
#urgenceServ .corpsWrap .contenu #left .leftInt a:hover, #loadMore:hover,
#garantieOldbuy .corpsSide #oldBuy .contenu span:hover, #productPageUsage #products #left form input[type=submit]:hover,
header #wrapHead nav .navInt ul li.current-menu-item a:hover, #introCouv .wrap #right #rightInt #tete #bottom span#reserver:hover, #introCouv .wrap #right #rightInt #tete #bottom span#reserver:before, #introUse .wrap #right #rightInt #tete #bottom span#reserver:hover{
  color: #002131 !important;
  background: none !important;
}
#introUse .wrap #right #rightInt #tete #bottom span#reserver:hover:before{
  display: none;
}
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_footer input:hover {
  background: none;
  color: #fff;
}
#popOld{
  margin-top: 5vw;
}
#popOld #contenu #forClose, #popReserve #contenu #forClosea {
  top: 7%;
}
header #wrapHead nav {
    width: 100vw;
}
header #wrapHead nav .navInt ul#headMenu {
    width: 70vw;
}
#intro .partIntro#left {
    background-position: 67% 100% !important;
}
header #wrapHead nav .navInt ul#menuPartRight {
    width: auto !important;
    @include bp('l'){
      margin-right:20px;
    }
}
input.search-input {
    width: 0px;
    margin: 0;
    padding: 0;
    border: 0px;
}
button.search-submit {
    -webkit-appearance: none;
    background: none;
    border: 0px;
}
#introUse {
    position: relative;
    z-index: 9999;
}
form.search {
    display: flex;
}
.search button {
  
   background-color: transparent;
   border: none;
   outline: none;
	height: 25px;
	width: 25px;
   padding: 0;
   position: relative;
   
}

.search button img {
  @include bp('l'){
    width: 36px;
    top: -3px;
    height: 36px;

   }

      top: 5px;
      width: 1.63vw;
    height: 1.63vw;
   opacity: 1;
   transition: opacity 0.5s;
   position: absolute;
   left: 0;
}

.search button::after {
     @include bp('l'){
         width: 36px;
    height: 36px;
        top: -3px;
   }
   content: "";
   position: absolute;
      width: 1.63vw;
    height: 1.63vw;
   display: block;
   opacity: 0;
   pointer-events: none;
   background-image: url(../images/search.svg);
   background-repeat: no-repeat;
   background-size: contain;
      top: 5px;
   transition: opacity 0.5s;
}

.search button:hover::after {
   opacity: 1;
}

.search button:hover img {
   opacity: 0;
    @include bp('l'){
         width: 36px;
    height: 36px;
   }
}

.search .search-input {
   border: 0px;
   width: 0;
   height: 1.36vw;
   background-color: transparent;
   border-bottom: 2px solid #69e5f4;
   border-radius: 0px;
   color: #ffffff;
   opacity: 0;
   font-family: 'Raleway', sans-serif;
   transition: all 0.5s;
   -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
}

.search .search-input.visible {
  @include bp('s'){
     font-size: 3.8vw;
  }
   width: 12.59vw;
   height: 100%;
   opacity: 1;
   font-size:15px;
}

.search-input.visible::-webkit-input-placeholder {
   /* Chrome/Opera/Safari */
   color: #ffffff;
   opacity: 1;
}

.search-input.visible::-moz-placeholder {
   /* Firefox 19+ */
   color:  #ffffff;
   opacity: 1;
   transition: opacity 0.5s;
}

.search-input.visible:-ms-input-placeholder {
   /* IE 10+ */
   color:  #ffffff;
   opacity: 1;
}

.search-input.visible:-moz-placeholder {
   /* Firefox 18- */
   color:  #ffffff;
   opacity: 1;
}

.search-input:focus::-webkit-input-placeholder {
   /* Chrome/Opera/Safari */
   opacity: 0;
}

.search-input:focus::-moz-placeholder {
   /* Firefox 19+ */
   opacity: 0;
}

.search-input:focus:-ms-input-placeholder {
   /* IE 10+ */
   opacity: 0;
}

.search-input:focus:-moz-placeholder {
   /* Firefox 18- */
   opacity: 0;
}
#header_page_recherche{
  padding: 6.041666666666667vw 0 2.8125vw;
}
#header_page_recherche #titleBox {
    background: #fff;
    position: relative;
    width: 45.520833333333336vw;
    height: 16.875vw;
    border: .8854166667vw solid #69e5f4;
    border-left: none!important;
}
#header_page_recherche #titleBox:before {
    position: absolute;
    background-image: url(../images/vague-prod.jpg);
    background-size: contain;
    background-position: 100%;
    background-repeat: no-repeat;
    top: 50%;
    left: 14vw;
    display: table;
    content: "";
    width: 74.47916666666667vw;
    height: 200%;
    clear: both;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: -1;
}
#header_page_recherche #titleBox:after {
    display: table;
    content: "";
    clear: both;
}
#header_page_recherche .vague {
    position: absolute;
    right: 0;
    bottom: 0;
}
#header_page_recherche h1 {
  @media screen and (max-width: 420px){
    font-size:8vw!important;
  }

    position: absolute;
    top: 50%;
    right: 8.333333333333334vw;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    font-size: 4.0625vw;
    font-family: sabloReg;
    line-height: 4.0625vw;
    color: #002131;
    text-align: right;
}
.content_results{
  width: 90%;
  margin: 0 auto;
  min-height: 10vw;
  padding: 20px;
}
section.content_results article {
  h2{
    @include  bp('m'){
      font-size:3.5vw;
    }
        @include  bp('s'){
      font-size:5vw;
    }
    font-size:2.6vw;
  }
    margin: 30px 0;
    position: relative;
}
section.content_results article::before {
  content: "•";
  position: absolute;
  left: -32px;
  top: 0;
  font-size: 31px;
  line-height: 1;
  color: #69e5f4;
  font-weight: bold;
}
section.content_results article .date, section.content_results article .author, section.content_results article .comments  {
    display: none;
}
#productPageUsage #topUsage.usage #titleBox:before{
  background-image: url(../images/vague-prod3.png) !important;
}
.couvercles_page#productPageUsage #topUsage #titleBox:before{
  background-image: url(../images/vague-prod.png) !important;
}

.meubles_ext#productPageUsage #topUsage #titleBox:before{
  background-image: url(../images/eau-meubles-exterieur.png) !important;
}
.spas_nage_page#productPageUsage #topUsage #titleBox:before{
  background-image: url(../images/vague-prod2.png) !important;
}
#garantieOldbuy:before {
    background-image: url(../images/vague_accueil-garantie.png) !important;
}
.prodInt .contenu {
    text-align: center;
    margin: 0;
}
.prodInt .contenu h5, .prodInt .contenu span.year{
    line-height: 1;
    margin: 5px 0;
}
#loadMore {
    margin: 0 auto 50px !important;
    transition: all 500ms  !important;
    position: relative !important;
    font-size: 18px !important;
    line-height: 1.5 !important;
}
#loadMore::after {
    background: #69e5f4;
    position: absolute;
    display: table;
    content: "";
    bottom: -.625vw;
    width: 100%;
    height: 4px;
    -webkit-transition-property: width;
    transition-property: width;
    -webkit-transition-duration: .3s;
    transition-duration: .3s;
}
#loadMore:hover::after {
  width: 0px;
}
.border_blue{
  width: 100%;
  max-width: 98px;
  height: 4px;
  background-color: #69e5f4;
  transition: all 500ms;
}
#products #left form input[type=submit]:hover .border_blue{
    width: 0px;
}
#productPageUsage #products #left #promoSlide #pSlide .slick-list .slick-track .slick-slide .slideInt{
    -webkit-font-smoothing: antialiased;
}
footer #wrapFooter #infos .partInfo#coords span{
  max-width: 300px;
}
.pagination {
    width: 100%;
    display: flex;
    justify-content: center;
}
.page-numbers {
    font-size: 18px;
    line-height: 1;
    padding: 0 5px;
}
#aboutService .wrap #servList .slick-list .slick-track .slick-slide.slick-current span {
    background: #69e5f4;
    color: #fff;
    font-family: sabloReg;
}
#aboutService:before {
    background-image: url(../images/vagues_services.png);
}
#blocVague:before {
    background-image: url(../images/vague_entreprise.png);
}
div#tete p {
  display: block;
  margin-top: 30px;
    color: #002131;
    font-size: .8854166666666667vw;
    font-family: robotoReg;
    letter-spacing: .01em;
    text-transform: none;
}
/*********** RESPONSIVE ************/

@media screen and (max-width: 1300px) {
  #introUse #sceau {
    top: -7vw;
  }
}
@media screen and (max-height: 900px){
  #popReserve #contenu {
      top: 10vw;
  }
}
@media screen and (max-width: 991px) {
  #montre #listNewSpas {
    height: auto;
    display: flex;
    flex-direction: column-reverse;
  }
  #introNew #right #contenu #bottom .partBottom img {
    height: 40px;
    width: 40px;
    margin: 0 auto 10px;
  }
  #introEntreprise .vague-rond-bleu {
    display: none;
  }

  #introEntreprise #left {
    max-width: unset;
  }

  h1 {
    font-size: 30px !important;
    line-height: 1.2 !important;
    letter-spacing: normal !important;
  }

  p {
    font-size: 15px !important;
    line-height: 1.2 !important;
    letter-spacing: normal !important;
  }

  #expert #blocExpert {
    position: relative;
    width: 90%;
    height: 50vw;
    float: none;
    top: 30px;
    right: 0;
    left: 5%;
  }

  #salleMontre {
    margin: 0;
  }

  #salleMontre #salleImg {
    width: 100%;
    height: 66.875vw;
  }

  #salleMontre #contenu {
    background: #002131;
    position: relative;
    padding: 8.541666666666668vw 9.114583333333334vw 9.895833333333334vw 8.333333333333334vw;
    float: right;
    margin: -30px 0 0;
    z-index: 3;
    max-width: 95vw;
  }

  #blocVague .circle-d,
  #inventaire .circle-d-f,
  #salleMontre #contenu .vague,
  #urgenceEntreprise .goutte {
    display: none;
  }

  #inventaire #wrap {
    max-width: 90%;
  }

  #inventaire #wrap h1 {
    color: #69e5f4;
    font-size: 34px !important;
    line-height: 1.5 !important;
    font-family: robotoBlack;
    text-transform: uppercase;
  }

  #inventaire #wrap p {
    position: relative;
    top: auto;
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    padding-left: 9%;
    text-align: left;
  }

  #blocVague #wrap {
    position: relative;
    max-width: 90%;
    margin: 0 auto;
    z-index: 2;
  }

  #blocVague #wrap .partBloc {
    float: none;
    width: 100%;
  }

  #blocVague #wrap .partBloc .blocInt {
    max-width: unset;
    margin: 50px 0;
  }

  #blocVague #wrap .partBloc .blocInt h1 {
    font-size: 44px !important;
    line-height: 1 !important;
  }

  section#urgenceEntreprise #wrap {
    max-width: unset !important;
  }

  #urgenceEntreprise #wrap h1 {
    font-size: 50px !important;
    position: relative;
    float: none;
    text-align: right;
    margin: 0;
    max-width: unset;
    padding-right: 30px;
  }

  #urgenceEntreprise #wrap p {
    color: #fff;
    font-size: 1.3541666666666667vw;
    line-height: 2.03125vw;
    letter-spacing: 0.01em;
    max-width: 60%;
    text-align: right;
    padding: 15px;
    margin: -55px 0 50px 20px;
  }
  #introContact {
    height: 400px;
  }
  #introContact #left{
    width: 100%;
    height: 100%;
    float: none;
  }
  #introContact #right, #formContact .circle-d, #introServ .circle-d, #introServ .vague-rond, #introServ:after, .goutte, .goutte-w, .vague-bleu, .circle-d, .vague-rond-bleu, .vague{
    display: none;
  }
  #formContact .wrap .contenu #right {
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    left: auto;
    width: 100%;
    transform: none;
  }
  #formContact .wrap {
    position: relative;
    margin: 0 0 0 auto;
    max-width: unset;
  }
  #formContact .wrap .contenu {
    background: none;
  }
  #formContact .wrap h1{
    padding-left: 5%;
  }
  #formContact .wrap .contenu #left{
    width: 95%;
    background: #002131;
    margin: 0 0 0 5%;
    padding: 20px;
  }
  #formContact .wrap .contenu #left h4 {
    font-size: 24px;
    margin: 15px 0;
  }
  #formContact .wrap .contenu #left #coords span {
    display: block;
    color: #fff;
    font-size: 16px;
    font-family: robotoReg;
    line-height: 1.2;
    letter-spacing: normal;
  }
  #formContact .wrap .contenu #left #coords #social h5 {
    display: block;
    color: #69e5f4;
    font-size: 20px;
    font-family: robotoMed;
    line-height: 1;
    letter-spacing: normal;
    text-transform: uppercase;
    margin: 15px 0;
  }
  #formContact .wrap .contenu #left #coords #social ul li {
    width: 20px;
    height: 20px;
  }
  #formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li label {
    font-size: 16px;
    letter-spacing: normal;
  }
  #formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container input, #formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container select, #formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container textarea {
    font-size: 16px;
    line-height: 100%;
    letter-spacing: normal;
  }
  #formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_footer input {
    font-size: 16px;
  }
  #formContact #urgenceContact .corpsWrap .contenu #right {
    display: block;
    // width: 50vw;
    // height: 50vw;
    // position: relative;
    // transform: translateX(41vw);
  }
  #popReserve #contenu, #popOld #contenu .gform_wrapper, #popOld #contenu {
    width: 90%;
    max-width: unset;
        margin-top: 22vw;
  }
  #popReserve #contenu .gform_wrapper {
    max-width: unset;
    padding: 15px;
  }
  #popReserve #contenu .gform_wrapper form .gform_body ul li, #popOld #contenu .gform_wrapper form .gform_body ul li {
    float: none;
    width: 100%;
    clear: none;
    padding: 0 10px;
    margin: 0 0 30px;
  }
  #popReserve #contenu .gform_wrapper form .gform_footer input, #popOld #contenu .gform_wrapper form .gform_footer input {
    font-size: 16px;
    height: 20px;
    line-height: 1;
    padding-bottom: 5px;
  }
  #popOld #contenu #forClose, #popReserve #contenu #forClosea {
    top: 4%;
    z-index: 999;
  }
  #introServ .contenu {
    padding: 15px;
    max-width: unset;
  }
  #introServ .contenu h1 {
    max-width: unset;
  }
  #introServ #introImg {
    position: relative;
    width: 95%;
    height: 300px;
    right: auto;
    bottom: auto;
    margin: 50px 0 0 auto;
  }
  #buyBroken {
    height: auto;
  }
  #buyBroken .contenu {
    padding: 5%;
    padding-bottom: 150px;
  }
  #buyBroken .contenu h1 {
    float: none;
        padding: 0;
    width: 100%;
    margin: 30px 0;
  }
  #buyBroken .contenu #right, #conseil #right{
    float: none;
    width: 100%;
    max-width: unset;
  }
  #buyBroken .contenu #right p, #conseil #right .contenu p {
    max-width: unset;
    margin: 12px 0;
  }
  #buyBroken .contenu #right p+p, #conseil #right .contenu p+p {
    margin: 12px 0;
  }
  #conseil {
    @include bp('m'){
      display:none;
    }
    position: relative;
    padding: 0 0 200px 0;
    display: flex;
    flex-direction: column-reverse;
  }
  #conseil #conseilImg {
    position: relative;
    top: auto;
    left: auto;
    width: 95%;
        height: 68vw;
    z-index: 2;
  }
  #conseil #right .contenu h1 {
    margin: 30px 0 !important;
        max-width: 100%;
        float:none;
  }
  #aboutService .wrap {
    width: 100%;
    max-width: unset;
    float: none;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
  }
  #aboutService .wrap #servListContent {
    width: 100%;
    float: none;
    left: auto;
  }
  #aboutService .wrap #servList {
      background: transparent;
      width: 100%;
      position: relative;
      float: none;
      top: auto;
      left: auto;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: none;
  }
  #aboutService .wrap #servList .slick-list .slick-track .slick-slide {
    width: 100% !important;
  }
  #aboutService {
    padding: 0;
    background: none;
  }
  #aboutService .wrap #servList .slick-list .slick-track .slick-slide span {
    padding: 10px;
    font-size: 15px;
  }
  #servList .slick-slider .slick-track, #servList .slick-slider .slick-list{
    height: 100% !important;
  }
  #aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt {
    position: relative;
    max-width: unset;
    margin: 0;
    z-index: 1;
    padding: 15px;
  }
  #aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemTitle {
         bottom: 0%;
    left: 18px;
    max-width: 300px;
  }
  #aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemTitle span {
    font-size: 60px;
    line-height: 55px;
    max-width: unset;
  }
  #financement {
    padding: 50px 0;
  }
  #financement .contenu {
    position: relative;
    max-width: unset;
    margin: 0;
  }
  #financement .contenu h1 {
    max-width: 95%;
    margin: 0 auto;
  }
  #financement .contenu p {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    max-width: 80%;
    margin: -40px auto 0;
    text-align: left;
  }
  #financement .contenu img {
    display: block;
    width: 50%;
    height: auto;
    max-height: 120px;
    right: auto;
    position: relative;
    top: auto;
    margin-top: 90px;
    margin-left: auto;
    margin-right: auto;
  }
  #urgenceServ .corpsWrap {
    width: 100%;
    max-width: 95%;
    margin: 0;
    height: 100%;
    float: none;
  }
  #urgenceServ .corpsWrap .contenu #right {
    display: block;
    width: 60vw;
    height: 40vw;
        right: -7vw;
  }
  #necessaire {
    background: #3990ae;
    padding: 30px 0 60px;
  }
  #necessaire .wrap {
    text-align: center;
    max-width: 95%;
    margin: 0 auto;
  }
  #necessaire .wrap h1 {
    max-width: 95%;
    margin: 30px auto;
  }
  #distributeurs {
    padding: 30px 0;
  }
  #distributeurs .contenu {
    margin: 0 auto;
    max-width: 95%;
  }
  #distributeurs .contenu .secTitle span {
    font-size: 18px
  }
  #distributeurs .contenu .secTitle{
    margin: 30px 0;
  }
  #fonctionnalite .wrap h1{
    font-size: 16px!important;
  }
  footer, footer #wrapFooter {
    height: auto;
    min-height: 24vw;
    padding: 60px 0 80px;
  }
  footer #wrapFooter #infos .partInfo#coords span {
    word-break: break-word;
}
#similar #similarList .corpsSide .corpInt .sim-by-sim {
  width: 33.3333333333% !important;
}
footer #wrapFooter #logo {
    position: relative;
    top: auto;
}
footer #wrapFooter #logo a img {
    height: 80px;
    margin: 0 auto;
}
footer #wrapFooter #infos {
    position: relative;
    top: auto;
    max-width: 100%;
    height: auto;
}
footer #wrapFooter #infos .partInfo {
    position: relative;
    top: auto;
    left: auto!important;
    margin: 30px auto 0;
    display: table;
    text-align: center;
}
footer #wrapFooter #footBottom {
    position: relative;
    bottom: auto;
}
footer #wrapFooter #footBottom a, footer #wrapFooter #footBottom p {
    margin: 30px auto 0;
    text-align: center;
    display: table;
}
#introUse #sceau {
  top: -7vw;

    @media screen and (min-width: 767px){

    display:none;
  }
}
#products #left {
    padding-top: 20px;
}
footer #wrapFooter #logo, #coords h5, footer #wrapFooter #infos .partInfo#store, footer #wrapFooter #infos .partInfo#information, footer #wrapFooter #infos .partInfo#accordD{
  display: none;
}
footer {
    padding: 0 !important;
}
footer, footer #wrapFooter{
  padding-top: 20px;
}
footer #wrapFooter #infos .partInfo#coords span {
    max-width: unset;
    // font-size: 20px;
    line-height: 1.2;
}
footer #wrapFooter #infos .partInfo#coords #social ul li {
    width: 50px;
    height: 50px;
    margin: 0 10px;
}
footer #wrapFooter #infos .partInfo#coords #social ul li a i {
    font-size: 25px;
}
footer #wrapFooter #infos .partInfo#coords #social ul {
    margin: 30px 0;
    justify-content: center;
}
#garantieOldbuy .corpsSide #oldBuy .contenu h2 {
    max-width: 100%;
    font-size: 38px!important;
    line-height: 1;
}
#garantieOldbuy .corpsSide #oldBuy {
    height: auto;
    padding: 20px;
}
#garantieOldbuy .corpsSide #oldBuy #oldimg {
    display: block;
    right: -30px;
    bottom: 0;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
}
#garantieOldbuy .corpsSide #oldBuy .contenu {
    padding-bottom: 64px;
}
#garantieHome .corpsWrap .contenu {
    max-width: 620px;
    display: flex;
    flex-direction: column-reverse;
}
#garantieHome .corpsWrap .contenu #right {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
}
#garantieHome .corpsWrap .contenu #right img {
    display: block;
    width: 25vw;
    height: auto;
    margin: 0;
}
#urgenceHome .corpsWrap .contenu #right {
    display: block;
}
#garantieHome .corpsWrap .contenu #right{

      justify-content: flex-start;

}

}
@media screen and (max-width: 600px) {
div#tete p {
  display: block;
    color: #002131;
    font-size: 4vw;
    font-family: robotoReg;
    letter-spacing: .01em;
    text-transform: none;
}
#popOld #contenu #forClose, #popReserve #contenu #forClosea {
  top: 5%;
  z-index: 999;
}
#navPanelMobile ul li a {
    display: block;
    color: #fff;
    font-size: 16px;
    font-family: robotoBold;
    letter-spacing: .01em;
    text-transform: uppercase;
    padding: 5px 25px;
}
#navPanelMobile #adresseForNav span {
    font-size: 16px;
}
#navPanelMobile #social ul li a {
    font-size: 35px;
    color: #fff;
}
#navPanelMobile #adresseForNav {
    margin: 20px 0 0;
}
#introUse #sceau {
  top: 0vw;
}

#fonctionnalite {
    overflow: hidden;
}
header #wrapHead nav .navInt ul li#search {
    position: relative;
    width: auto;
    top: auto;
    left: auto;
    transform: none;
    margin-right: 30px !important;
}
.search .search-input.visible {
    width: 100%;
    opacity: 1;
    line-height: 1.2;
    height: 100%;
}
#header_page_recherche #titleBox {
    height: 30vw;
}

}

@media screen and (max-width: 580px) {

  #similar #similarList .corpsSide .corpInt .sim-by-sim {
  width: 100% !important;
}

#marques .corpsWrap #listMarques .wrapMarque+.wrapMarque{
  border:none;
}



}

@media screen and (max-width: 782px){

#garantieHome .corpsWrap .contenu #right{

      justify-content: flex-start;

}

#garantieHome .corpsWrap .contenu #right img {
  width:33vw;
}


}

// @media screen and (max-width: 767px){

//   #garantieHome .corpsWrap .contenu #left h1{

//       top:-20vw;
// }

// }