#productPageUsage{
	#topUsage{
		padding: 6.041666666666667vw 0 2.8125vw 0;
		.vague{
			position: absolute;
			right: 0;
			bottom: 0;
		}
		
		#titleBox{
			background: #fff;
			position: relative;
			width: 45.520833333333336vw;
			height: 16.875vw;
			border: 0.8854166666666667vw solid $bleu-p;
			border-left: none !important;
			@media screen and (max-width: 991px){
				padding: 2vw;
				margin:0!important;
			@include center;
//     text-align: center;
			}
			@media screen and (max-width: 580px){
			
				padding: 0vw;
			}
			&:before{
				position: absolute;
				background-image: url(../images/vague-prod.jpg);
				background-size: contain;
				background-position: right center;
				background-repeat: no-repeat;
				top: 50%;
				left: 14vw;
				display: table;
				content: "";
				width: 74.47916666666667vw;
				height: 200%;
				clear: both;
				transform: translate(0, -50%);
				z-index: -1;
			}
			&:after{
				display: table;
				content: "";
				clear: both;
			}
			h1{
				position: absolute;
				top: 50%;
				right: 8.333333333333334vw;
				transform: translate(0, -50%);
				max-width: 17.708333333333336vw;
				font-size: 4.0625vw;
				font-family: $s-reg;
				line-height: 4.0625vw;
				color: $bleu-f;
				text-align: right;
				@media screen and (max-width: 991px){
					font-size: 52px;
					line-height: 52px;
					max-width: 227px;
// 					position: relative;
					float: right;
					top: initial;
					transform: initial;
				}
				@media screen and (max-width: 767px){
					font-size: 47px;
					line-height: 47px;
					max-width: 210px;
				}
				@media screen and (max-width: 580px){
					    font-size: 6vw!important;
					line-height: 42px;
					    max-width: 29vw;
				}
				@media screen and (max-width: 420px){
				
					line-height: 37px;
					
				}
			}
		}
	}
	#products{
		max-width: 90.3125vw;
		margin: 0 auto;
		padding: 3.541666666666667vw 0 10vw 0;
		z-index: 999;
		@media screen and (max-width: 767px){
			max-width: 100%;
		}
		#left{
			position: relative;
			float: left;
			width: 19.947916666666668vw;
			min-height: 78.80208333333334vw;
			@media screen and (max-width: 991px){
				width: 25vw;
				min-height: 90vw;
			}
			@media screen and (max-width: 767px){
				width: 100%;
				float: initial;
				min-height: initial;
			}
			#filterProd{
				display: none;
				cursor: pointer;
				padding: 8px 20px;
				font-size: 14px;
				font-family: $r-reg;
				border-top: 1px solid $bleu-f;
				border-bottom: 1px solid $bleu-f;
				margin: 0 0 20px 0;
				@media screen and (max-width: 767px){
					display: block;
				}
			}
			.allSpas{
				position: relative;
				color: $bleu-f;
				font-size: 0.9895833333333334vw;
				font-family: $r-reg;
				line-height: 0.8333333333333334vw;
				letter-spacing: 0.01em;
				padding-left: 1.9270833333333335vw;
				font-weight: 700;
				@media screen and (max-width: 1280px){
					font-size: 12px;
					line-height: 10px;
				}
				@media screen and (max-width: 767px){
					display: none;
					padding-left: 36px;
					font-size: 14px;
					line-height: 12px;
					margin: 0 0 0 20px;
				}
				&:before{
					background: #d7d7d7;
					position: absolute;
					display: block;
					content: "";
					left: 0;
					width: 0.8333333333333334vw;
					height: 0.8333333333333334vw;
					top: 50%;
    				transform: translate(0, -50%);
    				@media screen and (max-width: 767px){
    					width: 16px;
    					height: 16px;
    				}
				}
				&.active{
					&:before{
						background: $bleu-p;
					}
				}
			}
			form{

				@media screen and (max-width: 767px){
					display: none;
					padding: 0 20px;
				}
				.labTitle{
					color: $bleu-f;
					font-size: 1.1979166666666667vw;
					font-family: $s-reg;
					letter-spacing: 0.1em;
					line-height: 1.5625vw;
					margin: 2.0833333333333335vw 0 0.5208333333333334vw 0;
					@media screen and (max-width: 1280px){
						font-size: 15px;
						line-height: 20px;
					}
				}
				input[type=submit]{
					position: relative;
					display: table;
					color: $bleu-p;
					font-size: 1.09375vw;
					font-family: $r-med;
					letter-spacing: 0.01em;
					text-transform: uppercase;
					clear: both;
					transition-property: color;
					transition-duration: 0.3s;
					transition-delay: 0.3s;
					z-index: 1;
					height: 1.6145833333333335vw;
					cursor: pointer;
					padding: 0;
					background: transparent;
					border: none;
					margin: 25px 0 0 0;
					outline: 0;
					transition: 0.4s;
					&:hover{
						background: $bleu-p;
						color: #fff;
					}
					@media screen and (max-width: 1280px){
						font-size: 14px;
						height: 20px;
					}
				}
				ul{
					li{
						& + li{
							margin: 0.6770833333333334vw 0 0 0;
							@media screen and (max-width: 767px){
								margin: 10px 0 0 0;
							}
						}
						label{
							display: block;
							position: relative;
							padding-left: 1.9270833333333335vw;
							margin: 0;
							cursor: pointer;
							font-size: 0.9895833333333334vw;
							font-family: $r-reg;
							line-height: 0.8333333333333334vw;
							user-select: none;
							letter-spacing: 0.01em;
							@media screen and (max-width: 1280px){
								font-size: 12px;
								line-height: 10px;
							}
							@media screen and (max-width: 767px){
								font-size: 14px;
								line-height: 12px;
								padding-left: 36px;
							}
							input{
								position: absolute;
								opacity: 0;
								cursor: pointer;

								&:checked{

									& ~ .checkmark{
										background-color: $bleu-p;
										&:after{
											display: block;
										}
									}
								}
							}
							span{
								position: absolute;
								top: 0;
								left: 0;
								height: 0.8333333333333334vw;
								width: 0.8333333333333334vw;
								background-color: #d7d7d7;
								@media screen and (max-width: 767px){
									width: 16px;
									height: 16px;
								}
								&:after{
									display: none;
								}
							}
						}
					}
				}
			}




			#promoSlide{
				background: $bleu-f;
				position: absolute;
				width: 100%;
				height: auto;
				bottom: 0;
				padding: 4.6875vw 0 4.166666666666667vw;
				@media screen and (max-width: 767px){
					display:none;
					position: relative;
					left: 0 !important;
					width: 100% !important;
					margin: 30px 0 0 0;
					padding: 80px 0;
				}
				#pSlide{
					position: relative;
					width: 100%;
					margin: 0;
					padding: 0 4.6875vw;
					@media screen and (max-width: 767px){
						padding: 0 60px;
					}
					.slick-arrow{
						display: none !important; 
					}
					.slick-dots{
						width: initial;
						left: 50%;
						transform: translate(-50%, 0);
						li{
							width: 0.8333333333333334vw;
							height: 0.8333333333333334vw;
							border: 1px solid $bleu-p;
							border-radius: 0.8333333333333334vw;
							cursor: pointer;
							button{
								&:before{
									display: none;
								}
							}
							@media screen and (max-width: 767px){
								width: 16px;
								height: 16px;
								border-radius: 16px;
							}
							&.slick-active{
								background: $bleu-p;
							}
						}
					}
					.slick-list{

						.slick-track{

							.slick-slide{

								.slideInt{
									text-align: center;
									h3{
										color: #fff;
										font-size: 2.135416666666667vw;
										font-family: $s-reg;
										line-height: 2.135416666666667vw;
										letter-spacing: 0.01em;
										@media screen and (max-width: 767px){
											font-size: 32px;
											line-height: 32px;
										}
									}
									h4{
										color: #fff;
										font-size: 1.6666666666666667vw;
										font-family: $r-reg;
										line-height: 1.7708333333333335vw;
										letter-spacing: 0.01em;
										margin: 0 0 3.6458333333333335vw 0;
										@media screen and (max-width: 767px){
											font-size: 28px;
											line-height: 28px;
											margin: 0 0 40px 0;
										}
										&:before{
											background: #fff;
											display: block;
											content: "";
											margin: 1.3020833333333335vw auto;
											width: 8.333333333333334vw;
											height: 1px;
											@media screen and (max-width: 767px){
												margin: 25px auto;
											}
										}
									}
									span{
										color: #fff;
										font-size: 0.78125vw;
										font-family: $r-italic;
										line-height: 1.7708333333333335vw;
										letter-spacing: 0.01em;
										@media screen and (max-width: 767px){
											font-size: 11px;
											line-height: 11px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		#right{
			float: left;
			width: calc(100% - 19.947916666666668vw);
			padding: 0 0 0 0.78125vw;
							#pSlide2{
					position: relative;
					width: 100%;
					margin: 0;
					padding: 0 4.6875vw;
					@media screen and (max-width: 767px){
						padding: 0 60px;
					}
					.slick-arrow{
						display: none !important; 
					}
					.slick-dots{
						width: initial;
						left: 50%;
						transform: translate(-50%, 0);
						li{
							width: 0.8333333333333334vw;
							height: 0.8333333333333334vw;
							border: 1px solid $bleu-p;
							border-radius: 0.8333333333333334vw;
							cursor: pointer;
							button{
								&:before{
									display: none;
								}
							}
							@media screen and (max-width: 767px){
								width: 16px;
								height: 16px;
								border-radius: 16px;
							}
							&.slick-active{
								background: $bleu-p;
							}
						}
					}
					.slick-list{

						.slick-track{

							.slick-slide{

								.slideInt{
									text-align: center;
									h3{
										color: #fff;
										font-size: 2.135416666666667vw;
										font-family: $s-reg;
										line-height: 2.135416666666667vw;
										letter-spacing: 0.01em;
										@media screen and (max-width: 767px){
											font-size: 32px;
											line-height: 32px;
										}
									}
									h4{
										color: #fff;
										font-size: 1.6666666666666667vw;
										font-family: $r-reg;
										line-height: 1.7708333333333335vw;
										letter-spacing: 0.01em;
										margin: 0 0 3.6458333333333335vw 0;
										@media screen and (max-width: 767px){
											font-size: 28px;
											line-height: 28px;
											margin: 0 0 40px 0;
										}
										&:before{
											background: #fff;
											display: block;
											content: "";
											margin: 1.3020833333333335vw auto;
											width: 8.333333333333334vw;
											height: 1px;
											@media screen and (max-width: 767px){
												margin: 25px auto;
											}
										}
									}
									span{
										color: #fff;
										font-size: 0.78125vw;
										font-family: $r-italic;
										line-height: 1.7708333333333335vw;
										letter-spacing: 0.01em;
										@media screen and (max-width: 767px){
											font-size: 11px;
											line-height: 11px;
										}
									}
								}
							}
						}
					}
				}
			#dropdown{

			}
			@media screen and (max-width: 991px){
				width: calc(100% - 25vw);
			}
			@media screen and (max-width: 767px){
				width: 100%;
				float: initial;
				padding: 0;
			}
			#productsList{
				display: flex;
				flex-wrap: wrap;
				@media screen and (max-width: 767px){
					padding: 60px 0;
				}

				&:after{
					display: table;
					content: "";
					clear: both;
				}
				#loadMore{
					display: block;
					clear: both;
					color: $bleu-p;
					font-size: 1.3020833333333335vw;
					font-family: $r-med;
					line-height: 1.0416666666666667vw;
					display: table;
					margin: 5.208333333333334vw auto 0;
					text-transform: uppercase;
					letter-spacing: 0.01em;
				}




							#promoSlide2{
								@media screen and (min-width: 767px){
									display:none;
									}
									margin-bottom:2vw;

				background: $bleu-f;
				position: absolute;
				width: 100%;
				height: auto;
				bottom: 0;
				padding: 4.6875vw 0 4.166666666666667vw;
				@media screen and (max-width: 767px){
					position: relative;
					left: 0 !important;
					width: 100% !important;
					margin: 30px 0;
					padding: 80px 0;
				}

			}


				.prod-by-prod{
					width: 33.3333333333%;
					margin: 0 0 3.604166666666667vw;
					display: none;
					.prodInt{
						display: block;
						width: 100%;
						max-width: 19.947916666666668vw;
						position: relative;
						.spasImg{
							background-size: contain;
							background-position: center center;
							background-repeat: no-repeat;
							width: 100%;
							height: 19.947916666666668vw;
							@media screen and (max-width: 991px){
								height: 250px;
							}
							@media screen and (max-width: 767px){
								height: 200px;
							}
							@media screen and (max-width: 420px){
								height: 175px;
							}
						}
						.contenu{
							text-align: center;
							margin: 1.0416666666666667vw 0 0 0;
							h5{
								color: $bleu-f;
								font-size: 0.9895833333333334vw;
								font-family: $r-bold;
								letter-spacing: 0.01em;
								@media screen and (max-width: 1280px){
									font-size: 14px;
								}
							}

							span{
								display: block;
								&.year{
									color: $bleu-f;
									font-size: 0.9895833333333334vw;
									font-family: $r-reg;
									letter-spacing: 0.01em;
									@media screen and (max-width: 1280px){
										font-size: 14px;
									}
								}
								&.price{
									color: $bleu-p;
									font-size: 0.9895833333333334vw;
									font-family: $r-bold;
									letter-spacing: 0.01em;
									@media screen and (max-width: 1280px){
										font-size: 14px;
									}
								}
							}
						}
					}
					@media screen and (max-width: 991px){
						width: 50%;
						.prodInt{
							float: initial !important;
							margin: 0 auto !important;
							max-width: 90%;
						}
						
					}
					@media screen and (max-width: 580px){
						width: 100%;
						.prodInt{
							float: initial !important;
							margin: 0 auto !important;
							max-width: 90%;
						}
						
					}
				}
			}
		}
	}
}

#garantieOldbuy{
	background: $bleu-t;
	position: relative;
	padding: 0 0 11.458333333333334vw 0;
	.goutte-bleu{
		position: absolute;
		top: calc(-15.46875vw - 19.53125vw);
		left: 0;
		z-index: -1;
	}
	&:before{
		background-image: url(../images/montre-home.png);
		background-size: 100% auto;
		background-position: center top;
		background-repeat: no-repeat;
		display: table;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 26.3023vw;
		z-index: 1;
		margin: -10vw 0 0 0;
		float: left;
	}
	#garantie{
		position: relative;
		max-width: 57.03125vw;
		margin: 0 auto 10.78125vw;
		clear: both;
		@media screen and (max-width: 580px){
			max-width: 100%;
			padding: 0 20px;
		}
		h1{
			position: absolute;
			width: 100%;
			max-width: 34.25vw;
			right: 0;
			color: $bleu-p;
			font-size: 6.770833333333334vw;
			font-family: $r-black;
			line-height: 6.770833333333334vw;
			top: 0;
			letter-spacing: 0.01em;
			text-align: right;
			text-transform: uppercase;
			@media screen and (max-width: 991px){
				opacity: .25;
				font-size: 86px;
				line-height: 86px;
				max-width: 400px;
				width: 34vw;
				top: -2vw;
				right: -8vw;
				text-align: right;
				font-size: 7vw!important;
				opacity: 0.25;


				line-height: 86px;
				max-width: 400px;
			}
			@media screen and (max-width: 580px){
				font-size: 56px;
				line-height: 56px;
				max-width: 325px;
				right: 20px;
			}
		}
		p{
			padding: 7.291666666666667vw 0 0 0;
			max-width: 34.28125vw;
			color: #fff;
			font-size: 1.3541666666666667vw;
			font-family: $s-reg;
			line-height: 2.03125vw;
			letter-spacing: 0.01em;
			text-align: right;
			@media screen and (max-width: 991px){
				max-width: 75%;
				font-size: 17px;
				line-height: 26px;
			}
			@media screen and (max-width: 580px){
// 				max-width: 100%;
			}
		}
	}
	.corpsSide{
		width: 100%;
		max-width: calc((100% - 8.958333333333334vw) + 2.447916666666667vw);
		float: right;
		.circle-d{
				position: absolute;
				right: 10.9375vw;
				top: calc(-5.5vw);
				transform: rotate(90deg);
			}
		#oldBuy{
			background: #fff;
			position: relative;
			width: 100%;
			height: 38.020833333333336vw;
			
			@media screen and (max-width: 1280px){
				height: auto;
				padding: 80px;
			}
			.contenu{
				position: absolute;
				top: 50%;
				left: 4.166666666666667vw;
				width: 36.51041666666667vw;
				transform: translate(0, -50%);
				@media screen and (max-width: 1280px){
					position: relative;
					top: initial;
					left: initial;
					transform: initial;
					width: 60%;
				}
				@media screen and (max-width: 991px){
					width: 80%;
				}
				@media screen and (max-width: 850px){
					width: 100%;
				}
				h2{
					color: $bleu-f;
					font-size: 4.0625vw;
					font-family: $s-reg;
					line-height: 4.0625vw;
					max-width: 26.5625vw;
					letter-spacing: 0.01em;
					@media screen and (max-width: 1280px){
						font-size: 52px;
						line-height: 52px;
						max-width: 70%;
					}
					@media screen and (max-width: 850px){
						max-width: 80%;
					}
					@media screen and (max-width: 767px){
						max-width: 90%;
					}
					@media screen and (max-width: 580px){
						max-width: 100%;
					}
				}
				p{
					color: $bleu-f;
					font-size: 0.8854166666666667vw;
					font-family: $r-reg;
					line-height: 1.3541666666666667vw;
					letter-spacing: 0.01em;
					margin: 3.125vw 0 3.6458333333333335vw 0;
					@media screen and (max-width: 1280px){
						font-size: 13px;
						line-height: 20px;
					}
					@media screen and (max-width: 580px){
						margin: 30px 0;
					}
				}
				span{
					position: relative;
					display: table;
					color: $bleu-p;
					font-size: 1.09375vw;
					font-family: $r-med;
					letter-spacing: 0.01em;
					text-transform: uppercase;
					clear: both;
					transition-property: color;
					transition-duration: 0.3s;
					transition-delay: 0.3s;
					z-index: 1;
					height: 1.6145833333333335vw;
					cursor: pointer;
					@media screen and (max-width: 1280px){
						font-size: 14px;
						height: 20px;
					}
					&:before{
						background: $bleu-p;
						position: absolute;
						display: table;
						content: "";
						bottom: -0.625vw;
						width: 0px;
						height: 0px;
						transition-property: width, height;
						transition-duration: 0.3s, 0.3s;
						transition-delay: 0.3s, 0.3s;
						z-index: -1;
						left: 50%;
						transform: translate(-50%, 0);
					}
					&:after{
						background: $bleu-p;
						position: absolute;
						display: table;
						content: "";
						bottom: -0.625vw;
						width: 100%;
						height: 4px;
						transition-property: width;
						transition-duration: 0.3s;
						@media screen and (min-width: 1920px){
							height: 0.20833333333333334vw;
						}
					}
					&:hover{
						color: #fff;
						&:before{
							width: calc(100% + 1.5625vw);
							height: calc(100% + (0.625vw * 2));
						}
						&:after{
							width: 0;
						}
					}
				}
			}
			#oldimg{
				background-image: url(../images/oldspas.png);
				background-size: auto 100%;
				background-position: left center;
				background-repeat: no-repeat;
				position: absolute;
				width: 46.51041666666667vw;
				height: 37.76041666666667vw;
				right: 0;
				bottom: -7.291666666666667vw;
				@media screen and (max-width: 1280px){
					right: -100px;
				}
				@media screen and (max-width: 991px){
					right: -300px;
					bottom: 50%;
					transform: translate(0, 50%);
				}
				@media screen and (max-width: 850px){
					display: none;
				}
			}
		}
	}
}