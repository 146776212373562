@charset "UTF-8";
/* =======================================================================
Core
========================================================================== */
/* =======================================================================
Responsive
========================================================================== */
/* =======================================================================
Mixin center
========================================================================== */
/* =======================================================================
Font-face
========================================================================== */
@font-face {
  font-family: 'robotoBold';
  src: url(../fonts/roboto/Roboto-Bold.ttf);
}

@font-face {
  font-family: 'robotoBlack';
  src: url(../fonts/roboto/Roboto-Black.ttf);
}

@font-face {
  font-family: 'robotoReg';
  src: url(../fonts/roboto/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'robotoItalic';
  src: url(../fonts/roboto/Roboto-Italic.ttf);
}

@font-face {
  font-family: 'robotoMed';
  src: url(../fonts/roboto/Roboto-Medium.ttf);
}

@font-face {
  font-family: 'sabloReg';
  src: url(../fonts/sablo/sablo-reg.ttf);
}

/* =======================================================================
Variables
========================================================================== */
/* =======================================================================
Global
========================================================================== */
@keyframes imgmove {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-2.2083333333vw, 2.2083333333vw);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes imgmoveS {
  0% {
    transform: translate(0, -50%);
  }
  25% {
    transform: translate(5px, -45%);
  }
  50% {
    transform: translate(0, -50%);
  }
  75% {
    transform: translate(-5px, -45%);
  }
  100% {
    transform: translate(0, -50%);
  }
}

/* line 36, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
html,
body {
  margin: 0;
  padding: 0;
}

/* line 43, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
body {
  overflow-x: hidden !important;
}

/* line 48, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

/* line 57, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
p {
  margin: 0;
}

/* line 61, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
* {
  outline: 0 !important;
}

/* line 65, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* line 74, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
a {
  text-decoration: none !important;
}

/* line 78, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
section {
  position: relative;
  z-index: 1;
  clear: both;
}

/* line 84, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.clear {
  clear: both;
}

/* line 88, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.corps {
  position: relative;
  display: block;
  max-width: 1170px;
  margin: 0 auto;
}

/* line 94, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.corpsSide {
  position: relative;
  display: block;
  max-width: calc(100% - 8.958333333333334vw);
  margin: 0;
}

/* line 103, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.effect {
  transition-property: transform;
  transition-duration: 0.4s;
}

/* line 104, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.effect.x {
  transform: translateX(1000%);
}

/* line 107, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.effect.xInverse {
  transform: translateX(-1000%);
}

/* line 112, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.effect.isVisible {
  transform: translateX(0);
}

/* line 118, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#loader {
  background: #002131;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition-property: opacity;
  transition-duration: 0.4s;
  z-index: 20;
}

/* line 129, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#loader img {
  display: block;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 991px) {
  /* line 129, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #loader img {
    height: 150px;
  }
}

@media screen and (max-width: 767px) {
  /* line 129, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #loader img {
    height: 100px;
  }
}

@media screen and (max-width: 580px) {
  /* line 129, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #loader img {
    height: 90px;
  }
}

/* line 157, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld {
  background: rgba(105, 229, 244, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 9999;
}

/* line 166, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu {
  background: #002131;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  height: auto;
  padding: 100px 0;
  transform: translate(-50%, -50%);
}

@media screen and (max-height: 900px) {
  /* line 166, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #popOld #contenu {
    top: 0;
    transform: translate(-50%, 0);
  }
}

/* line 179, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu #forClose {
  position: absolute;
  width: 25px;
  height: 20px;
  top: 34px;
  right: 39px;
  cursor: pointer;
}

/* line 186, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu #forClose:before {
  background: #fff;
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%) rotate(-45deg);
}

/* line 197, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu #forClose:after {
  background: #fff;
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%) rotate(45deg);
}

/* line 209, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper {
  margin: 0;
  max-width: calc(100% - 200px);
  margin: 0 auto;
}

/* line 214, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .validation_error {
  color: #ff0000;
  letter-spacing: 0.01em;
  margin: 0 0 20px 0;
  padding: 0 10px;
}

/* line 222, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_body ul li {
  float: left;
  width: 50%;
  clear: initial;
  padding: 0 10px;
  margin: 0 0 30px;
}

/* line 228, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_body ul li label {
  color: #69e5f4;
  font-size: 26px;
  font-family: "sabloReg";
  line-height: 21px;
  letter-spacing: 0.01em;
  margin: 0;
}

/* line 235, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_body ul li label .gfield_required {
  display: none;
}

/* line 240, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_body ul li .ginput_container input,
#popOld #contenu .gform_wrapper form .gform_body ul li .ginput_container select {
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  width: 100%;
  padding: 15px 0;
  outline: 0;
  color: #fff;
  font-size: 17px;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
}

/* line 252, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_body ul li .ginput_container input::-webkit-input-placeholder,
#popOld #contenu .gform_wrapper form .gform_body ul li .ginput_container select::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

/* line 255, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_body ul li .ginput_container input::-moz-placeholder,
#popOld #contenu .gform_wrapper form .gform_body ul li .ginput_container select::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

/* line 258, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_body ul li .ginput_container input:-ms-input-placeholder,
#popOld #contenu .gform_wrapper form .gform_body ul li .ginput_container select:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

/* line 261, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_body ul li .ginput_container input:-moz-placeholder,
#popOld #contenu .gform_wrapper form .gform_body ul li .ginput_container select:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

/* line 266, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_body ul li .ginput_container select option {
  background-color: #002131;
  color: #fff;
}

/* line 272, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_body ul li .validation_message {
  display: none;
}

/* line 275, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_body ul li#field_1_10, #popOld #contenu .gform_wrapper form .gform_body ul li#field_1_11 {
  clear: both;
  width: 100%;
}

/* line 282, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_body ul li.gfield_error .ginput_container input,
#popOld #contenu .gform_wrapper form .gform_body ul li.gfield_error .ginput_container select {
  border-color: #ff0000 !important;
}

/* line 289, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_body ul:after {
  display: table;
  content: "";
  clear: both;
}

/* line 296, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_footer {
  position: relative;
  margin: 20px 0 0 0;
  display: table;
  float: right;
  clear: both;
  width: auto;
}

/* line 303, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_footer input {
  background: transparent;
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  clear: both;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
  border: none;
  outline: 0;
  margin: 0;
}

@media screen and (max-width: 1280px) {
  /* line 303, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #popOld #contenu .gform_wrapper form .gform_footer input {
    font-size: 14px;
    height: 20px;
  }
}

@media screen and (max-width: 991px) {
  /* line 303, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #popOld #contenu .gform_wrapper form .gform_footer input {
    float: right;
  }
}

/* line 329, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_footer:before {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -4vw;
  width: 0px;
  height: 0px;
  transition-property: width, height;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0.3s;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}

/* line 344, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_footer:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.425vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 344, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #popOld #contenu .gform_wrapper form .gform_footer:after {
    height: 0.20833333333333334vw;
  }
}

/* line 358, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_footer:hover {
  color: #fff;
}

/* line 360, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_footer:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

/* line 364, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popOld #contenu .gform_wrapper form .gform_footer:hover:after {
  width: 0;
}

/* line 375, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve {
  background: rgba(105, 229, 244, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  z-index: 9999;
}

/* line 384, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu {
  background: #002131;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70%;
  height: auto;
  padding: 100px 0;
  transform: translate(-50%, -50%);
}

@media screen and (max-height: 900px) {
  /* line 384, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #popReserve #contenu {
    top: 0;
    transform: translate(-50%, 0);
  }
}

/* line 397, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu #forClosea {
  position: absolute;
  width: 25px;
  height: 20px;
  top: 34px;
  right: 39px;
  cursor: pointer;
}

/* line 404, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu #forClosea:before {
  background: #fff;
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%) rotate(-45deg);
}

/* line 415, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu #forClosea:after {
  background: #fff;
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 1px;
  top: 50%;
  left: 50%;
  transform: translate(0, -50%) rotate(45deg);
}

/* line 427, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper {
  margin: 0;
  max-width: calc(100% - 200px);
  margin: 0 auto;
}

/* line 432, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .validation_error {
  color: #ff0000;
  letter-spacing: 0.01em;
  margin: 0 0 20px 0;
  padding: 0 10px;
}

/* line 440, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li {
  float: left;
  width: 50%;
  clear: initial;
  padding: 0 10px;
  margin: 0 0 30px;
}

/* line 446, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li#field_2_7 {
  width: 100%;
  clear: both;
}

/* line 450, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li#field_2_1 {
  padding: 0 0 0 10px;
}

/* line 453, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li#field_2_2 {
  padding: 0 10px;
}

/* line 456, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li label {
  color: #69e5f4;
  font-size: 26px;
  font-family: "sabloReg";
  line-height: 21px;
  letter-spacing: 0.01em;
  margin: 0;
}

/* line 463, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li label .gfield_required {
  display: none;
}

/* line 468, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li .ginput_container input,
#popReserve #contenu .gform_wrapper form .gform_body ul li .ginput_container textarea {
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  width: 100%;
  outline: 0;
  color: #fff;
  font-size: 17px;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
  overflow: hidden;
  padding: 15px 15px 15px 0;
}

/* line 481, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li .ginput_container input::-webkit-input-placeholder,
#popReserve #contenu .gform_wrapper form .gform_body ul li .ginput_container textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

/* line 484, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li .ginput_container input::-moz-placeholder,
#popReserve #contenu .gform_wrapper form .gform_body ul li .ginput_container textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

/* line 487, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li .ginput_container input:-ms-input-placeholder,
#popReserve #contenu .gform_wrapper form .gform_body ul li .ginput_container textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

/* line 490, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li .ginput_container input:-moz-placeholder,
#popReserve #contenu .gform_wrapper form .gform_body ul li .ginput_container textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

/* line 495, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li .ginput_container select option {
  color: #000;
}

/* line 503, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li .validation_message {
  display: none;
}

/* line 506, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li#field_1_10, #popReserve #contenu .gform_wrapper form .gform_body ul li#field_1_11 {
  clear: both;
  width: 100%;
}

/* line 513, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul li.gfield_error .ginput_container input,
#popReserve #contenu .gform_wrapper form .gform_body ul li.gfield_error .ginput_container select,
#popReserve #contenu .gform_wrapper form .gform_body ul li.gfield_error .ginput_container textarea {
  border-color: #ff0000 !important;
}

/* line 521, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_body ul:after {
  display: table;
  content: "";
  clear: both;
}

/* line 528, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_footer {
  position: relative;
  margin: 20px 0 0 0;
  display: table;
  float: right;
  clear: both;
  width: auto;
}

/* line 535, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_footer input {
  background: transparent;
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  clear: both;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
  border: none;
  outline: 0;
  margin: 0;
}

@media screen and (max-width: 1280px) {
  /* line 535, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #popReserve #contenu .gform_wrapper form .gform_footer input {
    font-size: 14px;
    height: 20px;
  }
}

@media screen and (max-width: 991px) {
  /* line 535, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #popReserve #contenu .gform_wrapper form .gform_footer input {
    float: right;
  }
}

/* line 561, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_footer:before {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -4vw;
  width: 0px;
  height: 0px;
  transition-property: width, height;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0.3s;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}

/* line 576, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_footer:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.425vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 576, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #popReserve #contenu .gform_wrapper form .gform_footer:after {
    height: 0.20833333333333334vw;
  }
}

/* line 590, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_footer:hover {
  color: #fff;
}

/* line 592, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_footer:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

/* line 596, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#popReserve #contenu .gform_wrapper form .gform_footer:hover:after {
  width: 0;
}

/* line 608, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#singCallAction {
  background: #3990ae;
  padding: 8.333333333333334vw 6.510416666666667vw 22.708333333333336vw;
}

/* line 611, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#singCallAction .vague {
  position: absolute;
  right: 0;
  bottom: 10.208333333333334vw;
}

/* line 616, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#singCallAction .partAction {
  float: left;
  width: 50%;
}

@media screen and (max-width: 767px) {
  /* line 616, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #singCallAction .partAction {
    width: 100%;
    float: initial;
  }
  /* line 622, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #singCallAction .partAction + .partAction {
    margin: 40px 0 0 0;
  }
}

/* line 626, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#singCallAction .partAction .partInt {
  max-width: 31.25vw;
  margin: 0 auto;
  text-align: center;
}

@media screen and (max-width: 767px) {
  /* line 626, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #singCallAction .partAction .partInt {
    max-width: 80%;
  }
}

/* line 633, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#singCallAction .partAction .partInt h3 {
  color: #fff;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  letter-spacing: 0.01em;
  line-height: 4.0625vw;
}

@media screen and (max-width: 767px) {
  /* line 633, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #singCallAction .partAction .partInt h3 {
    font-size: 31px;
    line-height: 31px;
  }
}

/* line 644, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#singCallAction .partAction .partInt a,
#singCallAction .partAction .partInt span {
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  clear: both;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
  cursor: pointer;
  margin: 2.0833333333333335vw auto 0;
}

@media screen and (max-width: 1280px) {
  /* line 644, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #singCallAction .partAction .partInt a,
  #singCallAction .partAction .partInt span {
    font-size: 14px;
    height: 20px;
  }
}

/* line 665, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#singCallAction .partAction .partInt a:before,
#singCallAction .partAction .partInt span:before {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 0px;
  height: 0px;
  transition-property: width, height;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0.3s;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}

/* line 680, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#singCallAction .partAction .partInt a:after,
#singCallAction .partAction .partInt span:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 680, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #singCallAction .partAction .partInt a:after,
  #singCallAction .partAction .partInt span:after {
    height: 0.20833333333333334vw;
  }
}

/* line 694, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#singCallAction .partAction .partInt a:hover,
#singCallAction .partAction .partInt span:hover {
  color: #fff;
}

/* line 696, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#singCallAction .partAction .partInt a:hover:before,
#singCallAction .partAction .partInt span:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

/* line 700, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#singCallAction .partAction .partInt a:hover:after,
#singCallAction .partAction .partInt span:hover:after {
  width: 0;
}

/* line 715, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar {
  background: #3990ae;
  position: relative;
  padding: 0 0 11.979166666666668vw 0;
}

/* line 719, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar .goutte {
  position: absolute;
  left: 0;
  bottom: 30.625vw;
}

/* line 724, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #title {
  background: #002131;
  position: absolute;
  padding: 6.770833333333334vw 8.333333333333334vw;
  width: 100%;
  max-width: 53.75vw;
  top: -14.010416666666668vw;
  z-index: 1;
}

@media screen and (max-width: 767px) {
  /* line 724, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #title {
    max-width: 70%;
  }
}

/* line 735, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #title h1 {
  color: #fff;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  letter-spacing: 0.01em;
  line-height: 4.0625vw;
  text-align: right;
}

@media screen and (max-width: 767px) {
  /* line 735, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #title h1 {
    font-size: 31px;
    line-height: 31px;
  }
}

/* line 750, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #similarList .corpsSide {
  background: #fff;
  float: right;
  width: 100%;
  max-width: calc(100% - 13.177083333333334vw);
  padding: 7.65625vw 0;
}

@media screen and (max-width: 767px) {
  /* line 750, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #similarList .corpsSide {
    padding: 80px 0;
  }
}

/* line 759, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #similarList .corpsSide .corpInt {
  width: 100%;
  max-width: 69.58333333333334vw;
  margin: 0 auto;
}

/* line 764, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #similarList .corpsSide .corpInt .sim-by-sim {
  float: left;
  width: 33.3333333333%;
  margin: 0 0 2.0833333333333335vw 0;
}

/* line 768, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #similarList .corpsSide .corpInt .sim-by-sim .simInt {
  display: block;
  width: 100%;
  max-width: 19.947916666666668vw;
  position: relative;
}

@media screen and (max-width: 991px) {
  /* line 768, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #similarList .corpsSide .corpInt .sim-by-sim .simInt {
    max-width: 90%;
    margin: 0 auto;
  }
}

/* line 780, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #similarList .corpsSide .corpInt .sim-by-sim .simInt .spasImg {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 19.947916666666668vw;
}

@media screen and (max-width: 991px) {
  /* line 780, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #similarList .corpsSide .corpInt .sim-by-sim .simInt .spasImg {
    height: 250px;
  }
}

@media screen and (max-width: 767px) {
  /* line 780, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #similarList .corpsSide .corpInt .sim-by-sim .simInt .spasImg {
    height: 200px;
  }
}

@media screen and (max-width: 420px) {
  /* line 780, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #similarList .corpsSide .corpInt .sim-by-sim .simInt .spasImg {
    height: 175px;
  }
}

/* line 796, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #similarList .corpsSide .corpInt .sim-by-sim .simInt .contenu {
  text-align: center;
  margin: 1.0416666666666667vw 0 0 0;
}

/* line 799, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #similarList .corpsSide .corpInt .sim-by-sim .simInt .contenu h5 {
  color: #002131;
  font-size: 0.9895833333333334vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 799, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #similarList .corpsSide .corpInt .sim-by-sim .simInt .contenu h5 {
    font-size: 14px;
  }
}

/* line 809, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #similarList .corpsSide .corpInt .sim-by-sim .simInt .contenu span {
  display: block;
}

/* line 811, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #similarList .corpsSide .corpInt .sim-by-sim .simInt .contenu span.year {
  color: #002131;
  font-size: 0.9895833333333334vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 811, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #similarList .corpsSide .corpInt .sim-by-sim .simInt .contenu span.year {
    font-size: 14px;
  }
}

/* line 820, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #similarList .corpsSide .corpInt .sim-by-sim .simInt .contenu span.price {
  color: #69e5f4;
  font-size: 0.9895833333333334vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 820, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #similarList .corpsSide .corpInt .sim-by-sim .simInt .contenu span.price {
    font-size: 14px;
  }
}

/* line 838, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #similarList .corpsSide .corpInt .sim-by-sim:nth-child(2) .prodInt {
  margin: 0 auto;
}

/* line 843, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#similar #similarList .corpsSide .corpInt .sim-by-sim:nth-child(3) .prodInt {
  float: right;
}

@media screen and (max-width: 991px) {
  /* line 764, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #similarList .corpsSide .corpInt .sim-by-sim {
    width: 50%;
  }
  /* line 849, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #similarList .corpsSide .corpInt .sim-by-sim .prodInt {
    float: initial !important;
    margin: 0 auto !important;
    max-width: 90%;
  }
}

@media screen and (max-width: 580px) {
  /* line 764, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #similarList .corpsSide .corpInt .sim-by-sim {
    width: 100%;
  }
  /* line 858, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #similar #similarList .corpsSide .corpInt .sim-by-sim .prodInt {
    float: initial !important;
    margin: 0 auto !important;
    max-width: 90%;
  }
}

/* line 871, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
textarea {
  overflow: hidden !important;
}

/* line 876, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile {
  top: 9vw;
  height: 100vh;
  background: #002131;
  position: fixed;
  width: 100%;
  display: none;
  z-index: 999999999999;
  overflow-y: scroll;
}

/* line 885, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile::-webkit-scrollbar {
  width: 2px;
}

/* line 890, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

/* line 894, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile::-webkit-scrollbar-thumb {
  background-color: #69e5f4;
  outline: 0;
}

@media screen and (max-width: 767px) {
  /* line 876, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #navPanelMobile {
    top: 15vw;
  }
}

/* line 902, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile ul {
  border-bottom: 2px solid #fff;
}

/* line 905, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile ul li.current_page_item {
  background: #69e5f4;
}

/* line 908, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile ul li a {
  display: block;
  color: #fff;
  font-size: 18px;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  padding: 15px 25px;
}

/* line 919, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile #adresseForNav {
  margin: 40px 0 0 0;
}

/* line 921, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile #adresseForNav a {
  display: block;
  color: #fff;
}

/* line 929, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile #adresseForNav span {
  display: block;
  color: #fff;
  padding: 0 25px;
  text-align: center;
  width: 100%;
  font-size: 18px;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
}

/* line 941, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile #social {
  display: table;
  margin: 0px auto 0;
}

/* line 944, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile #social ul {
  border: none !important;
}

/* line 946, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile #social ul li {
  float: left;
}

/* line 948, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile #social ul li + li {
  margin: 0 0 0 0;
}

/* line 951, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile #social ul li a {
  font-size: 60px;
  color: #fff;
}

/* line 956, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#navPanelMobile #social ul:after {
  display: table;
  content: "";
  clear: both;
}

/* line 967, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.vague {
  background-image: url(../images/vague.svg);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 15.104166666666668vw;
  height: 9.479166666666668vw;
  animation-name: imgmove;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

/* line 979, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.vague-bleu {
  background-image: url(../images/vague-bleu.svg);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 15.104166666666668vw;
  height: 9.479166666666668vw;
  animation-name: imgmove;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

/* line 992, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.goutte {
  background-image: url(../images/goutte.png);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 11.614583333333334vw;
  height: 15.46875vw;
  animation-name: imgmove;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

/* line 1004, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.goutte-w {
  background-image: url(../images/goutte-blanche.png);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 11.614583333333334vw;
  height: 15.46875vw;
  animation-name: imgmove;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

/* line 1016, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.goutte-bleu {
  background-image: url(../images/goutte-bleu.png);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 11.614583333333334vw;
  height: 15.46875vw;
  animation-name: imgmove;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

/* line 1028, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.vague-rond-bleu {
  background-image: url(../images/vague-rond-bleu.svg);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 15.416666666666668vw;
  height: 6.614583333333334vw;
  animation-name: imgmove;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

/* line 1040, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.vague-rond {
  background-image: url(../images/vague-rond.svg);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 15.15625vw;
  height: 6.510416666666667vw;
  animation-name: imgmove;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

/* line 1052, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.circle-d {
  background-image: url(../images/circle.svg);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 14.114583333333334vw;
  height: 1.25vw;
  animation-name: imgmove;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

/* line 1064, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.circle-d-f {
  background-image: url(../images/circle-f.svg);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 14.114583333333334vw;
  height: 1.25vw;
  animation-name: imgmove;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

/* line 1078, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#breads {
  margin: 3.385416666666667vw 0 0 9vw;
  font-size: .885vw;
}

@media all and (max-width: 992px) {
  /* line 1078, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #breads {
    font-size: 15px !important;
  }
}

/* line 1088, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#warn h1 {
  font-size: 20px !important;
}

/* line 1095, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.gform_confirmation_message {
  color: #fff;
  font-size: 30px;
  font-family: "sabloReg";
  padding: 0 60px;
}

@media screen and (max-width: 580px) {
  /* line 1095, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  .gform_confirmation_message {
    padding: 0 40px;
  }
}

@media screen and (max-width: 420px) {
  /* line 1095, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  .gform_confirmation_message {
    padding: 0 20px;
  }
}

/* line 1115, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.prodInt .spasImg,
.sim-by-sim .spasImg,
.spa-by-spa .spasImg {
  position: relative;
}

/* line 1117, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.prodInt .spasImg:after,
.sim-by-sim .spasImg:after,
.spa-by-spa .spasImg:after {
  background: #69e5f4;
  position: absolute;
  display: block;
  content: "";
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.4s;
}

/* line 1132, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.prodInt:hover .spasImg:after,
.sim-by-sim:hover .spasImg:after,
.spa-by-spa:hover .spasImg:after {
  opacity: 0.5;
}

/* line 1139, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.new {
  z-index: 9;
}

/* line 1141, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.new span {
  font-family: "sabloReg";
}

/* line 1148, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#quatrecentquatre {
  padding: 150px 60px;
}

@media screen and (max-width: 580px) {
  /* line 1148, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #quatrecentquatre {
    padding: 100px 40px;
  }
}

/* line 1153, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#quatrecentquatre h1 {
  text-align: center;
  color: #000;
  font-size: 40px;
  font-family: "sabloReg";
  letter-spacing: 0.01em;
  line-height: 42px;
}

/*------------------------------------*\
            MY CSS
        \*------------------------------------*/
/* line 1166, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
#promotions .slick-arrow, #promoMobile .slick-arrow {
  display: none !important;
}

/* line 1171, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.slick-next:before {
  content: '>';
  font-size: 16vw;
  font-family: monospace;
  color: #6dd6e7;
  line-height: 0;
  position: relative;
  right: 5vw;
  opacity: 0.8;
}

/* line 1182, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.slick-prev:before {
  content: '<';
  font-size: 16vw;
  font-family: monospace;
  color: #6dd6e7;
  line-height: 0;
  position: relative;
  opacity: 0.8;
  left: 2vw;
}

/*------------------------------------*\
    ONGLETS
\*------------------------------------*/
/* line 1197, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.tab.active {
  z-index: -1;
  color: black !important;
}

/* line 1202, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.tab {
  z-index: 9;
  width: 20vw !important;
  cursor: pointer;
}

/* line 1208, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.tab_content.open {
  opacity: 1;
  z-index: 5;
  transition: .3s;
}

/* line 1214, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.tab_content .tab_title {
  /*transform: scale(0.8);*/
  opacity: 0;
  transition: .2s ease-out;
}

/* line 1220, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.tab_content .tab_text {
  transform: scale(1);
  opacity: 0;
  z-index: -1;
  /*transition: .2s ease-in-out;*/
}

/* line 1227, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.tab_content.open .tab_title,
.tab_content.open .tab_text {
  transform: scale(1);
  opacity: 1;
  z-index: 9;
  transition: .4s ease-in-out;
}

/*------------------------------------*\
    DROPDOWN
\*------------------------------------*/
/* line 1240, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.dropdown {
  display: none !important;
  top: 0vw;
  display: flex;
  position: absolute;
}

@media all and (max-width: 767px) {
  /* line 1240, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  .dropdown {
    display: none;
  }
}

/* line 1248, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.dropdown h4 {
  margin-right: 1vw;
  font-size: 1.5vw;
}

@media screen and (max-width: 580px) {
  /* line 1248, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  .dropdown h4 {
    font-size: 3vw;
  }
}

/* line 1255, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.dropdown option {
  font-size: 1.5vw;
}

/* line 1258, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.dropdown select {
  font-size: 1.5vw;
}

@media screen and (max-width: 580px) {
  /* line 1258, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  .dropdown select {
    font-size: 3vw;
  }
}

/*------------------------------------*\
    ARROW
\*------------------------------------*/
@media all and (max-width: 767px) {
  /* line 1272, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  .arrow {
    transform: none;
    transition: all .2s;
    position: absolute;
    z-index: 8;
    right: 2.2vw;
    top: 1.8vw;
    border-left: 2vw solid transparent;
    border-right: 2vw solid transparent;
    border-top: 2vw solid #a2a2a2;
    pointer-events: none;
  }
}

/* line 1287, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
.arrow_container.open .arrow {
  transform: rotate(180deg);
}

/*------------------------------------*\
    EDGE
\*------------------------------------*/
@supports (-ms-ime-align: auto) {
  /* line 1297, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #intro .partIntro#right video {
    height: auto !important;
    width: auto !important;
  }
  /* line 1302, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_base.scss */
  #montre #listNewSpas #right {
    top: 15% !important;
  }
}

/* line 4, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
ul li a br {
  display: none !important;
}

/* line 11, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
#supportHead {
  display: none;
  width: 100%;
  height: 6.36vw;
  transition-property: height;
  transition-duration: 0.4s;
}

@media screen and (min-width: 1920px) {
  /* line 11, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  #supportHead {
    height: 7.291666666666667vw;
  }
}

@media screen and (max-width: 991px) {
  /* line 11, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  #supportHead {
    height: 9vw;
    display: block;
  }
}

@media screen and (max-width: 767px) {
  /* line 11, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  #supportHead {
    height: 15vw;
  }
}

@media screen and (max-width: 420px) {
  /* line 11, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  #supportHead {
    height: 50px;
  }
}

/* line 34, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
#supportHead.head-is-Scroll {
  display: block;
  height: 100px;
}

@media screen and (min-width: 1920px) {
  /* line 34, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  #supportHead.head-is-Scroll {
    height: 5.208333333333334vw;
  }
}

@media screen and (max-width: 767px) {
  /* line 34, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  #supportHead.head-is-Scroll {
    height: 80px;
  }
}

@media screen and (max-width: 580px) {
  /* line 34, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  #supportHead.head-is-Scroll {
    height: 65px;
  }
}

@media screen and (max-width: 420px) {
  /* line 34, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  #supportHead.head-is-Scroll {
    height: 50px;
  }
}

/* line 50, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
#supportHead.notFront {
  display: block !important;
}

/* line 54, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header {
  position: relative;
  background: #002131;
  display: block;
  width: 100%;
  height: 7.3vw;
  z-index: 999999 !important;
  transition-property: height;
  transition-duration: 0.4s;
}

/* line 63, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header.notFront {
  position: fixed;
}

@media screen and (min-width: 1920px) {
  /* line 54, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header {
    height: 7.291666666666667vw;
  }
}

@media screen and (max-width: 991px) {
  /* line 54, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header {
    height: 9vw;
    position: fixed;
  }
}

@media screen and (max-width: 767px) {
  /* line 54, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header {
    height: 15vw;
    position: fixed;
  }
}

/* line 79, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead {
  max-width: 90.365vw;
  margin: 0 auto;
  height: 100%;
  display: flex;
}

/* line 84, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead #logo {
  position: relative;
  float: left;
  height: 100%;
  width: 30vw;
}

/* line 89, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead #logo a {
  position: absolute;
  display: table;
  width: 6.72vw !important;
  height: calc(100% - 44px);
  top: 50%;
  transform: translate(0, -50%);
}

@media screen and (min-width: 1920px) {
  /* line 89, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead #logo a {
    width: 7.760416666666667vw !important;
  }
}

@media screen and (max-width: 991px) {
  /* line 89, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead #logo a {
    width: 129px !important;
  }
}

@media screen and (max-width: 767px) {
  /* line 89, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead #logo a {
    width: 100px !important;
  }
}

@media screen and (max-width: 580px) {
  /* line 89, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead #logo a {
    width: 80px !important;
  }
}

@media screen and (max-width: 420px) {
  /* line 89, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead #logo a {
    width: 70px !important;
  }
}

/* line 111, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead #logo a img {
  display: block;
  width: 100%;
  height: 5.22vw;
}

@media screen and (max-width: 767px) {
  /* line 111, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead #logo a img {
    width: 16vw !important;
    height: 12vw;
  }
}

/* line 122, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav {
  width: 68vw;
  position: relative;
  float: left;
  height: 100%;
}

/* line 127, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt {
  display: flex;
  width: 100%;
  height: 100%;
}

/* line 131, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul {
  display: table-cell;
}

/* line 133, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShort {
  display: none;
  padding: 0 15px 0 0;
}

@media screen and (min-width: 1920px) {
  /* line 133, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#headMenuShort {
    padding: 0 0.78125vw 0 0;
  }
}

@media screen and (max-width: 1580px) {
  /* line 133, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#headMenuShort {
    display: block;
  }
}

@media screen and (max-width: 1235px) {
  /* line 133, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#headMenuShort {
    display: none;
  }
}

/* line 145, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShort li {
  position: relative;
  padding: 0 11px !important;
  float: right;
  height: 100%;
  line-height: 6.8vw;
  /*&:after{
								background: $bleu-p;
								position: absolute;
								display: block;
								content: "";
								width: 0px;
								height: 30px;
								left: 0;
								bottom: 0;
								opacity: 0;
								transform: translateY(0);
								transition-property: width, height, opacity, transform;
								transition-duration: 0.4s, 0.4s, 0.4s, 0.4s;
								transition-delay: 0s, 0.4s, 0s, 0.4s;
								@media screen and (min-width: 1920px){
									height: 1.5625vw;
								}
							}
							&:hover{
								&:after{
									width: 100%;
									height: calc(100% + 12px);
									opacity: 1;
									transform: translateY(12px);
								}
							}*/
}

@media screen and (min-width: 1920px) {
  /* line 145, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#headMenuShort li {
    padding: 0 0.5729166666666667vw !important;
  }
}

/* line 182, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShort li.current_page_item:after {
  background: #69e5f4;
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: calc(100% + 12px);
  left: 0;
  bottom: 0;
  opacity: 1;
  transform: translateY(12px);
}

/* line 195, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShort li a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* line 203, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShort li ul {
  background: #002131;
  display: none;
  position: absolute;
  z-index: 9;
  padding: 20px;
  width: 200%;
}

/* line 210, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShort li ul li {
  line-height: 100% !important;
  padding: 0 !important;
  float: initial !important;
}

/* line 214, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShort li ul li + li {
  margin: 20px 0 0 0;
}

/* line 223, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShort li:hover ul {
  display: block;
}

/* line 229, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShortIpad {
  display: none;
  padding: 0 15px 0 0;
}

@media screen and (min-width: 1920px) {
  /* line 229, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#headMenuShortIpad {
    padding: 0 0.78125vw 0 0;
  }
}

@media screen and (max-width: 1235px) {
  /* line 229, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#headMenuShortIpad {
    display: block;
  }
}

@media screen and (max-width: 991px) {
  /* line 229, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#headMenuShortIpad {
    display: none;
  }
}

/* line 241, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShortIpad li {
  position: relative;
  padding: 0 11px !important;
  float: right;
  height: 100%;
  line-height: 6.8vw;
  /*&:after{
								background: $bleu-p;
								position: absolute;
								display: block;
								content: "";
								width: 0px;
								height: 30px;
								left: 0;
								bottom: 0;
								opacity: 0;
								transform: translateY(0);
								transition-property: width, height, opacity, transform;
								transition-duration: 0.4s, 0.4s, 0.4s, 0.4s;
								transition-delay: 0s, 0.4s, 0s, 0.4s;
								@media screen and (min-width: 1920px){
									height: 1.5625vw;
								}
							}
							&:hover{
								&:after{
									width: 100%;
									height: calc(100% + 12px);
									opacity: 1;
									transform: translateY(12px);
								}
							}*/
}

@media screen and (min-width: 1920px) {
  /* line 241, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#headMenuShortIpad li {
    line-height: 7.291666666666667vw;
    padding: 0 0.5729166666666667vw !important;
  }
}

/* line 278, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShortIpad li.current_page_item:after {
  background: #69e5f4;
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: calc(100% + 12px);
  left: 0;
  bottom: 0;
  opacity: 1;
  transform: translateY(12px);
}

/* line 291, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShortIpad li a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* line 299, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShortIpad li ul {
  background: #002131;
  display: none;
  position: absolute;
  z-index: 9;
  padding: 20px;
  width: 200%;
}

/* line 306, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShortIpad li ul li {
  line-height: 100% !important;
  padding: 0 !important;
  float: initial !important;
}

/* line 310, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShortIpad li ul li + li {
  margin: 20px 0 0 0;
}

/* line 319, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenuShortIpad li:hover ul {
  display: block;
}

/* line 325, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenu {
  width: 62vw;
  padding: 0 15px 0 0;
}

@media screen and (min-width: 1920px) {
  /* line 325, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#headMenu {
    padding: 0 0.78125vw 0 0;
  }
}

@media screen and (max-width: 991px) {
  /* line 325, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#headMenu {
    display: none;
  }
}

/* line 335, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenu li {
  position: relative;
  line-height: 7.5vw;
  padding: 0 11px !important;
  float: right;
  height: 100%;
  /*&:after{
								background: $bleu-p;
								position: absolute;
								display: block;
								content: "";
								width: 0px;
								height: 30px;
								left: 0;
								bottom: 0;
								opacity: 0;
								transform: translateY(0);
								transition-property: width, height, opacity, transform;
								transition-duration: 0.4s, 0.4s, 0.4s, 0.4s;
								transition-delay: 0s, 0.4s, 0s, 0.4s;
								@media screen and (min-width: 1920px){
									height: 1.5625vw;
								}
							}
							&:hover{
								&:after{
									width: 100%;
									height: calc(100% + 12px);
									opacity: 1;
									transform: translateY(12px);
									@media screen and (min-width: 1920px){
										height: calc(100% + 0.625vw);
										transform: translateY(0.625vw);
									}
								}
							}*/
}

@media screen and (min-width: 1920px) {
  /* line 335, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#headMenu li {
    line-height: 6.8vwvw;
    padding: 0 0.5729166666666667vw !important;
  }
}

/* line 346, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenu li.current_page_item:after {
  background: #69e5f4;
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: calc(100% + 12px);
  left: 0;
  bottom: 0;
  opacity: 1;
  transform: translateY(12px);
}

/* line 389, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#headMenu li a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  font-size: 0.7vw;
}

/* line 399, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#menuPartRight {
  position: relative;
  align-items: center;
  display: flex;
  width: 6.22vw;
  position: relative;
  height: 100%;
  vertical-align: middle;
}

@media screen and (min-width: 1920px) {
  /* line 399, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#menuPartRight {
    width: 9.427083333333334vw;
  }
}

@media screen and (max-width: 991px) {
  /* line 399, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#menuPartRight {
    width: 20vw;
    position: absolute;
    display: flex;
    width: auto;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);
  }
}

@media screen and (max-width: 580px) {
  /* line 422, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#menuPartRight li {
    margin: 0 !important;
  }
}

/* line 426, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#menuPartRight li + li {
  margin: 0 0 0 1.81vw;
}

@media screen and (min-width: 1920px) {
  /* line 426, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#menuPartRight li + li {
    margin: 0 0 0 0.9375vw;
  }
}

/* line 433, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#menuPartRight li.socialUnit {
  line-height: 1.5vw !important;
}

@media all and (max-width: 992px) {
  /* line 433, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#menuPartRight li.socialUnit {
    line-height: 30px !important;
  }
}

@media screen and (max-width: 580px) {
  /* line 433, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#menuPartRight li.socialUnit {
    display: none;
  }
}

/* line 446, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#menuPartRight li a {
  display: block;
  width: 1.63vw;
  height: 1.63vw;
  border: 0.13vw solid #fff;
  border-radius: 1.63vw;
  text-align: center;
  font-size: 0.86vw !important;
}

@media screen and (max-width: 992px) {
  /* line 446, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#menuPartRight li a {
    display: block;
    width: 36px;
    height: 36px;
    border: 3px solid #fff;
    border-radius: 36px;
    text-align: center;
    font-size: 19px !important;
  }
}

@media screen and (max-width: 991px) {
  /* line 473, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#menuPartRight li#lang {
    position: relative;
    width: 25px;
    height: 36px;
    margin-left: 27px;
  }
}

@media screen and (max-width: 991px) {
  /* line 486, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#menuPartRight li#lang a {
    display: none;
  }
}

/* line 491, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#menuPartRight li#lang #hamMenu {
  position: absolute;
  display: none;
  top: 50%;
  width: 25px;
  height: 20px;
  transform: translate(0, -50%) rotate(0deg);
  cursor: pointer;
  transition-property: transform;
  transition-duration: 0.4s;
}

@media screen and (max-width: 991px) {
  /* line 491, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul#menuPartRight li#lang #hamMenu {
    display: block;
  }
}

/* line 505, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#menuPartRight li#lang #hamMenu span {
  background: #fff;
  position: absolute;
  width: 100%;
  height: 2px;
}

/* line 510, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#menuPartRight li#lang #hamMenu span:nth-child(1) {
  top: 0;
  left: 0;
  transition-property: top, transform;
  transition-duration: 0.4s, 0.4s;
  transition-delay: 0.4s, 0.4s;
}

/* line 517, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#menuPartRight li#lang #hamMenu span:nth-child(2) {
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  transition-property: width;
  transition-duration: 0.4s;
  transition-delay: 0.4s;
}

/* line 525, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#menuPartRight li#lang #hamMenu span:nth-child(3) {
  left: 0;
  bottom: 0;
  transition-property: bottom, transform;
  transition-duration: 0.4s, 0.4s;
  transition-delay: 0.4s, 0.4s;
}

/* line 533, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#menuPartRight li#lang #hamMenu.active {
  transform: translate(0, -50%) rotate(360deg);
}

/* line 536, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#menuPartRight li#lang #hamMenu.active span:nth-child(1) {
  top: 50%;
  transform: translate(0, -50%) rotate(45deg);
}

/* line 540, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#menuPartRight li#lang #hamMenu.active span:nth-child(2) {
  width: 0;
}

/* line 543, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul#menuPartRight li#lang #hamMenu.active span:nth-child(3) {
  bottom: 50%;
  transform: translate(0, 50%) rotate(-45deg);
}

/* line 554, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul li {
  line-height: 36px;
  float: left;
}

@media screen and (min-width: 1920px) {
  /* line 554, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul li {
    line-height: 1.875vw;
  }
}

@media screen and (max-width: 580px) {
  /* line 561, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul li#search {
    position: absolute;
    width: calc(100% - 25px);
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
  }
}

/* line 569, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul li#search span {
  background-image: url(../images/loupe.svg);
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  width: 31px;
  height: 31px;
  cursor: pointer;
}

@media screen and (min-width: 1920px) {
  /* line 569, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul li#search span {
    width: 1.6145833333333335vw;
    height: 1.6145833333333335vw;
  }
}

@media screen and (max-width: 580px) {
  /* line 569, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul li#search span {
    float: right;
    margin: 0 20px 0 0;
    width: 25px;
    height: 25px;
  }
}

/* line 590, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul li a {
  color: #fff;
  font-size: 0.77vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  transition: 0.4s;
}

@media screen and (min-width: 1920px) {
  /* line 590, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
  header #wrapHead nav .navInt ul li a {
    font-size: 0.7291666666666667vw;
  }
}

/* line 600, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul li a:hover {
  color: #69e5f4 !important;
}

/* line 604, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header #wrapHead nav .navInt ul li a i {
  padding: 0 !important;
}

/* line 614, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
header.head-is-Scroll {
  position: fixed;
  top: 0;
  left: 0;
}

/* line 1126, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
#menuPartRight li a {
  transition: 0.4s;
}

/* line 1128, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_header.scss */
#menuPartRight li a:hover {
  color: #69e5f4 !important;
  border-color: #69e5f4 !important;
}

/* line 1, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer {
  background: #002131;
  height: 19.6875vw;
}

@media screen and (max-width: 767px) {
  /* line 1, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer {
    height: auto;
    padding: 60px 0;
  }
}

/* line 8, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter {
  position: relative;
  max-width: 90.3125vw;
  margin: 0 auto;
  height: 100%;
}

/* line 13, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #footBottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 5.208333333333334vw;
}

@media screen and (max-width: 767px) {
  /* line 13, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #footBottom {
    position: relative;
    bottom: initial;
  }
}

/* line 23, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #footBottom p {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

@media screen and (max-width: 767px) {
  /* line 23, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #footBottom p {
    position: relative;
    top: initial;
    transform: initial;
  }
}

/* line 34, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #footBottom a {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

@media screen and (max-width: 767px) {
  /* line 34, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #footBottom a {
    position: relative;
    top: initial;
    transform: initial;
  }
}

/* line 44, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #footBottom a img {
  margin: 0 0 0 0.9895833333333334vw;
}

/* line 48, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #footBottom p,
footer #wrapFooter #footBottom a {
  color: #fff;
  font-size: 0.625vw;
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1380px) {
  /* line 48, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #footBottom p,
  footer #wrapFooter #footBottom a {
    font-size: 11px;
  }
}

@media screen and (max-width: 767px) {
  /* line 48, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #footBottom p,
  footer #wrapFooter #footBottom a {
    margin: 30px auto 0;
    text-align: center;
    display: table;
  }
}

/* line 64, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #logo {
  position: absolute;
  top: 4.166666666666667vw;
  left: 0;
}

@media screen and (max-width: 767px) {
  /* line 64, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #logo {
    position: relative;
    top: initial;
  }
}

/* line 74, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #logo a img {
  display: block;
  width: auto;
  height: 7.447916666666667vw;
}

@media screen and (max-width: 767px) {
  /* line 74, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #logo a img {
    height: 80px;
    margin: 0 auto;
  }
}

/* line 85, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos {
  position: absolute;
  top: 3.854166666666667vw;
  right: 0;
  width: 100%;
  max-width: 61.35416666666667vw;
  height: 8.072916666666668vw;
}

@media screen and (max-width: 1380px) {
  /* line 85, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #infos {
    height: 120px;
  }
}

@media screen and (max-width: 767px) {
  /* line 85, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #infos {
    position: relative;
    top: initial;
    max-width: 100%;
    height: auto;
  }
}

/* line 101, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo {
  position: absolute;
  top: 0;
}

@media screen and (max-width: 767px) {
  /* line 101, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #infos .partInfo {
    position: relative;
    top: initial;
    left: initial !important;
    margin: 30px auto 0;
    display: table;
    text-align: center;
  }
}

/* line 112, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo#coords {
  left: 0;
}

/* line 114, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo#coords span {
  display: block;
  max-width: 11.458333333333334vw;
  color: #fff;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
  line-height: 1.09375vw;
}

@media screen and (max-width: 1380px) {
  /* line 114, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #infos .partInfo#coords span {
    font-size: 12px;
    max-width: 180px;
    line-height: 13px;
  }
}

/* line 130, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo#coords #social ul {
  display: flex;
  margin: 0.625vw 0 0 0;
}

/* line 133, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo#coords #social ul li {
  position: relative;
  border: 2px solid #fff;
  width: 1.5104166666666667vw;
  height: 1.5104166666666667vw;
  border-radius: 10.5104166666666667vw;
}

@media screen and (max-width: 1280px) {
  /* line 133, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #infos .partInfo#coords #social ul li {
    width: 20px;
    height: 20px;
  }
}

/* line 143, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo#coords #social ul li + li {
  margin: 0 0 0 0.5208333333333334vw;
}

/* line 146, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo#coords #social ul li a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
}

/* line 153, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo#coords #social ul li a i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* line 160, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo#coords #social ul li:hover {
  border-color: #69e5f4;
}

/* line 162, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo#coords #social ul li:hover a {
  color: #69e5f4;
}

/* line 170, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo#store {
  left: 20.729166666666668vw;
}

/* line 173, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo#information {
  left: 41.145833333333336vw;
}

/* line 176, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo#accordD {
  right: 0;
}

/* line 178, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo#accordD img {
  display: block;
  width: auto;
  height: 3.854166666666667vw;
}

@media screen and (max-width: 767px) {
  /* line 178, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #infos .partInfo#accordD img {
    height: 50px;
  }
}

/* line 191, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo ul li a {
  display: block;
  color: #fff;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
  line-height: 1.09375vw;
}

/* line 198, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo ul li a:hover {
  color: #69e5f4;
}

@media screen and (max-width: 1380px) {
  /* line 191, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #infos .partInfo ul li a {
    font-size: 12px;
    line-height: 13px;
  }
}

/* line 208, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo a {
  display: block;
  color: #fff;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
  line-height: 1.09375vw;
}

/* line 215, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo a:hover {
  color: #69e5f4;
}

@media screen and (max-width: 1380px) {
  /* line 208, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #infos .partInfo a {
    font-size: 12px;
    line-height: 13px;
  }
}

/* line 223, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
footer #wrapFooter #infos .partInfo h5 {
  color: #69e5f4;
  font-size: 1.09375vw;
  line-height: 1.09375vw;
  letter-spacing: 0.01em;
  margin: 0 0 0.5208333333333334vw 0;
  font-family: "sabloReg";
}

@media screen and (max-width: 1380px) {
  /* line 223, public_html/ew-frame/content/themes/ew/assets/src/scss/global/_footer.scss */
  footer #wrapFooter #infos .partInfo h5 {
    font-size: 14px;
    line-height: 15px;
  }
}

/* =======================================================================
Layout
========================================================================== */
/* line 2, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#wrapperPage {
  overflow: hidden;
}

/* line 5, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#intro {
  height: calc(100vh - 140px);
}

@media screen and (min-width: 1920px) {
  /* line 5, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #intro {
    height: calc(100vh - 7.291666666666667vw);
  }
}

@media screen and (max-width: 991px) {
  /* line 5, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #intro {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  /* line 5, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #intro {
    display: none;
  }
}

/* line 17, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#intro img {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 991px) {
  /* line 17, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #intro img {
    width: 100%;
    max-width: 80%;
    height: auto;
    margin: 0 auto;
  }
}

/* line 31, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#intro .partIntro {
  float: left;
  width: 50%;
  height: 100%;
}

/* line 35, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#intro .partIntro#left {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
  position: relative;
}

/* line 40, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#intro .partIntro#left:after {
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
}

/* line 49, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#intro .partIntro#right {
  overflow: hidden;
  display: flex;
}

/* line 52, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#intro .partIntro#right video {
  width: 100%;
  height: 86vh;
  object-fit: cover;
}

/* line 61, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promoMobile {
  display: none;
  height: auto;
  overflow: hidden;
}

/* line 65, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promoMobile #promoListMob {
  height: 100%;
}

/* line 67, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promoMobile #promoListMob .slick-dots {
  position: relative;
  display: table;
  width: auto;
  padding: 0;
  margin: 0 auto;
  top: initial;
  left: initial;
  right: initial;
  bottom: -11px;
}

/* line 77, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promoMobile #promoListMob .slick-dots li {
  background: transparent;
  border: 1px solid #000;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  transition-property: background;
  transition-duration: 0.4s;
  margin: 0;
}

/* line 86, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promoMobile #promoListMob .slick-dots li + li {
  margin: 0 0 0 10px;
}

/* line 91, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promoMobile #promoListMob .slick-dots li button:before {
  display: none;
}

/* line 95, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promoMobile #promoListMob .slick-dots li.slick-active {
  background: #000;
}

/* line 100, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promoMobile #promoListMob .slick-list {
  height: 100%;
}

/* line 102, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promoMobile #promoListMob .slick-list .slick-track {
  height: 100%;
}

/* line 104, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promoMobile #promoListMob .slick-list .slick-track .slick-slide {
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
  position: relative;
  height: 100%;
  min-height: 275px;
}

@media all and (max-width: 767px) {
  /* line 104, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #promoMobile #promoListMob .slick-list .slick-track .slick-slide {
    min-height: 55vw;
    background-size: contain;
    background-position: center;
  }
}

/* line 118, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promoMobile #promoListMob .slick-list .slick-track .slick-slide .slideInt {
  padding: 16vw 15.9vw;
  margin: 1.36vw auto 0;
  border: 5px solid #002131;
  max-width: 90%;
  text-align: center;
}

/* line 125, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promoMobile #promoListMob .slick-list .slick-track .slick-slide .slideInt h2 {
  font-size: 28px;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
  line-height: 34px;
}

@media screen and (max-width: 767px) {
  /* line 61, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #promoMobile {
    display: block;
  }
}

/* line 140, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished {
  overflow: hidden;
}

@media screen and (max-width: 767px) {
  /* line 143, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #promotions {
    display: none;
  }
}

/* line 147, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList {
  position: relative;
  height: 57.917vw;
  margin: 0 !important;
}

/* line 151, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-dots {
  position: absolute;
  left: 8.958333333333334vw;
  bottom: 3.385416666666667vw;
  display: table;
  width: auto;
  padding: 0;
  margin: 0;
}

/* line 159, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-dots li {
  background: transparent;
  border: 1px solid #000;
  width: 1.09375vw;
  height: 1.09375vw;
  border-radius: 1.09375vw;
  transition-property: background;
  transition-duration: 0.4s;
  margin: 0;
}

/* line 168, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-dots li + li {
  margin: 0 0 0 0.78125vw;
}

/* line 173, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-dots li button:before {
  display: none;
}

/* line 177, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-dots li.slick-active {
  background: #000;
}

/* line 182, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list {
  height: 100%;
}

/* line 184, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track {
  height: 100%;
}

/* line 186, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  height: 100%;
}

/* line 193, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc {
  position: absolute;
  width: calc(100% - (8.958333333333334vw * 2));
  height: calc(100% - (6.041666666666667vw * 2));
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* line 201, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc {
  background: #fff;
  position: relative;
  border-width: 0.9375vw;
  border-style: solid;
}

/* line 206, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc:nth-child(1) {
  width: 100%;
  max-width: 32.34375vw;
  height: 100%;
  border-color: #69e5f4;
  float: left;
  color: #69e5f4;
}

/* line 213, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc:nth-child(1) .contenu {
  padding: 12.395833333333334vw 0 0 0;
}

/* line 215, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc:nth-child(1) .contenu h2 {
  max-width: 19.791666666666668vw;
  margin: 0 auto;
}

/* line 219, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc:nth-child(1) .contenu h3 {
  max-width: 21.354166666666668vw;
  margin: 0 auto;
}

/* line 222, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc:nth-child(1) .contenu h3:before {
  background: #69e5f4;
}

/* line 228, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc:nth-child(2) {
  background: #002131;
  width: 100%;
  max-width: 44.739583333333336vw;
  height: calc(50% - 2.447916666666667vw);
  border-color: #002131;
  float: right;
  color: #fff;
  border: none;
}

/* line 237, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc:nth-child(2) .contenu {
  padding: 2.760416666666667vw 0 0 0;
}

/* line 239, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc:nth-child(2) .contenu h2 {
  max-width: 25.885416666666668vw;
  margin: 0 auto;
}

/* line 245, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc:nth-child(2) .contenu h3:before {
  background: #fff;
}

/* line 251, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc:nth-child(3) {
  width: 100%;
  max-width: 44.739583333333336vw;
  height: calc(50% - 2.447916666666667vw);
  border-color: #3990ae;
  position: absolute;
  right: 0;
  bottom: 0;
  color: #3990ae;
}

/* line 260, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc:nth-child(3) .contenu {
  padding: 3.125vw 0 0 0;
}

/* line 265, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc:nth-child(3) .contenu h3 {
  max-width: 29.166666666666668vw;
  margin: 0 auto;
}

/* line 268, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc:nth-child(3) .contenu h3:before {
  background: #3990ae;
}

/* line 275, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc .contenu {
  position: absolute;
  height: auto;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  text-align: center;
  padding: 0 !important;
}

/* line 284, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc .contenu h2 {
  font-size: 2.7083333333333335vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  line-height: 2.916666666666667vw;
}

/* line 291, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc .contenu h3 {
  font-size: 1.7708333333333335vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
  line-height: 2.03125vw;
}

/* line 296, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc .contenu h3:before {
  position: relative;
  display: block;
  content: "";
  margin: 2.0833333333333335vw auto 1.3020833333333335vw;
  width: 5.208333333333334vw;
  height: 2px;
}

/* line 305, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc .contenu p {
  position: absolute;
  bottom: 1.0416666666666667vw;
  text-align: center;
  padding: 0 3.125vw;
  font-size: 0.7291666666666667vw;
  font-family: "robotoItalic";
  line-height: 0.8854166666666667vw;
}

@media screen and (max-width: 1280px) {
  /* line 305, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #promotions #promoList .slick-list .slick-track .slick-slide .toBloc .bloc .contenu p {
    font-size: 1.1vw;
    line-height: 1.2vw;
  }
}

/* line 329, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide {
  max-width: calc((100% - 8.958333333333334vw) + 2.447916666666667vw);
  height: 59.375vw;
}

@media screen and (max-width: 991px) {
  /* line 329, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide {
    max-width: calc((100% - 8.958333333333334vw * 2) + 2.447916666666667vw);
    margin: 0 auto;
    height: auto;
  }
}

/* line 337, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #left {
  background: #002131;
  float: left;
  width: 41.35416666666667vw;
  height: 100%;
}

@media screen and (max-width: 991px) {
  /* line 337, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #left {
    width: 90vw;
    float: initial;
    height: auto;
    margin: 0 0vw 60px -10vw;
  }
}

/* line 349, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #left .contenu {
  width: calc(100% - 8.229166666666668vw);
  text-align: right;
}

@media screen and (max-width: 991px) {
  /* line 349, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #left .contenu {
    width: 100%;
    padding: 20px 60px 40px 0;
    position: relative;
  }
}

/* line 357, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #left .contenu #img {
  background-image: url(../images/remis-a-neufs-presentation.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left bottom;
  position: relative;
  width: 100%;
  height: 31.25vw;
  opacity: 0.25;
  margin: 0 0 3.385416666666667vw 0;
}

@media screen and (max-width: 991px) {
  /* line 357, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #left .contenu #img {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 300px;
    height: 100%;
    margin: 0;
  }
}

/* line 375, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #left .contenu #img:after {
  background: #002131;
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

/* line 387, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #left .contenu h3 {
  color: #fff;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  letter-spacing: 0.01em;
  line-height: 4.0625vw;
  max-width: 17.96875vw;
  float: right;
  margin: 0 0 2.8645833333333335vw 0;
}

@media screen and (max-width: 1280px) {
  /* line 387, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #left .contenu h3 {
    font-size: 52px;
    line-height: 52px;
    max-width: 230px;
  }
}

@media screen and (max-width: 420px) {
  /* line 387, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #left .contenu h3 {
    font-size: 47px;
    line-height: 47px;
  }
}

/* line 409, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #left .contenu a {
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  clear: both;
  float: right;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
}

@media screen and (max-width: 1280px) {
  /* line 409, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #left .contenu a {
    width: 162px;
    font-size: 14px;
    height: 20px;
  }
}

/* line 432, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #left .contenu a:before {
  background: #69e5f4;
  position: absolute;
  display: none;
  content: "";
  bottom: -0.625vw;
  width: 0px;
  height: 0px;
  transition-property: width, height;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0.3s;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}

@media screen and (max-width: 1280px) {
  /* line 432, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #left .contenu a:before {
    bottom: -7px;
  }
}

/* line 450, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #left .contenu a:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 450, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #left .contenu a:after {
    height: 0.20833333333333334vw;
  }
}

@media screen and (max-width: 1280px) {
  /* line 450, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #left .contenu a:after {
    bottom: -7px;
  }
}

/* line 468, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #left .contenu a:hover {
  color: #fff;
}

/* line 470, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #left .contenu a:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

@media screen and (max-width: 1280px) {
  /* line 470, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #left .contenu a:hover:before {
    width: 179px;
    height: 36px;
  }
}

/* line 478, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #left .contenu a:hover:after {
  width: 0;
}

/* line 485, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #right {
  float: left;
  width: calc(100% - 41.35416666666667vw);
  padding: 0 0 0 2.447916666666667vw;
}

@media screen and (max-width: 991px) {
  /* line 485, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #right {
    float: initial;
    width: 100%;
    padding: 0;
  }
}

/* line 496, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #right #rightInt .spa-by-spa {
  float: left;
  width: 50%;
  padding: 0 2.447916666666667vw;
  margin: 0 0 2.0833333333333335vw 0;
}

@media screen and (max-width: 991px) {
  /* line 496, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #right #rightInt .spa-by-spa {
    margin: 0 0 30px 0;
  }
}

@media screen and (max-width: 580px) {
  /* line 496, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #right #rightInt .spa-by-spa {
    width: 100%;
  }
}

/* line 508, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #right #rightInt .spa-by-spa .spasImg {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 19.895833333333336vw;
  display: block;
}

@media screen and (max-width: 991px) {
  /* line 508, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #right #rightInt .spa-by-spa .spasImg {
    height: 250px;
  }
}

@media screen and (max-width: 767px) {
  /* line 508, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #right #rightInt .spa-by-spa .spasImg {
    height: 200px;
  }
}

@media screen and (max-width: 420px) {
  /* line 508, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #right #rightInt .spa-by-spa .spasImg {
    height: 175px;
  }
}

/* line 525, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #right #rightInt .spa-by-spa .spasImg .new {
  background-color: #f28136;
  display: block;
  position: absolute;
  width: 6.302083333333334vw;
  height: 6.302083333333334vw;
  border-radius: 6.302083333333334vw;
  top: 1.40625vw;
  right: calc(-0.5208333333333334vw);
}

@media screen and (max-width: 991px) {
  /* line 525, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #right #rightInt .spa-by-spa .spasImg .new {
    width: 80px;
    height: 80px;
    border-radius: 80px;
  }
}

/* line 539, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #right #rightInt .spa-by-spa .spasImg .new span {
  display: block;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  text-align: center;
  color: #fff;
  font-size: 1.1458333333333335vw;
  line-height: 1.1458333333333335vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 991px) {
  /* line 539, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #right #rightInt .spa-by-spa .spasImg .new span {
    font-size: 16px;
    line-height: 16px;
  }
}

/* line 558, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #right #rightInt .spa-by-spa .contenu {
  text-align: center;
  margin: 1.0416666666666667vw 0 0 0;
}

/* line 561, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #right #rightInt .spa-by-spa .contenu h5 {
  color: #002131;
  font-size: 0.9895833333333334vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 561, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #right #rightInt .spa-by-spa .contenu h5 {
    font-size: 14px;
  }
}

/* line 571, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #right #rightInt .spa-by-spa .contenu span {
  display: block;
}

/* line 573, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #right #rightInt .spa-by-spa .contenu span.year {
  color: #002131;
  font-size: 0.9895833333333334vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 573, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #right #rightInt .spa-by-spa .contenu span.year {
    font-size: 14px;
  }
}

/* line 582, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#refurbished .corpsSide #right #rightInt .spa-by-spa .contenu span.price {
  color: #69e5f4;
  font-size: 0.9895833333333334vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 582, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #refurbished .corpsSide #right #rightInt .spa-by-spa .contenu span.price {
    font-size: 14px;
  }
}

/* line 599, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre {
  margin-left: 10vw;
  position: relative;
  margin: -8.020833333333334vw 0 0 0;
  padding: 22.135416666666668vw 0 0 0;
  clear: initial;
}

@media screen and (max-width: 991px) {
  /* line 599, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre {
    margin: 0;
  }
}

/* line 608, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre:before {
  background-image: url(../images/vague-bg.png);
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

/* line 622, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre:after {
  background-color: #3990ae;
  position: absolute;
  display: block;
  content: "";
  left: 0;
  bottom: 0;
  width: 100%;
  height: calc(100% - 9.895833333333334vw);
  z-index: -1;
}

/* line 633, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #desc {
  position: relative;
  max-width: 55.78125vw;
  margin: 0 auto;
}

@media screen and (max-width: 1280px) {
  /* line 633, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #desc {
    max-width: 714px;
  }
}

@media screen and (max-width: 991px) {
  /* line 633, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #desc {
    padding: 0 40px;
  }
}

/* line 643, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #desc p {
  position: absolute;
  width: 100%;
  height: auto;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  text-align: center;
  max-width: 46.614583333333336vw;
  font-size: 1.3541666666666667vw;
  font-family: "sabloReg";
  line-height: 2.03125vw;
  z-index: 2;
  color: #fff;
}

@media screen and (max-width: 1280px) {
  /* line 643, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #desc p {
    font-size: 17px;
    line-height: 26px;
    max-width: 598px;
  }
}

@media screen and (max-width: 767px) {
  /* line 643, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #desc p {
    padding: 0 40px;
  }
}

/* line 668, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #desc #descTitle span {
  display: block;
  color: #69e5f4;
  font-size: 6.770833333333334vw;
  font-family: "robotoBlack";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  opacity: 0.25;
  line-height: 6.770833333333334vw;
}

@media screen and (max-width: 1280px) {
  /* line 668, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #desc #descTitle span {
    font-size: 86px;
    line-height: 86px;
  }
}

@media screen and (max-width: 580px) {
  /* line 668, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #desc #descTitle span {
    font-size: 60px;
    line-height: 60px;
  }
}

/* line 688, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas {
  position: relative;
  height: 60vw;
  margin: 11.979166666666668vw 0 0 0;
  z-index: 1;
}

/* line 693, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas .vague {
  position: absolute;
  right: 0;
  top: calc(-182px - 65px);
}

/* line 698, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #left {
  background: #fff;
  width: 66.19791666666667vw;
  height: 100%;
  padding: 5.989583333333334vw 0 0 0;
}

@media screen and (max-width: 991px) {
  /* line 698, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #left {
    width: 100%;
    padding: 0;
    height: auto;
    padding: 60px 0 30px;
  }
}

/* line 709, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #left #leftIn {
  max-width: calc(100% - (8.854166666666668vw * 2));
  margin: 0 auto;
}

/* line 712, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #left #leftIn .spa-by-spa {
  float: left;
  width: 50%;
  margin: 0 0 2.0833333333333335vw 0;
}

@media screen and (max-width: 580px) {
  /* line 712, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #left #leftIn .spa-by-spa {
    width: 100%;
    margin: 0 0 40px 0;
  }
}

/* line 720, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #left #leftIn .spa-by-spa .spasInt {
  max-width: 19.947916666666668vw;
  width: 100%;
  display: block;
}

@media screen and (max-width: 991px) {
  /* line 720, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #left #leftIn .spa-by-spa .spasInt {
    max-width: 90%;
    margin: 0 auto;
  }
}

/* line 728, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .spasImg {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: 18.229166666666668vw;
}

@media screen and (max-width: 991px) {
  /* line 728, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .spasImg {
    height: 250px;
  }
}

@media screen and (max-width: 767px) {
  /* line 728, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .spasImg {
    height: 200px;
  }
}

@media screen and (max-width: 420px) {
  /* line 728, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .spasImg {
    height: 175px;
  }
}

/* line 744, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .spasImg .new {
  background: #f28136;
  display: block;
  position: absolute;
  width: 6.302083333333334vw;
  height: 6.302083333333334vw;
  border-radius: 6.302083333333334vw;
  top: 1.40625vw;
  left: calc(19.947916666666668vw - 6.302083333333334vw + 0.5208333333333334vw);
}

@media screen and (max-width: 991px) {
  /* line 744, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .spasImg .new {
    width: 80px;
    height: 80px;
    border-radius: 80px;
    left: initial;
    right: 0;
  }
}

/* line 760, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .spasImg .new span {
  display: block;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  text-align: center;
  color: #fff;
  font-size: 1.1458333333333335vw;
  line-height: 1.1458333333333335vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 991px) {
  /* line 760, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .spasImg .new span {
    font-size: 16px;
    line-height: 16px;
  }
}

/* line 779, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .contenu {
  text-align: center;
  margin: 1.0416666666666667vw 0 0 0;
}

/* line 782, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .contenu h5 {
  color: #002131;
  font-size: 0.9895833333333334vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 782, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .contenu h5 {
    font-size: 14px;
  }
}

/* line 792, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .contenu span {
  display: block;
}

/* line 794, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .contenu span.year {
  color: #002131;
  font-size: 0.9895833333333334vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 794, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .contenu span.year {
    font-size: 14px;
  }
}

/* line 803, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .contenu span.price {
  color: #69e5f4;
  font-size: 0.9895833333333334vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 803, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #left #leftIn .spa-by-spa .spasInt .contenu span.price {
    font-size: 14px;
  }
}

/* line 818, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #right {
  background: #002131;
  position: absolute;
  top: 50%;
  right: 0;
  width: 41.35416666666667vw;
  height: calc(100% - (6.197916666666667vw * 2));
  transform: translate(0, -50%);
}

@media screen and (max-width: 991px) {
  /* line 818, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #right {
    margin-left: 10vw;
    position: relative;
    top: initial;
    right: 0;
    transform: initial;
    height: auto;
    width: 90vw;
  }
}

/* line 837, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #right .contenu {
  width: calc(100% - 3.8020833333333335vw);
  text-align: left;
  float: right;
}

@media screen and (max-width: 991px) {
  /* line 837, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #right .contenu {
    padding: 20px 60px 40px 0;
    display: flex;
    flex-direction: column;
  }
}

/* line 847, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #right .contenu #img {
  background-image: url(../images/remis-a-neufs-presentation.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right bottom;
  position: relative;
  width: 100%;
  height: 26.510416666666668vw;
  opacity: 0.25;
  margin: 0 0 3.385416666666667vw 0;
}

@media screen and (max-width: 991px) {
  /* line 847, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #right .contenu #img {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 100%;
  }
}

/* line 864, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #right .contenu #img:after {
  background: #002131;
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}

/* line 876, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #right .contenu h3 {
  color: #fff;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  letter-spacing: 0.01em;
  line-height: 4.0625vw;
  max-width: 10.416666666666668vw;
  margin: 0 0 2.8645833333333335vw 0;
}

@media screen and (max-width: 1280px) {
  /* line 876, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #right .contenu h3 {
    font-size: 52px;
    line-height: 52px;
    max-width: 135px;
  }
}

@media screen and (max-width: 991px) {
  /* line 876, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #right .contenu h3 {
    float: right;
  }
}

@media screen and (max-width: 420px) {
  /* line 876, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #right .contenu h3 {
    font-size: 47px;
    line-height: 47px;
  }
}

/* line 897, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #right .contenu a {
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  clear: both;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
}

@media screen and (max-width: 1280px) {
  /* line 897, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #right .contenu a {
    font-size: 14px;
    height: 20px;
  }
}

@media screen and (max-width: 991px) {
  /* line 897, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #right .contenu a {
    float: right;
    width: 159px;
    font-size: 14px;
  }
}

/* line 920, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #right .contenu a:before {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 0px;
  height: 0px;
  transition-property: width, height;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0.3s;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}

/* line 935, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #right .contenu a:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 935, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #montre #listNewSpas #right .contenu a:after {
    height: 0.20833333333333334vw;
  }
}

/* line 949, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #right .contenu a:hover {
  color: #fff;
}

/* line 951, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #right .contenu a:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

/* line 955, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#montre #listNewSpas #right .contenu a:hover:after {
  width: 0;
}

/* line 965, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#marques {
  background: #3990ae;
  padding: 11.875vw 0 10.677083333333334vw 0;
}

/* line 968, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#marques .goutte {
  position: absolute;
  top: -1.5104166666666667vw;
  left: -0.78125vw;
}

/* line 973, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#marques .vague-rond-bleu {
  position: absolute;
  right: 0;
  bottom: 70px;
}

/* line 978, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#marques .corpsWrap {
  max-width: calc(100% - (9.010416666666668vw * 2));
  margin: 0 auto;
}

@media screen and (max-width: 580px) {
  /* line 982, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #marques .corpsWrap #listMarques {
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 580px) {
  /* line 989, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #marques .corpsWrap #listMarques .wrapMarque {
    width: 48%;
  }
}

/* line 995, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#marques .corpsWrap #listMarques .wrapMarque:after {
  display: table;
  content: "";
  clear: both;
}

/* line 1001, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#marques .corpsWrap #listMarques .wrapMarque + .wrapMarque {
  border-top: 1px solid #fff;
}

@media screen and (max-width: 580px) {
  /* line 1005, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #marques .corpsWrap #listMarques .wrapMarque + .wrapMarque:nth-child(2) {
    border-top: none;
    margin: 1vw;
  }
}

/* line 1012, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#marques .corpsWrap #listMarques .wrapMarque .marque-par-marque {
  position: relative;
  float: left;
  width: 25%;
  height: 19.010416666666668vw;
}

/* line 1019, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#marques .corpsWrap #listMarques .wrapMarque .marque-par-marque:first-child:after {
  background: #fff;
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 100%;
  right: 0;
}

@media screen and (max-width: 580px) {
  /* line 1019, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #marques .corpsWrap #listMarques .wrapMarque .marque-par-marque:first-child:after {
    background: none;
  }
}

/* line 1034, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#marques .corpsWrap #listMarques .wrapMarque .marque-par-marque:nth-child(3):before {
  background: #fff;
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 100%;
  left: 0;
}

/* line 1043, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#marques .corpsWrap #listMarques .wrapMarque .marque-par-marque:nth-child(3):after {
  background: #fff;
  position: absolute;
  display: block;
  content: "";
  width: 1px;
  height: 100%;
  right: 0;
}

@media screen and (max-width: 991px) {
  /* line 1012, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #marques .corpsWrap #listMarques .wrapMarque .marque-par-marque {
    width: 33.3333333333%;
  }
  /* line 1056, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #marques .corpsWrap #listMarques .wrapMarque .marque-par-marque:nth-child(3):after {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  /* line 1012, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #marques .corpsWrap #listMarques .wrapMarque .marque-par-marque {
    width: 50%;
  }
  /* line 1064, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #marques .corpsWrap #listMarques .wrapMarque .marque-par-marque:nth-child(3):after, #marques .corpsWrap #listMarques .wrapMarque .marque-par-marque:nth-child(3):before {
    display: none;
  }
}

@media screen and (max-width: 580px) {
  /* line 1012, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #marques .corpsWrap #listMarques .wrapMarque .marque-par-marque {
    width: 100%;
  }
}

/* line 1074, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#marques .corpsWrap #listMarques .wrapMarque .marque-par-marque .marqueInt {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
}

/* line 1080, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#marques .corpsWrap #listMarques .wrapMarque .marque-par-marque .marqueInt h2 {
  color: #fff;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  letter-spacing: 0.01em;
  line-height: 4.0625vw;
  padding: 0 3.125vw 0 0;
}

@media screen and (max-width: 991px) {
  /* line 1080, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #marques .corpsWrap #listMarques .wrapMarque .marque-par-marque .marqueInt h2 {
    font-size: 6vw;
    line-height: 6vw;
  }
}

@media screen and (max-width: 767px) {
  /* line 1080, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #marques .corpsWrap #listMarques .wrapMarque .marque-par-marque .marqueInt h2 {
    font-size: 7vw;
    line-height: 7vw;
  }
}

/* line 1097, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#marques .corpsWrap #listMarques .wrapMarque .marque-par-marque .marqueInt img {
  display: block;
  max-width: 80%;
  height: auto;
  margin: 0 auto;
}

/* line 1110, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#garantieHome {
  background: #3990ae;
  padding: 4.427083333333334vw 0 0 0;
  height: 35.78125vw;
}

@media screen and (max-width: 991px) {
  /* line 1110, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #garantieHome {
    height: auto;
  }
}

/* line 1117, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#garantieHome .corpsWrap {
  max-width: calc(100% - (9.010416666666668vw * 2));
  margin: 0 auto;
  height: 100%;
}

/* line 1121, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#garantieHome .corpsWrap .contenu {
  max-width: calc(100% - 4.6875vw);
  margin: 0 auto;
  height: 100%;
}

@media screen and (max-width: 991px) {
  /* line 1121, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #garantieHome .corpsWrap .contenu {
    max-width: 620px;
  }
}

/* line 1128, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#garantieHome .corpsWrap .contenu #left {
  position: relative;
  float: left;
  width: 48.54166666666667vw;
  height: 100%;
}

@media screen and (max-width: 991px) {
  /* line 1128, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #garantieHome .corpsWrap .contenu #left {
    width: 100%;
    margin: 0 0 100px 0;
  }
}

@media screen and (max-width: 767px) {
  /* line 1128, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #garantieHome .corpsWrap .contenu #left {
    margin: 0 0 90px 0;
  }
}

@media screen and (max-width: 580px) {
  /* line 1128, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #garantieHome .corpsWrap .contenu #left {
    margin: 0 0 80px 0;
  }
}

@media screen and (max-width: 420px) {
  /* line 1128, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #garantieHome .corpsWrap .contenu #left {
    margin: 0 0 60px 0;
  }
}

/* line 1146, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#garantieHome .corpsWrap .contenu #left h1 {
  position: absolute;
  right: 0;
  text-align: right;
  font-size: 6.770833333333334vw;
  font-family: "robotoBlack";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #69e5f4;
  line-height: 6.770833333333334vw;
  max-width: 37.76041666666667vw;
}

@media screen and (max-width: 991px) {
  /* line 1146, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #garantieHome .corpsWrap .contenu #left h1 {
    top: -20vw;
    font-size: 86px;
    line-height: 86px;
    max-width: 80%;
    opacity: 0.2;
    font-size: 8vw !important;
    width: 60%;
    line-height: 60px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1146, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #garantieHome .corpsWrap .contenu #left h1 {
    width: 50%;
  }
}

/* line 1174, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#garantieHome .corpsWrap .contenu #left p {
  position: absolute;
  max-width: 26.40625vw;
  top: 7.291666666666667vw;
  left: 0;
  text-align: right;
  color: #fff;
  font-size: 1.3541666666666667vw;
  font-family: "sabloReg";
  letter-spacing: 0.01em;
  line-height: 2.03125vw;
}

@media screen and (max-width: 991px) {
  /* line 1174, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #garantieHome .corpsWrap .contenu #left p {
    font-size: 17px;
    line-height: 26px;
    max-width: 340px;
    position: relative;
    top: initial;
    z-index: 2;
    max-width: 100%;
  }
}

/* line 1199, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#garantieHome .corpsWrap .contenu #right {
  float: left;
  width: calc(100% - 48.54166666666667vw);
}

@media screen and (max-width: 991px) {
  /* line 1199, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #garantieHome .corpsWrap .contenu #right {
    display: none;
  }
}

/* line 1205, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#garantieHome .corpsWrap .contenu #right img {
  display: block;
  width: auto;
  height: 15.989583333333334vw;
  margin: -0.5208333333333334vw 0 0 4.166666666666667vw;
}

/* line 1216, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome {
  background: #3990ae;
  padding: 0 0 7.1875vw 0;
  overflow: hidden;
}

/* line 1220, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap {
  width: 100%;
  max-width: calc(100% - (9.010416666666668vw));
  margin: 0 auto;
  height: 100%;
  float: right;
}

@media all and (max-width: 767px) {
  /* line 1220, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap {
    float: inherit;
    margin-left: 0;
  }
}

/* line 1230, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap .contenu {
  position: relative;
  height: 35.78125vw;
}

@media screen and (max-width: 1280px) {
  /* line 1230, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu {
    height: auto;
  }
}

/* line 1236, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap .contenu #left {
  background: #fff;
  position: relative;
  width: 65.36458333333334vw;
  height: calc(100% - 1.8229166666666667vw);
}

/* line 1241, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap .contenu #left .goutte {
  position: absolute;
  top: calc(-15.46875vw / 2);
  right: 8.333333333333334vw;
}

@media screen and (max-width: 1280px) {
  /* line 1236, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu #left {
    height: auto;
    padding: 60px 0;
  }
}

@media screen and (max-width: 1200px) {
  /* line 1236, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu #left {
    width: 80%;
    height: 643px;
    overflow: hidden;
  }
}

@media screen and (max-width: 767px) {
  /* line 1236, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu #left {
    width: 100%;
    height: auto;
    padding-bottom: 370px;
  }
}

@media screen and (max-width: 580px) {
  /* line 1236, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu #left {
    width: 100%;
    height: auto;
    padding-bottom: 80vw;
  }
}

/* line 1265, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap .contenu #left .leftInt {
  position: absolute;
  top: 50%;
  left: 4.114583333333334vw;
  transform: translate(0, -50%);
  max-width: 34.375vw;
}

@media screen and (max-width: 1280px) {
  /* line 1265, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu #left .leftInt {
    position: relative;
    top: initial;
    transform: initial;
  }
}

@media screen and (max-width: 1200px) {
  /* line 1265, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu #left .leftInt {
    max-width: 70%;
  }
}

@media screen and (max-width: 767px) {
  /* line 1265, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu #left .leftInt {
    max-width: 80%;
  }
}

/* line 1282, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap .contenu #left .leftInt h3 {
  color: #0b374a;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  letter-spacing: 0.01em;
  line-height: 4.0625vw;
}

@media screen and (max-width: 1280px) {
  /* line 1282, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu #left .leftInt h3 {
    font-size: 52px;
    line-height: 52px;
  }
}

/* line 1293, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap .contenu #left .leftInt p {
  color: #0b374a;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
  line-height: 1.3541666666666667vw;
  margin: 3.4375vw 0 4.583333333333334vw 0;
}

@media screen and (max-width: 1280px) {
  /* line 1293, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu #left .leftInt p {
    font-size: 15px;
    line-height: 24px;
    margin: 40px 0 60px 0;
  }
}

/* line 1306, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap .contenu #left .leftInt a {
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  clear: both;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
}

@media screen and (max-width: 1280px) {
  /* line 1306, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu #left .leftInt a {
    font-size: 14px;
    height: 20px;
  }
}

/* line 1324, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap .contenu #left .leftInt a:before {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 0px;
  height: 0px;
  transition-property: width, height;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0.3s;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}

/* line 1339, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap .contenu #left .leftInt a:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 1339, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu #left .leftInt a:after {
    height: 0.20833333333333334vw;
  }
}

/* line 1353, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap .contenu #left .leftInt a:hover {
  color: #fff;
}

/* line 1355, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap .contenu #left .leftInt a:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

/* line 1359, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap .contenu #left .leftInt a:hover:after {
  width: 0;
}

/* line 1368, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
#urgenceHome .corpsWrap .contenu #right {
  background-image: url(../images/camion.png);
  background-size: auto 100%;
  background-position: left center;
  background-repeat: no-repeat;
  position: absolute;
  width: 49.739583333333336vw;
  height: 33.85416666666667vw;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 767px) {
  /* line 1368, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu #right {
    overflow: hidden;
    display: none;
    right: -9vw;
    height: 44vw;
    width: 64vw;
  }
}

@media screen and (max-width: 580px) {
  /* line 1368, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-front-page.scss */
  #urgenceHome .corpsWrap .contenu #right {
    height: 64vw;
    width: 84vw;
  }
}

/* line 2, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #topUsage {
  padding: 6.041666666666667vw 0 2.8125vw 0;
}

/* line 4, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #topUsage .vague {
  position: absolute;
  right: 0;
  bottom: 0;
}

/* line 10, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #topUsage #titleBox {
  background: #fff;
  position: relative;
  width: 45.520833333333336vw;
  height: 16.875vw;
  border: 0.8854166667vw solid #69e5f4;
  border-left: none !important;
}

@media screen and (max-width: 991px) {
  /* line 10, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #topUsage #titleBox {
    padding: 2vw;
    margin: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
}

@media screen and (max-width: 580px) {
  /* line 10, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #topUsage #titleBox {
    padding: 0vw;
  }
}

/* line 27, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #topUsage #titleBox:before {
  position: absolute;
  background-image: url(../images/vague-prod.jpg);
  background-size: contain;
  background-position: right center;
  background-repeat: no-repeat;
  top: 50%;
  left: 14vw;
  display: table;
  content: "";
  width: 74.47916666666667vw;
  height: 200%;
  clear: both;
  transform: translate(0, -50%);
  z-index: -1;
}

/* line 43, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #topUsage #titleBox:after {
  display: table;
  content: "";
  clear: both;
}

/* line 48, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #topUsage #titleBox h1 {
  position: absolute;
  top: 50%;
  right: 8.333333333333334vw;
  transform: translate(0, -50%);
  max-width: 17.708333333333336vw;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  line-height: 4.0625vw;
  color: #002131;
  text-align: right;
}

@media screen and (max-width: 991px) {
  /* line 48, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #topUsage #titleBox h1 {
    font-size: 52px;
    line-height: 52px;
    max-width: 227px;
    float: right;
    top: initial;
    transform: initial;
  }
}

@media screen and (max-width: 767px) {
  /* line 48, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #topUsage #titleBox h1 {
    font-size: 47px;
    line-height: 47px;
    max-width: 210px;
  }
}

@media screen and (max-width: 580px) {
  /* line 48, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #topUsage #titleBox h1 {
    font-size: 6vw !important;
    line-height: 42px;
    max-width: 29vw;
  }
}

@media screen and (max-width: 420px) {
  /* line 48, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #topUsage #titleBox h1 {
    line-height: 37px;
  }
}

/* line 86, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products {
  max-width: 90.3125vw;
  margin: 0 auto;
  padding: 3.541666666666667vw 0 10vw 0;
  z-index: 999;
}

@media screen and (max-width: 767px) {
  /* line 86, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products {
    max-width: 100%;
  }
}

/* line 94, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left {
  position: relative;
  float: left;
  width: 19.947916666666668vw;
  min-height: 78.80208333333334vw;
}

@media screen and (max-width: 991px) {
  /* line 94, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left {
    width: 25vw;
    min-height: 90vw;
  }
}

@media screen and (max-width: 767px) {
  /* line 94, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left {
    width: 100%;
    float: initial;
    min-height: initial;
  }
}

/* line 108, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left #filterProd {
  display: none;
  cursor: pointer;
  padding: 8px 20px;
  font-size: 14px;
  font-family: "robotoReg";
  border-top: 1px solid #002131;
  border-bottom: 1px solid #002131;
  margin: 0 0 20px 0;
}

@media screen and (max-width: 767px) {
  /* line 108, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left #filterProd {
    display: block;
  }
}

/* line 121, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left .allSpas {
  position: relative;
  color: #002131;
  font-size: 0.9895833333333334vw;
  font-family: "robotoReg";
  line-height: 0.8333333333333334vw;
  letter-spacing: 0.01em;
  padding-left: 1.9270833333333335vw;
  font-weight: 700;
}

@media screen and (max-width: 1280px) {
  /* line 121, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left .allSpas {
    font-size: 12px;
    line-height: 10px;
  }
}

@media screen and (max-width: 767px) {
  /* line 121, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left .allSpas {
    display: none;
    padding-left: 36px;
    font-size: 14px;
    line-height: 12px;
    margin: 0 0 0 20px;
  }
}

/* line 141, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left .allSpas:before {
  background: #d7d7d7;
  position: absolute;
  display: block;
  content: "";
  left: 0;
  width: 0.8333333333333334vw;
  height: 0.8333333333333334vw;
  top: 50%;
  transform: translate(0, -50%);
}

@media screen and (max-width: 767px) {
  /* line 141, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left .allSpas:before {
    width: 16px;
    height: 16px;
  }
}

/* line 157, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left .allSpas.active:before {
  background: #69e5f4;
}

@media screen and (max-width: 767px) {
  /* line 162, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left form {
    display: none;
    padding: 0 20px;
  }
}

/* line 168, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left form .labTitle {
  color: #002131;
  font-size: 1.1979166666666667vw;
  font-family: "sabloReg";
  letter-spacing: 0.1em;
  line-height: 1.5625vw;
  margin: 2.0833333333333335vw 0 0.5208333333333334vw 0;
}

@media screen and (max-width: 1280px) {
  /* line 168, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left form .labTitle {
    font-size: 15px;
    line-height: 20px;
  }
}

/* line 180, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left form input[type=submit] {
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  clear: both;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: none;
  margin: 25px 0 0 0;
  outline: 0;
  transition: 0.4s;
}

/* line 201, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left form input[type=submit]:hover {
  background: #69e5f4;
  color: #fff;
}

@media screen and (max-width: 1280px) {
  /* line 180, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left form input[type=submit] {
    font-size: 14px;
    height: 20px;
  }
}

/* line 212, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left form ul li + li {
  margin: 0.6770833333333334vw 0 0 0;
}

@media screen and (max-width: 767px) {
  /* line 212, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left form ul li + li {
    margin: 10px 0 0 0;
  }
}

/* line 218, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left form ul li label {
  display: block;
  position: relative;
  padding-left: 1.9270833333333335vw;
  margin: 0;
  cursor: pointer;
  font-size: 0.9895833333333334vw;
  font-family: "robotoReg";
  line-height: 0.8333333333333334vw;
  user-select: none;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 218, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left form ul li label {
    font-size: 12px;
    line-height: 10px;
  }
}

@media screen and (max-width: 767px) {
  /* line 218, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left form ul li label {
    font-size: 14px;
    line-height: 12px;
    padding-left: 36px;
  }
}

/* line 238, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left form ul li label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* line 245, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left form ul li label input:checked ~ .checkmark {
  background-color: #69e5f4;
}

/* line 247, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left form ul li label input:checked ~ .checkmark:after {
  display: block;
}

/* line 253, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left form ul li label span {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.8333333333333334vw;
  width: 0.8333333333333334vw;
  background-color: #d7d7d7;
}

@media screen and (max-width: 767px) {
  /* line 253, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left form ul li label span {
    width: 16px;
    height: 16px;
  }
}

/* line 264, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left form ul li label span:after {
  display: none;
}

/* line 276, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left #promoSlide {
  background: #002131;
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  padding: 4.6875vw 0 4.166666666666667vw;
}

@media screen and (max-width: 767px) {
  /* line 276, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left #promoSlide {
    display: none;
    position: relative;
    left: 0 !important;
    width: 100% !important;
    margin: 30px 0 0 0;
    padding: 80px 0;
  }
}

/* line 291, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left #promoSlide #pSlide {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0 4.6875vw;
}

@media screen and (max-width: 767px) {
  /* line 291, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left #promoSlide #pSlide {
    padding: 0 60px;
  }
}

/* line 299, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left #promoSlide #pSlide .slick-arrow {
  display: none !important;
}

/* line 302, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left #promoSlide #pSlide .slick-dots {
  width: initial;
  left: 50%;
  transform: translate(-50%, 0);
}

/* line 306, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left #promoSlide #pSlide .slick-dots li {
  width: 0.8333333333333334vw;
  height: 0.8333333333333334vw;
  border: 1px solid #69e5f4;
  border-radius: 0.8333333333333334vw;
  cursor: pointer;
}

/* line 313, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left #promoSlide #pSlide .slick-dots li button:before {
  display: none;
}

@media screen and (max-width: 767px) {
  /* line 306, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left #promoSlide #pSlide .slick-dots li {
    width: 16px;
    height: 16px;
    border-radius: 16px;
  }
}

/* line 322, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left #promoSlide #pSlide .slick-dots li.slick-active {
  background: #69e5f4;
}

/* line 333, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left #promoSlide #pSlide .slick-list .slick-track .slick-slide .slideInt {
  text-align: center;
}

/* line 335, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left #promoSlide #pSlide .slick-list .slick-track .slick-slide .slideInt h3 {
  color: #fff;
  font-size: 2.135416666666667vw;
  font-family: "sabloReg";
  line-height: 2.135416666666667vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 767px) {
  /* line 335, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left #promoSlide #pSlide .slick-list .slick-track .slick-slide .slideInt h3 {
    font-size: 32px;
    line-height: 32px;
  }
}

/* line 346, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left #promoSlide #pSlide .slick-list .slick-track .slick-slide .slideInt h4 {
  color: #fff;
  font-size: 1.6666666666666667vw;
  font-family: "robotoReg";
  line-height: 1.7708333333333335vw;
  letter-spacing: 0.01em;
  margin: 0 0 3.6458333333333335vw 0;
}

@media screen and (max-width: 767px) {
  /* line 346, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left #promoSlide #pSlide .slick-list .slick-track .slick-slide .slideInt h4 {
    font-size: 28px;
    line-height: 28px;
    margin: 0 0 40px 0;
  }
}

/* line 358, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left #promoSlide #pSlide .slick-list .slick-track .slick-slide .slideInt h4:before {
  background: #fff;
  display: block;
  content: "";
  margin: 1.3020833333333335vw auto;
  width: 8.333333333333334vw;
  height: 1px;
}

@media screen and (max-width: 767px) {
  /* line 358, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left #promoSlide #pSlide .slick-list .slick-track .slick-slide .slideInt h4:before {
    margin: 25px auto;
  }
}

/* line 370, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #left #promoSlide #pSlide .slick-list .slick-track .slick-slide .slideInt span {
  color: #fff;
  font-size: 0.78125vw;
  font-family: "robotoItalic";
  line-height: 1.7708333333333335vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 767px) {
  /* line 370, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #left #promoSlide #pSlide .slick-list .slick-track .slick-slide .slideInt span {
    font-size: 11px;
    line-height: 11px;
  }
}

/* line 388, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right {
  float: left;
  width: calc(100% - 19.947916666666668vw);
  padding: 0 0 0 0.78125vw;
}

/* line 392, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #pSlide2 {
  position: relative;
  width: 100%;
  margin: 0;
  padding: 0 4.6875vw;
}

@media screen and (max-width: 767px) {
  /* line 392, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #pSlide2 {
    padding: 0 60px;
  }
}

/* line 400, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #pSlide2 .slick-arrow {
  display: none !important;
}

/* line 403, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #pSlide2 .slick-dots {
  width: initial;
  left: 50%;
  transform: translate(-50%, 0);
}

/* line 407, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #pSlide2 .slick-dots li {
  width: 0.8333333333333334vw;
  height: 0.8333333333333334vw;
  border: 1px solid #69e5f4;
  border-radius: 0.8333333333333334vw;
  cursor: pointer;
}

/* line 414, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #pSlide2 .slick-dots li button:before {
  display: none;
}

@media screen and (max-width: 767px) {
  /* line 407, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #pSlide2 .slick-dots li {
    width: 16px;
    height: 16px;
    border-radius: 16px;
  }
}

/* line 423, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #pSlide2 .slick-dots li.slick-active {
  background: #69e5f4;
}

/* line 434, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #pSlide2 .slick-list .slick-track .slick-slide .slideInt {
  text-align: center;
}

/* line 436, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #pSlide2 .slick-list .slick-track .slick-slide .slideInt h3 {
  color: #fff;
  font-size: 2.135416666666667vw;
  font-family: "sabloReg";
  line-height: 2.135416666666667vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 767px) {
  /* line 436, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #pSlide2 .slick-list .slick-track .slick-slide .slideInt h3 {
    font-size: 32px;
    line-height: 32px;
  }
}

/* line 447, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #pSlide2 .slick-list .slick-track .slick-slide .slideInt h4 {
  color: #fff;
  font-size: 1.6666666666666667vw;
  font-family: "robotoReg";
  line-height: 1.7708333333333335vw;
  letter-spacing: 0.01em;
  margin: 0 0 3.6458333333333335vw 0;
}

@media screen and (max-width: 767px) {
  /* line 447, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #pSlide2 .slick-list .slick-track .slick-slide .slideInt h4 {
    font-size: 28px;
    line-height: 28px;
    margin: 0 0 40px 0;
  }
}

/* line 459, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #pSlide2 .slick-list .slick-track .slick-slide .slideInt h4:before {
  background: #fff;
  display: block;
  content: "";
  margin: 1.3020833333333335vw auto;
  width: 8.333333333333334vw;
  height: 1px;
}

@media screen and (max-width: 767px) {
  /* line 459, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #pSlide2 .slick-list .slick-track .slick-slide .slideInt h4:before {
    margin: 25px auto;
  }
}

/* line 471, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #pSlide2 .slick-list .slick-track .slick-slide .slideInt span {
  color: #fff;
  font-size: 0.78125vw;
  font-family: "robotoItalic";
  line-height: 1.7708333333333335vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 767px) {
  /* line 471, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #pSlide2 .slick-list .slick-track .slick-slide .slideInt span {
    font-size: 11px;
    line-height: 11px;
  }
}

@media screen and (max-width: 991px) {
  /* line 388, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right {
    width: calc(100% - 25vw);
  }
}

@media screen and (max-width: 767px) {
  /* line 388, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right {
    width: 100%;
    float: initial;
    padding: 0;
  }
}

/* line 498, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #productsList {
  display: flex;
  flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
  /* line 498, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #productsList {
    padding: 60px 0;
  }
}

/* line 505, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #productsList:after {
  display: table;
  content: "";
  clear: both;
}

/* line 510, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #productsList #loadMore {
  display: block;
  clear: both;
  color: #69e5f4;
  font-size: 1.3020833333333335vw;
  font-family: "robotoMed";
  line-height: 1.0416666666666667vw;
  display: table;
  margin: 5.208333333333334vw auto 0;
  text-transform: uppercase;
  letter-spacing: 0.01em;
}

/* line 526, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #productsList #promoSlide2 {
  margin-bottom: 2vw;
  background: #002131;
  position: absolute;
  width: 100%;
  height: auto;
  bottom: 0;
  padding: 4.6875vw 0 4.166666666666667vw;
}

@media screen and (min-width: 767px) {
  /* line 526, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #productsList #promoSlide2 {
    display: none;
  }
}

@media screen and (max-width: 767px) {
  /* line 526, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #productsList #promoSlide2 {
    position: relative;
    left: 0 !important;
    width: 100% !important;
    margin: 30px 0;
    padding: 80px 0;
  }
}

/* line 549, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #productsList .prod-by-prod {
  width: 33.3333333333%;
  margin: 0 0 3.604166666666667vw;
  display: none;
}

/* line 553, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #productsList .prod-by-prod .prodInt {
  display: block;
  width: 100%;
  max-width: 19.947916666666668vw;
  position: relative;
}

/* line 558, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #productsList .prod-by-prod .prodInt .spasImg {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 19.947916666666668vw;
}

@media screen and (max-width: 991px) {
  /* line 558, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #productsList .prod-by-prod .prodInt .spasImg {
    height: 250px;
  }
}

@media screen and (max-width: 767px) {
  /* line 558, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #productsList .prod-by-prod .prodInt .spasImg {
    height: 200px;
  }
}

@media screen and (max-width: 420px) {
  /* line 558, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #productsList .prod-by-prod .prodInt .spasImg {
    height: 175px;
  }
}

/* line 574, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #productsList .prod-by-prod .prodInt .contenu {
  text-align: center;
  margin: 1.0416666666666667vw 0 0 0;
}

/* line 577, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #productsList .prod-by-prod .prodInt .contenu h5 {
  color: #002131;
  font-size: 0.9895833333333334vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 577, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #productsList .prod-by-prod .prodInt .contenu h5 {
    font-size: 14px;
  }
}

/* line 587, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #productsList .prod-by-prod .prodInt .contenu span {
  display: block;
}

/* line 589, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #productsList .prod-by-prod .prodInt .contenu span.year {
  color: #002131;
  font-size: 0.9895833333333334vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 589, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #productsList .prod-by-prod .prodInt .contenu span.year {
    font-size: 14px;
  }
}

/* line 598, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#productPageUsage #products #right #productsList .prod-by-prod .prodInt .contenu span.price {
  color: #69e5f4;
  font-size: 0.9895833333333334vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 598, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #productsList .prod-by-prod .prodInt .contenu span.price {
    font-size: 14px;
  }
}

@media screen and (max-width: 991px) {
  /* line 549, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #productsList .prod-by-prod {
    width: 50%;
  }
  /* line 612, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #productsList .prod-by-prod .prodInt {
    float: initial !important;
    margin: 0 auto !important;
    max-width: 90%;
  }
}

@media screen and (max-width: 580px) {
  /* line 549, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #productsList .prod-by-prod {
    width: 100%;
  }
  /* line 621, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #productPageUsage #products #right #productsList .prod-by-prod .prodInt {
    float: initial !important;
    margin: 0 auto !important;
    max-width: 90%;
  }
}

/* line 634, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy {
  background: #3990ae;
  position: relative;
  padding: 0 0 11.458333333333334vw 0;
}

/* line 638, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .goutte-bleu {
  position: absolute;
  top: calc(-15.46875vw - 19.53125vw);
  left: 0;
  z-index: -1;
}

/* line 644, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy:before {
  background-image: url(../images/montre-home.png);
  background-size: 100% auto;
  background-position: center top;
  background-repeat: no-repeat;
  display: table;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 26.3023vw;
  z-index: 1;
  margin: -10vw 0 0 0;
  float: left;
}

/* line 659, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy #garantie {
  position: relative;
  max-width: 57.03125vw;
  margin: 0 auto 10.78125vw;
  clear: both;
}

@media screen and (max-width: 580px) {
  /* line 659, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy #garantie {
    max-width: 100%;
    padding: 0 20px;
  }
}

/* line 668, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy #garantie h1 {
  position: absolute;
  width: 100%;
  max-width: 34.25vw;
  right: 0;
  color: #69e5f4;
  font-size: 6.770833333333334vw;
  font-family: "robotoBlack";
  line-height: 6.770833333333334vw;
  top: 0;
  letter-spacing: 0.01em;
  text-align: right;
  text-transform: uppercase;
}

@media screen and (max-width: 991px) {
  /* line 668, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy #garantie h1 {
    opacity: .25;
    font-size: 86px;
    line-height: 86px;
    max-width: 400px;
    width: 34vw;
    top: -2vw;
    right: -8vw;
    text-align: right;
    font-size: 7vw !important;
    opacity: 0.25;
    line-height: 86px;
    max-width: 400px;
  }
}

@media screen and (max-width: 580px) {
  /* line 668, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy #garantie h1 {
    font-size: 56px;
    line-height: 56px;
    max-width: 325px;
    right: 20px;
  }
}

/* line 704, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy #garantie p {
  padding: 7.291666666666667vw 0 0 0;
  max-width: 34.28125vw;
  color: #fff;
  font-size: 1.3541666666666667vw;
  font-family: "sabloReg";
  line-height: 2.03125vw;
  letter-spacing: 0.01em;
  text-align: right;
}

@media screen and (max-width: 991px) {
  /* line 704, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy #garantie p {
    max-width: 75%;
    font-size: 17px;
    line-height: 26px;
  }
}

/* line 723, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .corpsSide {
  width: 100%;
  max-width: calc((100% - 8.958333333333334vw) + 2.447916666666667vw);
  float: right;
}

/* line 727, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .corpsSide .circle-d {
  position: absolute;
  right: 10.9375vw;
  top: calc(-5.5vw);
  transform: rotate(90deg);
}

/* line 733, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .corpsSide #oldBuy {
  background: #fff;
  position: relative;
  width: 100%;
  height: 38.020833333333336vw;
}

@media screen and (max-width: 1280px) {
  /* line 733, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy {
    height: auto;
    padding: 80px;
  }
}

/* line 743, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .corpsSide #oldBuy .contenu {
  position: absolute;
  top: 50%;
  left: 4.166666666666667vw;
  width: 36.51041666666667vw;
  transform: translate(0, -50%);
}

@media screen and (max-width: 1280px) {
  /* line 743, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy .contenu {
    position: relative;
    top: initial;
    left: initial;
    transform: initial;
    width: 60%;
  }
}

@media screen and (max-width: 991px) {
  /* line 743, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy .contenu {
    width: 80%;
  }
}

@media screen and (max-width: 850px) {
  /* line 743, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy .contenu {
    width: 100%;
  }
}

/* line 762, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .corpsSide #oldBuy .contenu h2 {
  color: #002131;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  line-height: 4.0625vw;
  max-width: 26.5625vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 762, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy .contenu h2 {
    font-size: 52px;
    line-height: 52px;
    max-width: 70%;
  }
}

@media screen and (max-width: 850px) {
  /* line 762, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy .contenu h2 {
    max-width: 80%;
  }
}

@media screen and (max-width: 767px) {
  /* line 762, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy .contenu h2 {
    max-width: 90%;
  }
}

@media screen and (max-width: 580px) {
  /* line 762, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy .contenu h2 {
    max-width: 100%;
  }
}

/* line 784, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .corpsSide #oldBuy .contenu p {
  color: #002131;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
  margin: 3.125vw 0 3.6458333333333335vw 0;
}

@media screen and (max-width: 1280px) {
  /* line 784, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy .contenu p {
    font-size: 13px;
    line-height: 20px;
  }
}

@media screen and (max-width: 580px) {
  /* line 784, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy .contenu p {
    margin: 30px 0;
  }
}

/* line 799, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .corpsSide #oldBuy .contenu span {
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  clear: both;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
  cursor: pointer;
}

@media screen and (max-width: 1280px) {
  /* line 799, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy .contenu span {
    font-size: 14px;
    height: 20px;
  }
}

/* line 818, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .corpsSide #oldBuy .contenu span:before {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 0px;
  height: 0px;
  transition-property: width, height;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0.3s;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}

/* line 833, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .corpsSide #oldBuy .contenu span:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 833, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy .contenu span:after {
    height: 0.20833333333333334vw;
  }
}

/* line 847, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .corpsSide #oldBuy .contenu span:hover {
  color: #fff;
}

/* line 849, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .corpsSide #oldBuy .contenu span:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

/* line 853, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .corpsSide #oldBuy .contenu span:hover:after {
  width: 0;
}

/* line 859, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
#garantieOldbuy .corpsSide #oldBuy #oldimg {
  background-image: url(../images/oldspas.png);
  background-size: auto 100%;
  background-position: left center;
  background-repeat: no-repeat;
  position: absolute;
  width: 46.51041666666667vw;
  height: 37.76041666666667vw;
  right: 0;
  bottom: -7.291666666666667vw;
}

@media screen and (max-width: 1280px) {
  /* line 859, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy #oldimg {
    right: -100px;
  }
}

@media screen and (max-width: 991px) {
  /* line 859, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy #oldimg {
    right: -300px;
    bottom: 50%;
    transform: translate(0, 50%);
  }
}

@media screen and (max-width: 850px) {
  /* line 859, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-product.scss */
  #garantieOldbuy .corpsSide #oldBuy #oldimg {
    display: none;
  }
}

/* line 1, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#introServ {
  padding: 7.916666666666667vw 0 18.697916666666668vw 0;
  min-height: 58.07291666666667vw;
}

/* line 4, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#introServ .vague-rond {
  position: absolute;
  top: 4.166666666666667vw;
  right: calc(45.520833333333336vw - (15.15625vw / 2));
  z-index: 2;
}

/* line 10, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#introServ .circle-d {
  position: absolute;
  left: 4.84375vw;
  bottom: 8.072916666666668vw;
}

/* line 15, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#introServ:after {
  background: #3990ae;
  position: absolute;
  display: block;
  content: "";
  width: 45.520833333333336vw;
  height: 100%;
  top: 0;
  right: 0;
}

/* line 25, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#introServ .contenu {
  padding: 0 0 0 4.84375vw;
  max-width: 34.375vw;
}

/* line 28, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#introServ .contenu h1 {
  max-width: 25.572916666666668vw;
  color: #002131;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  line-height: 4.0625vw;
  margin: 0 0 2.8645833333333335vw 0;
  letter-spacing: 0.01em;
}

/* line 37, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#introServ .contenu p {
  color: #002131;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
}

/* line 43, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#introServ .contenu p + p {
  margin: 1.0416666666666667vw 0 0 0;
}

/* line 49, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#introServ #introImg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 53.75vw;
  height: 33.38541666666667vw;
  right: 0;
  bottom: 9.895833333333334vw;
  z-index: 1;
}

/* line 62, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#buyBroken {
  background-color: #002131;
  background-image: url(../images/glace-broken.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center bottom;
  height: 39.79166666666667vw;
}

/* line 69, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#buyBroken .goutte-w {
  position: absolute;
  right: 4.84375vw;
  top: calc(-15.46875vw + 1.5104166666666667vw);
  z-index: 3;
}

/* line 75, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#buyBroken .contenu {
  position: relative;
  padding: 7.03125vw 0 0 0;
}

/* line 79, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#buyBroken .contenu h1 {
  color: #fff;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  line-height: 4.0625vw;
  letter-spacing: 0.01em;
  float: left;
  width: calc(100% - 45.520833333333336vw);
  padding: 0 0 0 8.854166666666668vw;
}

/* line 89, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#buyBroken .contenu #right {
  float: left;
  width: 100%;
  max-width: 45.520833333333336vw;
}

/* line 93, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#buyBroken .contenu #right p {
  max-width: 36.19791666666667vw;
  color: #f4f4f4;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
}

/* line 100, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#buyBroken .contenu #right p + p {
  margin: 1.0416666666666667vw 0 0 0;
}

/* line 104, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#buyBroken .contenu #right span {
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  clear: both;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
  cursor: pointer;
  margin: 1.8229166666666667vw 0 0 0;
}

@media screen and (max-width: 1280px) {
  /* line 104, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #buyBroken .contenu #right span {
    font-size: 14px;
    height: 20px;
  }
}

/* line 145, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#buyBroken .contenu #right span:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 145, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #buyBroken .contenu #right span:after {
    height: 0.20833333333333334vw;
  }
}

@media screen and (max-width: 1280px) {
  /* line 145, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #buyBroken .contenu #right span:after {
    bottom: -7px;
  }
}

/* line 163, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#buyBroken .contenu #right span:hover {
  color: #fff;
}

/* line 165, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#buyBroken .contenu #right span:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

@media screen and (max-width: 1280px) {
  /* line 165, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #buyBroken .contenu #right span:hover:before {
    width: 179px;
    height: 36px;
  }
}

/* line 173, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#buyBroken .contenu #right span:hover:after {
  width: 0;
}

/* line 182, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#conseil {
  position: relative;
  padding: 9.895833333333334vw 0 15.416666666666668vw 0;
  z-index: 2;
}

@media all and (max-width: 992px) {
  /* line 182, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #conseil {
    padding: 0 0 0 !important;
    margin-bottom: 10vw;
  }
}

/* line 190, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#conseil #conseilImg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: -2.8125vw;
  left: 0;
  width: 49.63541666666667vw;
  height: calc(100% + 2.8125vw);
  z-index: 2;
}

/* line 201, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#conseil #conseilImg .vague-bleu {
  position: absolute;
  right: -4.0625vw;
  bottom: 8.333333333333334vw;
  width: 15.104166666666668vw;
  height: 9.479166666666668vw;
}

/* line 209, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#conseil #right {
  float: right;
  width: 100%;
  max-width: calc(100% - 49.63541666666667vw);
}

/* line 213, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#conseil #right .contenu {
  max-width: calc(100% - (9.010416666666668vw * 2));
  margin: 0 auto;
}

@media all and (max-width: 992px) {
  /* line 213, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #conseil #right .contenu {
    max-width: 90%;
  }
}

/* line 221, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#conseil #right .contenu h1 {
  color: #002131;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  line-height: 4.0625vw;
  letter-spacing: 0.01em;
  float: right;
  clear: both;
  margin: 0 0 3.385416666666667vw 0;
}

/* line 232, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#conseil #right .contenu p {
  max-width: 36.19791666666667vw;
  color: #002131;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
  clear: both;
}

/* line 240, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#conseil #right .contenu p + p {
  margin: 1.0416666666666667vw 0 0 0;
}

/* line 249, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService {
  background: #3990ae;
  position: relative;
  padding: 14.614583vw 0 0;
  margin-top: -5vw;
}

/* line 254, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .circle-d {
  position: absolute;
  top: 5.052083333333334vw;
  right: 4.84375vw;
}

/* line 259, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService:before {
  background-image: url(../images/vague-bg.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center top;
  position: absolute;
  display: block;
  content: "";
  top: -13vw;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 272, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap {
  width: 100%;
  max-width: 90.98958333333334vw;
  float: right;
  position: relative;
}

/* line 277, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servListContent {
  width: calc(100% - 20.677083333333336vw);
  float: left;
  left: 20.677083333333336vw;
}

/* line 286, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide {
  background: #fff;
  padding: 7.447916666666667vw 0 6.927083333333334vw;
  outline: 0 !important;
  min-height: 41vw;
}

@media screen and (max-width: 991px) {
  /* line 286, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #aboutService .wrap #servListContent .slick-list .slick-track .slick-slide {
    padding-top: 2vw;
    min-height: inherit;
  }
}

/* line 295, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemTitle {
  position: absolute;
  top: 50%;
  left: 79px;
  transform: translate(0, -50%);
  width: 100%;
}

/* line 303, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemTitle span {
  color: #69e5f4;
  font-size: 6.770833333333334vw;
  font-family: "robotoBlack";
  line-height: 6.770833333333334vw;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  word-wrap: break-word;
  display: block;
  width: 100%;
  opacity: 0.25;
}

/* line 316, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt {
  position: relative;
  max-width: 40.57291666666667vw;
  margin: 0 0 0 16.458333333333336vw;
  z-index: 1;
}

/* line 322, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt h1 {
  color: #002131;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  line-height: 4.0625vw;
  margin: 0 0 60px 0;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 991px) {
  /* line 322, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt h1 {
    margin-bottom: 20px;
  }
}

/* line 333, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt p {
  color: #002131;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
}

/* line 339, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt p + p {
  margin: 1.0416666666666667vw 0 0 0;
}

/* line 343, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt a {
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  clear: both;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
  cursor: pointer;
  margin: 1.8229166666666667vw 0 0 0;
}

@media screen and (max-width: 1280px) {
  /* line 343, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt a {
    font-size: 14px;
    height: 20px;
  }
}

/* line 384, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt a:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 384, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt a:after {
    height: 0.20833333333333334vw;
  }
}

@media screen and (max-width: 1280px) {
  /* line 384, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt a:after {
    bottom: -7px;
  }
}

/* line 404, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt a:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

@media screen and (max-width: 1280px) {
  /* line 404, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt a:hover:before {
    width: 179px;
    height: 36px;
  }
}

/* line 412, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt a:hover:after {
  width: 0;
}

/* line 422, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servList {
  background: transparent;
  width: calc(20.677083333333336vw + 3.385416666666667vw);
  position: absolute;
  float: left;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

/* line 430, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servList .slick-arrow {
  display: none !important;
}

/* line 435, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servList .slick-list .slick-track .slick-slide {
  background: #002131;
  outline: 0 !important;
  border: none !important;
  z-index: 999 !important;
  width: calc(100% - 3.385416666666667vw) !important;
  transition-property: width;
  transition-duration: 0.4s;
}

/* line 443, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servList .slick-list .slick-track .slick-slide span {
  font-family: sabloReg;
  background: transparent;
  display: block;
  width: 100%;
  padding: 2.2395833333333335vw 0 2.2395833333333335vw 2.3958333333333335vw;
  transition-property: background, width, color;
  transition-duration: 0.4s, 0.4s, 0.4s;
  line-height: 100%;
  font-size: 1.5625vw;
  letter-spacing: 0.01em;
  color: #3990ae;
  cursor: pointer;
}

/* line 457, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servList .slick-list .slick-track .slick-slide.slick-current {
  width: calc(100%) !important;
}

/* line 459, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#aboutService .wrap #servList .slick-list .slick-track .slick-slide.slick-current span {
  background: #69e5f4;
  color: #fff;
}

/* line 471, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#financement {
  background: #3990ae;
  padding: 15vw 0 13.28125vw 0;
}

/* line 474, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#financement .goutte {
  position: absolute;
  top: -1.5104166666666667vw;
  left: -.78125vw;
}

/* line 479, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#financement .vague-rond-bleu {
  position: absolute;
  right: 15.677083333333334vw;
  bottom: 4.635416666666667vw;
}

/* line 484, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#financement .contenu {
  position: relative;
  max-width: 57.395833333333336vw;
  margin: 0 0 0 12.96875vw;
}

/* line 488, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#financement .contenu h1 {
  max-width: 47.708333333333336vw;
  color: #69e5f4;
  opacity: 0.25;
  font-size: 6.770833333333334vw;
  font-family: "robotoBlack";
  line-height: 6.770833333333334vw;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

/* line 498, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#financement .contenu p {
  max-width: 49.79166666666667vw;
  position: absolute;
  color: #fff;
  font-size: 1.3541666666666667vw;
  line-height: 2.03125vw;
  font-family: "sabloReg";
  letter-spacing: 0.01em;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  z-index: 1;
  text-align: right;
}

/* line 512, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#financement .contenu img {
  position: absolute;
  display: block;
  width: 15.78125vw;
  height: auto;
  top: 50%;
  right: -20.416666666666668vw;
  transform: translate(0, -50%);
}

/* line 524, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#urgenceServ {
  background: #3990ae;
  padding: 0 0 0 0;
}

/* line 527, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#urgenceServ .corpsWrap {
  width: 100%;
  max-width: calc(100% - (9.010416666666668vw));
  margin: 0 auto;
  height: 100%;
  float: right;
}

/* line 533, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#urgenceServ .corpsWrap .contenu {
  position: relative;
  height: 35.78125vw;
}

@media screen and (max-width: 1280px) {
  /* line 533, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #urgenceServ .corpsWrap .contenu {
    height: auto;
  }
}

/* line 539, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#urgenceServ .corpsWrap .contenu #left {
  background: #fff;
  width: 65.36458333333334vw;
  height: calc(100% - 1.8229166666666667vw);
}

@media screen and (max-width: 1280px) {
  /* line 539, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #urgenceServ .corpsWrap .contenu #left {
    height: auto;
    padding: 60px 0;
    padding-bottom: 28vw;
  }
}

@media screen and (max-width: 1200px) {
  /* line 539, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #urgenceServ .corpsWrap .contenu #left {
    width: 80%;
  }
}

@media screen and (max-width: 992px) {
  /* line 539, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #urgenceServ .corpsWrap .contenu #left {
    width: 100%;
    padding-bottom: 39vw;
  }
}

@media screen and (max-width: 767px) {
  /* line 539, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #urgenceServ .corpsWrap .contenu #left {
    width: 100%;
  }
}

/* line 563, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#urgenceServ .corpsWrap .contenu #left .leftInt {
  position: absolute;
  top: 50%;
  left: 4.114583333333334vw;
  transform: translate(0, -50%);
  max-width: 34.375vw;
}

@media screen and (max-width: 1280px) {
  /* line 563, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #urgenceServ .corpsWrap .contenu #left .leftInt {
    position: relative;
    top: initial;
    transform: initial;
  }
}

@media screen and (max-width: 1200px) {
  /* line 563, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #urgenceServ .corpsWrap .contenu #left .leftInt {
    max-width: 70%;
  }
}

@media screen and (max-width: 767px) {
  /* line 563, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #urgenceServ .corpsWrap .contenu #left .leftInt {
    max-width: 80%;
  }
}

/* line 580, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#urgenceServ .corpsWrap .contenu #left .leftInt h3 {
  color: #0b374a;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  letter-spacing: 0.01em;
  line-height: 4.0625vw;
}

@media screen and (max-width: 1280px) {
  /* line 580, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #urgenceServ .corpsWrap .contenu #left .leftInt h3 {
    font-size: 52px;
    line-height: 52px;
  }
}

/* line 591, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#urgenceServ .corpsWrap .contenu #left .leftInt p {
  color: #0b374a;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
  line-height: 1.3541666666666667vw;
  margin: 3.4375vw 0 4.583333333333334vw 0;
}

@media screen and (max-width: 1280px) {
  /* line 591, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #urgenceServ .corpsWrap .contenu #left .leftInt p {
    font-size: 15px;
    line-height: 24px;
    margin: 40px 0 60px 0;
  }
}

/* line 604, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#urgenceServ .corpsWrap .contenu #left .leftInt a {
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  clear: both;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
}

@media screen and (max-width: 1280px) {
  /* line 604, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #urgenceServ .corpsWrap .contenu #left .leftInt a {
    font-size: 14px;
    height: 20px;
  }
}

/* line 622, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#urgenceServ .corpsWrap .contenu #left .leftInt a:before {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 0px;
  height: 0px;
  transition-property: width, height;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0.3s;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}

/* line 637, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#urgenceServ .corpsWrap .contenu #left .leftInt a:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 637, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #urgenceServ .corpsWrap .contenu #left .leftInt a:after {
    height: 0.20833333333333334vw;
  }
}

/* line 652, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#urgenceServ .corpsWrap .contenu #left .leftInt a:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

/* line 656, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#urgenceServ .corpsWrap .contenu #left .leftInt a:hover:after {
  width: 0;
}

/* line 663, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#urgenceServ .corpsWrap .contenu #right {
  background-image: url(../images/camion.png);
  background-size: auto 100%;
  background-position: left center;
  background-repeat: no-repeat;
  position: absolute;
  width: 49.739583333333336vw;
  height: 34.85416666666667vw;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 767px) {
  /* line 663, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
  #urgenceServ .corpsWrap .contenu #right {
    display: none;
  }
}

/* line 684, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#necessaire {
  background: #3990ae;
  padding: 15.625vw 0 5.989583333333334vw 0;
}

/* line 687, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#necessaire .vague {
  position: absolute;
  top: 3.385416666666667vw;
  left: 9.010416666666668vw;
}

/* line 692, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#necessaire .wrap {
  text-align: center;
  max-width: 60.677083333333336vw;
  margin: 0 auto;
}

/* line 696, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#necessaire .wrap h1 {
  color: #fff;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  line-height: 4.0625vw;
  letter-spacing: 0.01em;
  max-width: 47.395833333333336vw;
  margin: 0 auto 3.59375vw;
}

/* line 705, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#necessaire .wrap p {
  color: #fff;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
}

/* line 715, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#distributeurs {
  padding: 3.125vw 0 3.385416666666667vw 0;
}

/* line 717, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#distributeurs .contenu {
  margin: 0 auto;
  max-width: 42.91666666666667vw;
}

/* line 721, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#distributeurs .contenu .secTitle {
  position: relative;
  display: table;
  margin: 0 auto 2.0833333333333335vw;
  width: 100%;
  text-align: center;
}

/* line 727, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#distributeurs .contenu .secTitle:before {
  background: #002131;
  position: absolute;
  display: block;
  content: "";
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
  transform: translate(0, -50%);
}

/* line 738, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#distributeurs .contenu .secTitle span {
  background: #fff;
  position: relative;
  color: #002131;
  z-index: 2;
  line-height: 100%;
  font-size: 0.9895833333333334vw;
  font-family: "robotoBold";
  text-transform: uppercase;
  padding: 0 0.625vw;
}

/* line 750, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#distributeurs .contenu #listDistributeurs {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

/* line 756, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#distributeurs .contenu #listDistributeurs .dist-by-dist {
  width: 30%;
  float: left;
}

/* line 759, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#distributeurs .contenu #listDistributeurs .dist-by-dist img {
  display: block;
  max-width: 90%;
}

/* line 767, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#distributeurs .contenu #listDistributeurs .dist-by-dist:nth-child(2) img {
  margin: 0 auto;
}

/* line 772, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-services.scss */
#distributeurs .contenu #listDistributeurs .dist-by-dist:nth-child(3) img {
  float: right;
}

/* line 1, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#introContact {
  height: 40.88541666666667vw;
}

/* line 3, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#introContact #left,
#introContact #right {
  width: 50%;
  height: 100%;
  float: left;
}

/* line 10, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#introContact #left #map {
  width: 100%;
  height: 100%;
}

/* line 15, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#introContact #right {
  overflow: hidden;
  background-size: cover;
}

/* line 18, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#introContact #right video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* line 27, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact {
  background-image: url(../images/bg-contact.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center center;
  padding: 7.395833333333334vw 0 6.197916666666667vw 0;
}

/* line 33, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .circle-d {
  position: absolute;
  top: 3.9583333333333335vw;
  right: 13.177083333333334vw;
}

/* line 38, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap {
  position: relative;
  margin: 0 auto;
  max-width: 81.66666666666667vw;
}

/* line 42, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .vague-rond {
  position: absolute;
  left: 0;
  bottom: 15.052083333333334vw;
}

/* line 47, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap h1 {
  color: #69e5f4;
  font-size: 6.770833333333334vw;
  font-family: "robotoBlack";
  line-height: 100%;
  letter-spacing: 0.01em;
  margin: 0 0 2.0833333333333335vw 0;
  opacity: 0.25;
  text-transform: uppercase;
}

/* line 57, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu {
  background: #002131;
  position: relative;
  padding: 4.791666666666667vw 0 5.104166666666667vw;
}

/* line 61, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #left {
  width: 24.739583333333336vw;
  padding: 0 5.208333333333334vw 0 4.0625vw;
}

/* line 64, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #left h4 {
  color: #69e5f4;
  font-size: 1.5625vw;
  font-family: "sabloReg";
  line-height: 100%;
  letter-spacing: 0.01em;
  margin: 0 0 1.5625vw 0;
}

/* line 72, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #left p {
  color: #fff;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.09375vw;
  letter-spacing: 0.01em;
}

/* line 79, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #left #coords {
  margin: 25px 0 0 0;
}

/* line 81, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #left #coords a {
  display: block;
  color: #fff;
}

/* line 85, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #left #coords span {
  display: block;
  color: #fff;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.09375vw;
  letter-spacing: 0.01em;
}

/* line 95, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #left #coords #social h5 {
  display: block;
  color: #69e5f4;
  font-size: 0.8854166666666667vw;
  font-family: "robotoMed";
  line-height: 1.09375vw;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin: 1.3020833333333335vw 0 0 0;
}

/* line 105, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #left #coords #social ul {
  display: flex;
  margin: 0.625vw 0 0 0;
}

/* line 108, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #left #coords #social ul li {
  position: relative;
  border: 2px solid #fff;
  width: 1.5104166666666667vw;
  height: 1.5104166666666667vw;
  border-radius: 1.5104166666666667vw;
}

/* line 114, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #left #coords #social ul li + li {
  margin: 0 0 0 0.5208333333333334vw;
}

/* line 117, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #left #coords #social ul li a {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  color: #fff;
}

/* line 123, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #left #coords #social ul li a i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* line 135, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right {
  background: #3990ae;
  position: absolute;
  top: 50%;
  right: 3.8020833333333335vw;
  transform: translate(0, -50%);
  width: calc(100% - 24.739583333333336vw - 3.8020833333333335vw);
}

/* line 142, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt {
  padding: 5.260416666666667vw 4.479166666666667vw 4.322916666666667vw;
}

/* line 147, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form:after {
  display: table;
  content: "";
  clear: both;
}

/* line 152, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .validation_error {
  color: #ff0000;
  margin: 0 0 1.5625vw 0;
}

/* line 157, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

/* line 161, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li {
  width: 100%;
  clear: both;
  padding: 0;
  margin: 0;
}

/* line 166, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li + li {
  margin: 1.8229166666666667vw 0 0 0;
}

/* line 172, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li.gfield_error .ginput_container input,
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li.gfield_error .ginput_container select,
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li.gfield_error .ginput_container textarea {
  border-bottom: 1px solid #ff0000 !important;
}

/* line 179, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li label {
  color: #69e5f4;
  font-size: 1.3541666666666667vw;
  font-family: "sabloReg";
  line-height: 100%;
  letter-spacing: 0.01em;
  margin: 0 0 4px 0;
}

/* line 186, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li label .gfield_required {
  display: none;
}

/* line 190, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container {
  width: 100%;
}

/* line 192, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container .validation_message {
  display: none;
}

/* line 195, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container input,
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container select,
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container textarea {
  background: transparent;
  width: 100%;
  border: none;
  outline: 0;
  border-bottom: 1px solid #fff;
  padding: 0.78125vw 0;
  color: #fff;
  font-size: 1.5625vw;
  font-family: "robotoReg";
  line-height: 100%;
  letter-spacing: 0.01em;
}

/* line 210, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container input::-webkit-input-placeholder,
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container select::-webkit-input-placeholder,
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
}

/* line 213, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container input::-moz-placeholder,
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container select::-moz-placeholder,
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}

/* line 216, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container input:-ms-input-placeholder,
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container select:-ms-input-placeholder,
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container textarea:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
}

/* line 219, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container input:-moz-placeholder,
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container select:-moz-placeholder,
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}

/* line 227, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container select option {
  color: #000;
}

/* line 238, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_footer {
  display: table;
  float: right;
  position: relative;
}

/* line 242, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_footer:after {
  display: block;
  content: "";
  background: #69e5f4;
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0px;
}

/* line 251, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_footer input {
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  clear: both;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: none;
  margin: 15px 0 0 0;
  outline: 0;
  transition: 0.4s;
  padding: 10px;
}

/* line 272, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_footer input:hover {
  background: #69e5f4;
  color: #fff;
}

/* line 284, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact {
  margin: 12.5vw 0 0 0;
}

/* line 286, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .goutte {
  position: absolute;
  left: -.78125vw;
  bottom: 3.3333333333333335vw;
  z-index: 9;
}

/* line 292, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .corpsWrap {
  width: 100%;
  max-width: calc(100% - (9.010416666666668vw));
  margin: 0 auto;
  height: 100%;
  float: right;
}

/* line 298, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .corpsWrap .contenu {
  position: relative;
  height: 35.78125vw;
}

@media screen and (max-width: 1280px) {
  /* line 298, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu {
    height: auto;
  }
}

/* line 304, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .corpsWrap .contenu #left {
  background: #fff;
  width: 65.36458333333334vw;
  height: calc(100% - 1.8229166666666667vw);
}

@media screen and (max-width: 1280px) {
  /* line 304, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu #left {
    height: 620px;
    padding: 60px 0;
  }
}

@media screen and (max-width: 1200px) {
  /* line 304, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu #left {
    width: 80%;
  }
}

@media screen and (max-width: 767px) {
  /* line 304, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu #left {
    width: 100%;
  }
}

@media screen and (max-width: 580px) {
  /* line 304, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu #left {
    height: 720px;
  }
}

/* line 324, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .corpsWrap .contenu #left .leftInt {
  position: absolute;
  top: 50%;
  left: 4.114583333333334vw;
  transform: translate(0, -50%);
  max-width: 34.375vw;
}

@media screen and (max-width: 1280px) {
  /* line 324, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu #left .leftInt {
    position: relative;
    top: initial;
    transform: initial;
  }
}

@media screen and (max-width: 1200px) {
  /* line 324, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu #left .leftInt {
    max-width: 70%;
  }
}

@media screen and (max-width: 767px) {
  /* line 324, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu #left .leftInt {
    max-width: 80%;
  }
}

/* line 341, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .corpsWrap .contenu #left .leftInt h3 {
  color: #0b374a;
  font-size: 4.0625vw;
  font-family: "sabloReg";
  letter-spacing: 0.01em;
  line-height: 4.0625vw;
}

@media screen and (max-width: 1280px) {
  /* line 341, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu #left .leftInt h3 {
    font-size: 52px;
    line-height: 52px;
  }
}

/* line 352, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .corpsWrap .contenu #left .leftInt p {
  color: #0b374a;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
  line-height: 1.3541666666666667vw;
  margin: 3.4375vw 0 4.583333333333334vw 0;
}

@media screen and (max-width: 1280px) {
  /* line 352, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu #left .leftInt p {
    font-size: 15px;
    line-height: 24px;
    margin: 40px 0 60px 0;
  }
}

/* line 365, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .corpsWrap .contenu #left .leftInt a {
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  clear: both;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
}

@media screen and (max-width: 1280px) {
  /* line 365, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu #left .leftInt a {
    font-size: 14px;
    height: 20px;
  }
}

/* line 383, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .corpsWrap .contenu #left .leftInt a:before {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 0px;
  height: 0px;
  transition-property: width, height;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0.3s;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}

/* line 398, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .corpsWrap .contenu #left .leftInt a:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 398, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu #left .leftInt a:after {
    height: 0.20833333333333334vw;
  }
}

/* line 412, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .corpsWrap .contenu #left .leftInt a:hover {
  color: #fff;
}

/* line 414, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .corpsWrap .contenu #left .leftInt a:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

/* line 418, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .corpsWrap .contenu #left .leftInt a:hover:after {
  width: 0;
}

/* line 425, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
#formContact #urgenceContact .corpsWrap .contenu #right {
  background-image: url(../images/camion.png);
  background-size: auto 100%;
  background-position: left center;
  background-repeat: no-repeat;
  position: absolute;
  width: 49.739583333333336vw;
  height: 33.85416666666667vw;
  right: 0;
  bottom: 0;
}

@media screen and (max-width: 767px) {
  /* line 425, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu #right {
    display: none;
    width: 50vw;
    height: 50vw;
  }
}

@media screen and (max-width: 580px) {
  /* line 425, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-contact.scss */
  #formContact #urgenceContact .corpsWrap .contenu #right {
    display: none;
    width: 60vw;
    height: 60vw;
  }
}

/* line 1, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#vagueTopi {
  background-image: url(../images/eau-entreprise.png);
  background-position: left top;
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 57.29166666666667vw;
  animation-name: imgmoveS;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

/* line 17, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#introEntreprise {
  z-index: 2;
}

/* line 19, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#introEntreprise .vague-rond-bleu {
  position: absolute;
  top: 21.5625vw;
  right: 0;
}

/* line 24, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#introEntreprise #left {
  background: #3990ae;
  width: 100%;
  max-width: 49.6875vw;
  padding: 6.510416666666667vw 4.010416666666667vw 7.03125vw 9.0625vw;
}

/* line 29, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#introEntreprise #left h1 {
  margin: 0 0 3.125vw 0;
  color: #fff;
  font-size: 4.0625vw;
  line-height: 4.0625vw;
  font-family: "sabloReg";
  letter-spacing: 0.01em;
}

/* line 37, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#introEntreprise #left p {
  color: #fff;
  font-size: 0.8854166666666667vw;
  line-height: 1.3541666666666667vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
}

/* line 43, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#introEntreprise #left p + p {
  margin: 1.0416666666666667vw 0 0 0;
}

/* line 52, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#expert #blocExpert {
  position: relative;
  width: 48.85416666666667vw;
  height: 38.4375vw;
  float: right;
  top: -10vw;
  right: 9.0625vw;
}

/* line 59, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#expert #blocExpert:before {
  background: transparent;
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  border-top: 0.9375vw solid #69e5f4;
  border-left: 0.9375vw solid #69e5f4;
}

/* line 72, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#expert #blocExpert:after {
  background: transparent;
  position: absolute;
  display: block;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 3;
  border-right: 0.9375vw solid #69e5f4;
  border-bottom: 0.9375vw solid #69e5f4;
}

/* line 85, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#expert #blocExpert .blocInt {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  text-align: center;
  transform: translate(0, -50%);
  z-index: 4;
}

/* line 93, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#expert #blocExpert .blocInt span {
  display: block;
  color: #69e5f4;
  text-transform: initial;
}

/* line 97, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#expert #blocExpert .blocInt span:nth-child(1) {
  font-size: 6.09375vw;
  font-family: "robotoBlack";
  line-height: 6.302083333333334vw;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

/* line 104, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#expert #blocExpert .blocInt span:nth-child(2) {
  font-size: 4.166666666666667vw;
  font-family: "sabloReg";
  line-height: 4.635416666666667vw;
  letter-spacing: 0.01em;
}

/* line 110, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#expert #blocExpert .blocInt span:nth-child(3) {
  font-size: 5.416666666666667vw;
  font-family: "robotoBlack";
  line-height: 5.46875vw;
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

/* line 119, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#expert #blocExpert #imgWithBloc {
  background-image: url(../images/eau-expert.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  position: absolute;
  top: 50%;
  right: -9.0625vw;
  width: 49.739583333333336vw;
  height: 56.35416666666667vw;
  transform: translate(0, -50%);
  z-index: 2;
  animation-name: imgmoveS;
  animation-duration: 5s;
  animation-iteration-count: infinite;
}

/* line 138, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#salleMontre {
  position: relative;
  margin: -43.489583333333336vw 0 0 0;
  clear: initial;
  z-index: 2;
}

/* line 143, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#salleMontre #salleImg {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  backgorund-position: 100% 100%;
  position: relative;
  width: 57.708333333333336vw;
  height: 66.875vw;
}

/* line 152, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#salleMontre #contenu {
  background: #002131;
  position: relative;
  padding: 8.541666666666668vw 9.114583333333334vw 9.895833333333334vw 8.333333333333334vw;
  float: right;
  margin: -23.75vw 0 0 0;
  z-index: 3;
  max-width: 74.47916666666667vw;
}

/* line 160, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#salleMontre #contenu .vague {
  position: absolute;
  left: -11.979166666666668vw;
  bottom: 4.114583333333334vw;
}

/* line 166, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#salleMontre #contenu #contenuInt h1 {
  color: #fff;
  font-size: 4.0625vw;
  line-height: 4.0625vw;
  letter-spacing: 0.01em;
  font-family: "sabloReg";
  margin: 0 0 3.385416666666667vw;
}

/* line 174, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#salleMontre #contenu #contenuInt p {
  color: #fff;
  font-size: 0.8854166666666667vw;
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
  font-family: "robotoReg";
  word-break: break-all;
}

/* line 187, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#inventaire {
  padding: 8.75vw 0 16.145833333333336vw 0;
}

/* line 189, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#inventaire .circle-d-f {
  position: absolute;
  right: 13.020833333333334vw;
  bottom: 10.78125vw;
}

/* line 194, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#inventaire #wrap {
  position: relative;
  max-width: 75.3125vw;
  margin: 0 auto;
}

/* line 198, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#inventaire #wrap:after {
  display: table;
  content: "";
  clear: both;
}

/* line 203, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#inventaire #wrap h1 {
  color: #69e5f4;
  font-size: 6.770833333333334vw;
  line-height: 6.770833333333334vw;
  font-family: "robotoBlack";
  text-transform: uppercase;
}

/* line 210, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#inventaire #wrap p {
  position: absolute;
  top: 50%;
  right: 0;
  width: 100%;
  max-width: 57.03125vw;
  transform: translate(0, -50%);
  color: #002131;
  font-size: 1.3541666666666667vw;
  font-family: "sabloReg";
  line-height: 2.03125vw;
  letter-spacing: 0.01em;
  text-align: center;
}

/* line 227, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#blocVague {
  background-color: #3990ae;
  padding: 20.833333333333336vw 0 13.802083333333334vw 0;
}

/* line 230, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#blocVague .circle-d {
  position: absolute;
  left: 9.0625vw;
  bottom: 3.90625vw;
}

/* line 235, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#blocVague:before {
  background-image: url(../images/vague-bg.png);
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center top;
  position: absolute;
  display: block;
  content: "";
  top: -9.552083vw;
  left: 0;
  width: 100%;
  height: 100%;
}

/* line 248, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#blocVague #wrap {
  position: relative;
  max-width: 82.8125vw;
  margin: 0 auto;
  z-index: 2;
}

/* line 253, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#blocVague #wrap:after {
  display: table;
  content: "";
  clear: both;
}

/* line 258, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#blocVague #wrap .partBloc {
  float: left;
  width: 50%;
}

/* line 261, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#blocVague #wrap .partBloc .blocInt {
  max-width: 36.458333333333336vw;
}

/* line 263, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#blocVague #wrap .partBloc .blocInt:after {
  display: table;
  content: "";
  clear: both;
}

/* line 268, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#blocVague #wrap .partBloc .blocInt:nth-child(2) {
  float: right;
}

/* line 271, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#blocVague #wrap .partBloc .blocInt h1 {
  margin: 0 0 4.166666666666667vw 0;
  text-align: center;
  color: #fff;
  font-size: 4.0625vw;
  line-height: 4.0625vw;
  letter-spacing: 0.01em;
  font-family: "sabloReg";
  margin: 0 0 4.427083333333334vw;
}

/* line 281, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#blocVague #wrap .partBloc .blocInt p {
  color: #fff;
  font-size: 0.8854166666666667vw;
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
  font-family: "robotoReg";
}

/* line 287, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#blocVague #wrap .partBloc .blocInt p + p {
  margin: 0.78125vw 0 0 0;
}

/* line 297, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#urgenceEntreprise {
  background: #3990ae;
  padding: 0 0 14.322916666666668vw;
}

/* line 300, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#urgenceEntreprise .goutte {
  position: absolute;
  top: 2.604166666666667vw;
  right: -0.9895833333333334vw;
}

/* line 305, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#urgenceEntreprise #wrap {
  max-width: 57.5vw;
  margin: 0 auto;
  position: relative;
}

/* line 309, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#urgenceEntreprise #wrap:after {
  display: table;
  content: "";
  clear: both;
}

/* line 314, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#urgenceEntreprise #wrap h1 {
  color: #69e5f4;
  font-size: 6.770833333333334vw;
  line-height: 6.770833333333334vw;
  font-family: "robotoBlack";
  text-transform: uppercase;
  opacity: 0.25;
  max-width: 36.458333333333336vw;
  float: right;
  text-align: right;
  position: absolute;
  right: 0;
  top: 0;
}

/* line 328, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-page-entreprise.scss */
#urgenceEntreprise #wrap p {
  color: #fff;
  font-size: 1.3541666666666667vw;
  line-height: 2.03125vw;
  letter-spacing: 0.01em;
  max-width: 39.895833333333336vw;
  text-align: right;
  padding: 13.541666666666668vw 0 0 0;
}

/* line 1, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew {
  padding: 3.4375vw 0 0 0;
}

@media screen and (max-width: 767px) {
  /* line 1, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew {
    padding: 15px 0 0 0;
  }
}

/* line 6, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew .vague-rond-bleu {
  position: absolute;
  right: 0;
  top: 8.71875vw;
}

/* line 11, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left {
  width: 80%;
  max-width: 46.302083333333336vw;
  float: left;
}

@media screen and (max-width: 1280px) {
  /* line 11, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left {
    max-width: 37vw;
  }
}

@media screen and (max-width: 767px) {
  /* line 11, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left {
    max-width: 100%;
    float: initial;
    margin: 0 auto;
    width: 100%;
  }
}

/* line 25, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide {
  width: 100%;
  max-width: 37.239583333333336vw;
  float: right;
}

@media screen and (max-width: 1280px) {
  /* line 25, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left #partSlide {
    max-width: 28vw;
  }
}

@media screen and (max-width: 767px) {
  /* line 25, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left #partSlide {
    max-width: calc(100% - 80px);
    margin: 0 auto;
    float: initial;
  }
}

/* line 37, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide:after {
  display: table;
  content: "";
  clear: both;
}

/* line 43, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide #newSlide .slick-arrow {
  display: none !important;
  z-index: 3;
}

@media screen and (max-width: 767px) {
  /* line 43, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left #partSlide #newSlide .slick-arrow {
    display: block !important;
  }
}

/* line 49, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide #newSlide .slick-arrow.slick-next {
  right: 0 !important;
}

/* line 52, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide #newSlide .slick-arrow.slick-prev {
  left: 0 !important;
}

/* line 60, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide #newSlide .slick-list .slick-track .slick-slide {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 36.51041666666667vw;
}

@media screen and (max-width: 1280px) {
  /* line 60, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left #partSlide #newSlide .slick-list .slick-track .slick-slide {
    height: 27vw;
  }
}

@media screen and (max-width: 767px) {
  /* line 60, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left #partSlide #newSlide .slick-list .slick-track .slick-slide {
    height: 300px;
  }
}

/* line 75, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide #newSlideNav {
  max-width: calc(100% - 30px);
  margin: 15px auto 0;
}

@media screen and (max-width: 767px) {
  /* line 75, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left #partSlide #newSlideNav {
    display: none;
  }
}

/* line 81, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide #newSlideNav .slick-arrow {
  background: #3990ae;
  width: 38px;
  height: 38px;
  z-index: 1;
}

/* line 86, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide #newSlideNav .slick-arrow:before {
  background-image: url(../images/arrow-gal.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 21px;
  transform: translate(-50%, -50%);
  content: "" !important;
  filter: invert(100%);
  opacity: 1 !important;
}

/* line 101, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide #newSlideNav .slick-arrow.slick-prev {
  left: 0 !important;
}

/* line 103, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide #newSlideNav .slick-arrow.slick-prev:before {
  transform: translate(-50%, -50%) rotate(180deg);
}

/* line 107, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide #newSlideNav .slick-arrow.slick-next {
  right: 0 !important;
}

/* line 111, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide #newSlideNav .slick-list {
  padding: 0 !important;
  margin: 0 -7px !important;
}

/* line 116, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide #newSlideNav .slick-list .slick-track .slick-slide {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 11.666666666666668vw;
  margin: 0 7px !important;
}

@media screen and (max-width: 1280px) {
  /* line 116, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left #partSlide #newSlideNav .slick-list .slick-track .slick-slide {
    height: 14vw;
  }
}

/* line 125, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #partSlide #newSlideNav .slick-list .slick-track .slick-slide:before {
  display: table;
  content: "";
  clear: both;
}

/* line 135, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #garant {
  background: #3990ae;
  clear: both;
  width: calc(100% - 15px);
  padding: 4.583333333333334vw 8.177083333333334vw 4.583333333333334vw 0;
  margin: 15px 0 0 0;
}

@media screen and (max-width: 1280px) {
  /* line 135, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left #garant {
    padding: 70px 0 70px 0;
  }
}

@media screen and (max-width: 767px) {
  /* line 135, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left #garant {
    display: none;
  }
}

/* line 147, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #garant #contenu {
  max-width: 28.28125vw;
  float: right;
}

/* line 150, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #garant #contenu h3 {
  color: #fff;
  font-size: 3.1770833333333335vw;
  font-family: "sabloReg";
  line-height: 100%;
  letter-spacing: 0.01em;
  margin: 0 0 1.5625vw 0;
}

@media screen and (max-width: 1280px) {
  /* line 150, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left #garant #contenu h3 {
    font-size: 42px;
  }
}

@media screen and (max-width: 767px) {
  /* line 150, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left #garant #contenu h3 {
    font-size: 20px;
  }
}

/* line 164, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #garant #contenu p {
  color: #fff;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 164, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #left #garant #contenu p {
    font-size: 12px;
    line-height: 19px;
    padding: 0 30px 0 0;
  }
}

/* line 176, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #garant #contenu ul {
  margin: 10px 0 0 0;
}

/* line 178, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #garant #contenu ul li {
  position: relative;
  display: block;
  padding: 0 0 0 30px;
  color: #fff;
  font-size: .8854166666666667vw;
  font-family: robotoReg;
  line-height: 1.3541666666666667vw;
  letter-spacing: .01em;
}

/* line 187, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #garant #contenu ul li + li {
  margin: 7px 0 0 0;
}

/* line 190, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #left #garant #contenu ul li:before {
  background: #fff;
  position: absolute;
  display: block;
  content: "";
  float: left;
  width: 15px;
  height: 2px;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

/* line 207, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right {
  width: 44.739583333333336vw;
  float: left;
  position: relative;
}

@media screen and (max-width: 1280px) {
  /* line 207, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right {
    width: 54vw;
  }
}

@media screen and (max-width: 767px) {
  /* line 207, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right {
    min-height: auto !important;
    float: initial;
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 580px) {
  /* line 207, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right {
    width: 90%;
  }
}

/* line 223, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu {
  background: #fff;
  position: relative;
  max-width: 36.458333333333336vw;
  padding: 0 3.9583333333333335vw 4.114583333333334vw;
  z-index: 1;
}

@media screen and (max-width: 1280px) {
  /* line 223, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu {
    max-width: 45vw;
  }
}

@media screen and (max-width: 991px) {
  /* line 223, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu {
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  /* line 223, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu {
    padding: 60px 0;
  }
}

/* line 238, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #tete {
  margin: 0 0 2.0833333333333335vw 0;
}

/* line 240, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #tete h1 {
  color: #002131;
  font-size: 3.1770833333333335vw;
  font-family: "sabloReg";
  line-height: 4.0625vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 240, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu #tete h1 {
    font-size: 42px;
    line-height: 54px;
  }
}

/* line 251, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #tete span {
  color: #3990ae;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 251, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu #tete span {
    font-size: 13px;
    line-height: 19px;
  }
}

/* line 263, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu p {
  color: #002131;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 263, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu p {
    font-size: 13px;
    line-height: 19px;
  }
}

/* line 274, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu .titleDispo {
  color: #002131;
  font-size: 20px;
  font-family: "robotoMed";
  text-transform: uppercase;
  letter-spacing: 0.01em;
  margin: 30px 0 15px 0;
}

/* line 285, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu ul li + li {
  margin: 30px 0 0 0;
}

/* line 288, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu ul li h5 {
  margin: 0 0 5px 0;
  color: #002131;
  font-size: 16px;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
}

/* line 296, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu ul li p {
  margin: 0 0 5px 0;
  color: #002131;
  font-size: 16px;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
}

/* line 305, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #dimensions {
  margin: 40px 0;
}

/* line 307, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #dimensions span {
  display: block;
}

/* line 309, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #dimensions span:nth-child(1) {
  color: #002131;
  font-size: 0.8854166666666667vw;
  font-family: "robotoBold";
  line-height: 100%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  margin: 0 0 0.78125vw 0;
}

@media screen and (max-width: 1280px) {
  /* line 309, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu #dimensions span:nth-child(1) {
    font-size: 13px;
  }
}

/* line 321, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #dimensions span:nth-child(2) {
  color: #002131;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 100%;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 321, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu #dimensions span:nth-child(2) {
    font-size: 13px;
  }
}

/* line 333, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #colorDispo {
  color: #3990ae;
  font-size: 0.8854166666666667vw;
  font-family: "robotoItalic";
  line-height: 100%;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 333, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu #colorDispo {
    font-size: 13px;
  }
}

/* line 343, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu .secMobile {
  display: none;
}

@media screen and (max-width: 767px) {
  /* line 343, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu .secMobile {
    display: block;
  }
}

/* line 348, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu .secMobile#first {
  margin: 30px 0 20px 0;
}

/* line 355, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu .secMobile#first #newSlideM .slick-list .slick-track .slick-slide {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 250px;
}

/* line 365, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu .secMobile#second {
  background: #3990ae;
  padding: 40px 0;
  margin: 40px 0 0 0;
}

/* line 369, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu .secMobile#second .contenu {
  padding: 0 40px;
}

/* line 371, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu .secMobile#second .contenu h3 {
  color: #fff;
  font-size: 3.1770833333333335vw;
  font-family: "sabloReg";
  line-height: 100%;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 371, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu .secMobile#second .contenu h3 {
    font-size: 42px;
  }
}

/* line 381, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu .secMobile#second .contenu p {
  color: #fff;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 381, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu .secMobile#second .contenu p {
    font-size: 12px;
    line-height: 19px;
  }
}

/* line 395, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 3.125vw 0 0;
}

/* line 406, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #bottom .partBottom {
  text-align: center;
  display: flex;
  flex-direction: column;
}

/* line 420, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #bottom .partBottom img {
  display: block;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin: auto;
}

/* line 426, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #bottom .partBottom span {
  color: #3990ae;
  letter-spacing: 0.01em;
  display: block;
  line-height: 100%;
}

/* line 431, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #bottom .partBottom span:nth-child(2) {
  font-family: "robotoBold";
  font-size: 2.447916666666667vw;
}

@media screen and (max-width: 1280px) {
  /* line 431, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu #bottom .partBottom span:nth-child(2) {
    font-size: 31px;
  }
}

/* line 438, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #contenu #bottom .partBottom span:nth-child(3) {
  font-family: "robotoReg";
  font-size: 0.8854166666666667vw;
  text-transform: uppercase;
}

@media screen and (max-width: 1280px) {
  /* line 438, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #contenu #bottom .partBottom span:nth-child(3) {
    font-size: 13px;
  }
}

/* line 450, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introNew #right #secondImg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 100%;
  height: 45vw;
  bottom: 0;
}

@media screen and (max-width: 767px) {
  /* line 450, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introNew #right #secondImg {
    display: none;
  }
}

/* line 468, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse {
  padding: 3.4375vw 0 2.65625vw 0;
}

@media screen and (max-width: 1281px) {
  /* line 468, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse {
    padding-top: 8vw;
  }
}

@media screen and (max-width: 767px) {
  /* line 468, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse {
    padding: 15px 0 10px 0;
  }
}

/* line 478, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse:after {
  display: table;
  content: "";
  clear: both;
}

/* line 483, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse #sceau {
  background-image: url(../images/sceau-garantie.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  width: 13.854166666666668vw;
  height: 11.354166666666668vw;
  top: 2.34375vw;
  right: 8.333333333333334vw;
}

@media screen and (max-width: 767px) {
  /* line 483, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse #sceau {
    width: 140px;
    height: 115px;
    z-index: 3;
    top: 230px !important;
    right: 5vw;
  }
}

/* line 501, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .vague-rond {
  position: absolute;
  top: 18.489583333333336vw;
  right: 0;
}

/* line 506, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap {
  max-width: 82.55208333333334vw;
  margin: 0 auto;
}

/* line 509, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #left {
  float: left;
  width: 36.51041666666667vw;
}

@media screen and (max-width: 767px) {
  /* line 509, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #left {
    float: initial;
    width: 100%;
    margin: 0 0 30px 0;
  }
}

/* line 518, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #left #prodGal .slick-arrow {
  display: none !important;
  z-index: 3;
}

@media screen and (max-width: 767px) {
  /* line 518, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #left #prodGal .slick-arrow {
    display: block !important;
  }
}

/* line 524, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #left #prodGal .slick-arrow.slick-next {
  right: 0 !important;
}

/* line 527, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #left #prodGal .slick-arrow.slick-prev {
  left: 0 !important;
}

/* line 535, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #left #prodGal .slick-list .slick-track .slick-slide {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 36.51041666666667vw;
}

@media screen and (max-width: 767px) {
  /* line 535, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #left #prodGal .slick-list .slick-track .slick-slide {
    height: 300px;
  }
}

/* line 547, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #left #prodGal-nav {
  margin: 15px 0 0 0;
}

@media screen and (max-width: 767px) {
  /* line 547, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #left #prodGal-nav {
    display: none;
  }
}

/* line 552, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #left #prodGal-nav .slick-arrow {
  background: #3990ae;
  width: 38px;
  height: 38px;
  z-index: 1;
}

/* line 557, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #left #prodGal-nav .slick-arrow:before {
  background-image: url(../images/arrow-gal.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 21px;
  transform: translate(-50%, -50%);
  content: "" !important;
  filter: invert(100%);
  opacity: 1 !important;
}

/* line 572, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #left #prodGal-nav .slick-arrow.slick-prev {
  left: 0 !important;
}

/* line 574, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #left #prodGal-nav .slick-arrow.slick-prev:before {
  transform: translate(-50%, -50%) rotate(180deg);
}

/* line 578, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #left #prodGal-nav .slick-arrow.slick-next {
  right: 0 !important;
}

/* line 582, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #left #prodGal-nav .slick-list {
  padding: 0 !important;
  margin: 0 -7px !important;
}

/* line 587, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #left #prodGal-nav .slick-list .slick-track .slick-slide {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 11.666666666666668vw;
  margin: 0 7px !important;
}

/* line 598, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right {
  float: left;
  width: calc(100% - 36.51041666666667vw);
  padding: 0 0 0 4.479166666666667vw;
}

@media screen and (max-width: 767px) {
  /* line 598, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #right {
    float: initial;
    width: 100%;
    padding: 0;
  }
}

/* line 607, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt {
  max-width: 28.697916666666668vw;
}

@media screen and (max-width: 1280px) {
  /* line 607, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #right #rightInt {
    max-width: 100%;
  }
}

/* line 612, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #listOptions {
  margin: 2.0833333333333335vw 0 0 0;
}

/* line 614, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #listOptions table {
  width: 100%;
}

/* line 617, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #listOptions table tr + tr {
  border-top: 1px solid #ccc;
}

/* line 620, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #listOptions table tr td {
  color: #002131;
  font-size: 0.8854166666666667vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  vertical-align: top;
  padding: 5px 0;
}

@media screen and (max-width: 1280px) {
  /* line 620, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #right #rightInt #listOptions table tr td {
    font-size: 13px;
    line-height: 18px;
  }
}

/* line 632, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #listOptions table tr td span {
  display: block;
  color: #002131;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
  text-transform: initial;
}

@media screen and (max-width: 1280px) {
  /* line 632, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #right #rightInt #listOptions table tr td span {
    font-size: 13px;
    line-height: 18px;
  }
}

/* line 648, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #bottom {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 3.125vw 0 0;
}

/* line 659, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #bottom .partBottom {
  text-align: center;
  display: flex;
  flex-direction: column;
}

/* line 673, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #bottom .partBottom img {
  display: block;
  height: 63px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  margin: auto;
}

@media all and (max-width: 992px) {
  /* line 673, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #right #rightInt #bottom .partBottom img {
    height: 40px;
    width: 40px;
    margin: 0 auto 10px;
  }
}

/* line 685, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #bottom .partBottom span {
  color: #3990ae;
  letter-spacing: 0.01em;
  display: block;
  line-height: 100%;
}

/* line 690, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #bottom .partBottom span:nth-child(2) {
  font-family: "robotoBold";
  font-size: 2.447916666666667vw;
}

@media screen and (max-width: 1280px) {
  /* line 690, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #right #rightInt #bottom .partBottom span:nth-child(2) {
    font-size: 31px;
  }
}

/* line 697, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #bottom .partBottom span:nth-child(3) {
  font-family: "robotoReg";
  font-size: 0.8854166666666667vw;
  text-transform: uppercase;
}

@media screen and (max-width: 1280px) {
  /* line 697, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #right #rightInt #bottom .partBottom span:nth-child(3) {
    font-size: 13px;
  }
}

/* line 712, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #tete h1 {
  color: #002131;
  font-size: 3.1770833333333335vw;
  font-family: "sabloReg";
  line-height: 4.0625vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 712, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #right #rightInt #tete h1 {
    font-size: 42px;
    line-height: 54px;
  }
}

/* line 723, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #tete .sous {
  color: #3990ae;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 723, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #right #rightInt #tete .sous {
    font-size: 13px;
    line-height: 19px;
  }
}

/* line 734, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #tete #bottom {
  min-height: 5vw;
  position: relative;
  margin: 1.0416666666666667vw 0 0 0;
}

/* line 740, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #tete #bottom span#price {
  float: left;
  color: #3990ae;
  font-size: 2.447916666666667vw;
  font-family: "robotoBold";
  line-height: 3.697916666666667vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 740, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #right #rightInt #tete #bottom span#price {
    font-size: 31px;
    line-height: 47px;
  }
}

@media screen and (max-width: 400px) {
  /* line 740, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #right #rightInt #tete #bottom span#price {
    font-size: 7vw;
  }
}

/* line 756, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #tete #bottom span#reserver {
  position: absolute !important;
  right: 0;
  top: 50%;
  cursor: pointer;
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
  transform: translate(0, -50%);
}

@media screen and (max-width: 1280px) {
  /* line 756, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #right #rightInt #tete #bottom span#reserver {
    font-size: 14px;
    height: 20px;
  }
}

/* line 778, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #tete #bottom span#reserver:before {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 0px;
  height: 0px;
  transition-property: width, height;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0.3s;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}

/* line 793, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #tete #bottom span#reserver:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 793, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #introUse .wrap #right #rightInt #tete #bottom span#reserver:after {
    height: 0.20833333333333334vw;
  }
}

/* line 807, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #tete #bottom span#reserver:hover {
  color: #fff;
}

/* line 809, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #tete #bottom span#reserver:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

/* line 813, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#introUse .wrap #right #rightInt #tete #bottom span#reserver:hover:after {
  width: 0;
}

/* line 827, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide .goutte {
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 4.947916666666667vw;
  transform: translate(0, -50%);
}

/* line 835, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-arrow {
  background-color: #002131;
  background-image: url(../images/arrow-slide-use.svg);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 1.3541666666666667vw 52px;
  z-index: 1;
  width: 4.895833333333334vw;
  height: 4.895833333333334vw;
}

@media screen and (max-width: 991px) {
  /* line 835, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #usageSlide #sUsage .slick-arrow {
    width: 100px;
    height: 100px;
    background-size: 22px 43px;
  }
}

/* line 849, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-arrow:before {
  display: none;
}

/* line 852, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-arrow.slick-prev {
  right: 0 !important;
  left: initial !important;
  top: calc(50% - (5.833333333333334vw / 2)) !important;
  transform: translate(0, -50%) rotate(180deg);
}

@media screen and (max-width: 991px) {
  /* line 852, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #usageSlide #sUsage .slick-arrow.slick-prev {
    top: inherit !important;
    bottom: -51px;
    right: 102px !important;
  }
}

/* line 863, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-arrow.slick-next {
  right: 0 !important;
  top: initial !important;
  bottom: calc(50% - (5.833333333333334vw / 2)) !important;
  transform: translate(0, 50%);
}

@media screen and (max-width: 991px) {
  /* line 863, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #usageSlide #sUsage .slick-arrow.slick-next {
    top: inherit !important;
    bottom: 49px !important;
  }
}

/* line 874, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-list {
  padding: 0 !important;
  margin: 0 !important;
}

/* line 879, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-list .slick-track .slick-slide {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  height: 36.97916666666667vw;
  margin: 0 !important;
}

@media screen and (max-width: 991px) {
  /* line 879, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #usageSlide #sUsage .slick-list .slick-track .slick-slide {
    height: 460px;
  }
}

@media screen and (max-width: 580px) {
  /* line 879, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #usageSlide #sUsage .slick-list .slick-track .slick-slide {
    height: 738px;
  }
}

/* line 891, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-list .slick-track .slick-slide .slideInt {
  position: absolute;
  width: 100%;
  max-width: 66.40625vw;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* line 899, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-list .slick-track .slick-slide .slideInt:after {
  display: table;
  content: "";
  clear: both;
}

@media screen and (max-width: 991px) {
  /* line 891, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #usageSlide #sUsage .slick-list .slick-track .slick-slide .slideInt {
    max-width: 83.40625vw;
    top: 40%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
    align-items: flex-end;
  }
}

/* line 927, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-list .slick-track .slick-slide .slideInt h1 {
  position: absolute;
  color: #69e5f4;
  font-size: 6.822916666666667vw;
  font-family: "robotoBlack";
  letter-spacing: 0.01em;
  line-height: 6.822916666666667vw;
  text-transform: uppercase;
}

@media screen and (max-width: 991px) {
  /* line 927, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #usageSlide #sUsage .slick-list .slick-track .slick-slide .slideInt h1 {
    font-size: 50px !important;
    max-width: 268px !important;
    position: static;
  }
}

/* line 950, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-list .slick-track .slick-slide .slideInt p {
  color: #fff;
  font-size: 1.3541666666666667vw;
  font-family: "sabloReg";
  line-height: 2.03125vw;
  letter-spacing: 0.1em;
}

@media screen and (max-width: 991px) {
  /* line 950, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #usageSlide #sUsage .slick-list .slick-track .slick-slide .slideInt p {
    margin-top: 1vw !important;
    font-size: 21px !important;
    max-width: 100% !important;
  }
}

@media screen and (max-width: 580px) {
  /* line 950, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #usageSlide #sUsage .slick-list .slick-track .slick-slide .slideInt p {
    font-size: 5vw !important;
  }
}

/* line 974, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-list .slick-track .slick-slide.s0 .slideInt h1 {
  width: 100%;
  max-width: 33vw;
  top: 0;
  left: 0;
}

/* line 983, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-list .slick-track .slick-slide.s0 .slideInt p {
  margin: 6.5625vw 0 0 0;
  max-width: 43.69791666666667vw;
  float: right;
}

/* line 1003, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-list .slick-track .slick-slide.s1 .slideInt h1 {
  width: 100%;
  max-width: 500px;
  right: 0;
  text-align: right;
  font-size: 5.989583333333334vw;
  line-height: 5.989583333333334vw;
}

/* line 1017, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#usageSlide #sUsage .slick-list .slick-track .slick-slide.s1 .slideInt p {
  margin: 6.770833333333334vw 0 0 0;
  max-width: 36.458333333333336vw;
  text-align: right;
  font-size: 1.1458333333333335vw;
  line-height: 1.8229166666666667vw;
}

/* line 1037, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite {
  padding: 5.208333333333334vw 0 9.895833333333334vw 0;
  margin-bottom: 15vw;
}

/* line 1040, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .image {
  margin: 1vw 0;
  height: 200px;
  width: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
}

@media screen and (max-width: 580px) {
  /* line 1040, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #fonctionnalite .image {
    height: 50vw;
  }
}

/* line 1052, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .circle-d {
  position: absolute;
  left: 2.447916666666667vw;
  bottom: -8vw;
}

/* line 1057, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .tab_content {
  display: flex;
  flex-wrap: wrap;
}

/* line 1061, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap {
  margin: 0 9.1vw 5vw;
  position: absolute;
  width: 82vw;
  display: flex;
  flex-wrap: wrap;
}

/* line 1064, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap.options h1 {
  position: relative;
  left: 70vw;
}

/* line 1074, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap.specification .tab_content {
  flex-direction: column;
  width: 100%;
}

/* line 1078, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap.specification .tab_text {
  border-bottom: 0.15vw #e1e1e1 solid;
  width: 100%;
  margin: 0;
}

/* line 1083, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap.specification .foncInt {
  margin: 0.3vw 0 !important;
  width: 100%;
  display: flex;
  flex-direction: row;
}

/* line 1088, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap.specification .foncInt h4 {
  width: 33vw;
  margin: 0 !important;
}

/* line 1092, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap.specification .foncInt p {
  width: 50%;
}

/* line 1096, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap.specification h1 {
  position: relative;
  left: 36vw;
}

/* line 1112, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap h1 {
  color: #e1e1e1;
  font-size: 3.1770833333333335vw;
  line-height: 4.0625vw;
  letter-spacing: 0.01em;
  display: block;
  clear: both;
  margin: 0 0 3.125vw 0;
  width: 100%;
  font-family: "sabloReg";
}

@media screen and (max-width: 1280px) {
  /* line 1112, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #fonctionnalite .wrap h1 {
    margin: 0 0 60px 0;
    font-size: 40px;
    line-height: 100%;
  }
}

@media screen and (max-width: 991px) {
  /* line 1112, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #fonctionnalite .wrap h1 {
    margin: 0 0 40px 0;
  }
}

/* line 1132, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap .fonc-by-fonc {
  width: 25%;
  margin: 0 0 3.125vw 0;
}

@media screen and (max-width: 1280px) {
  /* line 1132, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #fonctionnalite .wrap .fonc-by-fonc {
    width: 33.3333333333%;
  }
}

@media screen and (max-width: 991px) {
  /* line 1132, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #fonctionnalite .wrap .fonc-by-fonc {
    width: 50%;
    margin: 0 0 30px;
  }
}

@media screen and (max-width: 767px) {
  /* line 1132, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #fonctionnalite .wrap .fonc-by-fonc {
    width: 100%;
  }
}

/* line 1145, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap .fonc-by-fonc .foncInt {
  max-width: calc(100% - 20px);
  margin: 0 auto;
}

/* line 1149, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap .fonc-by-fonc .foncInt .imgFonc {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 13.333333333333334vw;
}

@media screen and (max-width: 1280px) {
  /* line 1149, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #fonctionnalite .wrap .fonc-by-fonc .foncInt .imgFonc {
    height: 250px;
  }
}

/* line 1158, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap .fonc-by-fonc .foncInt h4 {
  color: #002131;
  font-size: 0.8854166666666667vw;
  line-height: 1.3541666666666667vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
  margin: 1.0416666666666667vw 0;
}

@media screen and (max-width: 1280px) {
  /* line 1158, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #fonctionnalite .wrap .fonc-by-fonc .foncInt h4 {
    font-size: 13px;
    line-height: 19px;
  }
}

/* line 1170, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
#fonctionnalite .wrap .fonc-by-fonc .foncInt p {
  color: #002131;
  font-size: 0.8854166666666667vw;
  line-height: 1.3541666666666667vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 1170, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-spas.scss */
  #fonctionnalite .wrap .fonc-by-fonc .foncInt p {
    font-size: 13px;
    line-height: 19px;
  }
}

/* line 1, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv {
  padding: 3.4375vw 0 2.65625vw 0;
  z-index: 2;
}

/* line 4, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .vague-rond {
  position: absolute;
  top: 18.489583333333336vw;
  right: 0;
}

/* line 10, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv:after {
  display: table;
  content: "";
  clear: both;
}

/* line 15, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap {
  max-width: 82.55208333333334vw;
  margin: 0 auto;
}

/* line 18, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #left {
  float: left;
  width: 36.51041666666667vw;
}

@media screen and (max-width: 767px) {
  /* line 18, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #left {
    float: initial;
    width: 100%;
    margin: 0 0 30px 0;
  }
}

/* line 27, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #left #prodGal .slick-arrow {
  display: none !important;
  z-index: 9;
}

@media screen and (max-width: 767px) {
  /* line 27, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #left #prodGal .slick-arrow {
    display: block !important;
  }
}

/* line 33, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #left #prodGal .slick-arrow.slick-next {
  right: 0 !important;
}

/* line 36, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #left #prodGal .slick-arrow.slick-prev {
  left: 0 !important;
}

/* line 44, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #left #prodGal .slick-list .slick-track .slick-slide {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 36.51041666666667vw;
}

@media screen and (max-width: 767px) {
  /* line 44, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #left #prodGal .slick-list .slick-track .slick-slide {
    height: 300px;
  }
}

/* line 56, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #left #prodGal-nav {
  margin: 15px 0 0 0;
}

@media screen and (max-width: 767px) {
  /* line 56, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #left #prodGal-nav {
    display: none;
  }
}

/* line 61, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #left #prodGal-nav .slick-arrow {
  background: #3990ae;
  width: 38px;
  height: 38px;
  z-index: 1;
}

/* line 66, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #left #prodGal-nav .slick-arrow:before {
  background-image: url(../images/arrow-gal.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 21px;
  transform: translate(-50%, -50%);
  content: "" !important;
  filter: invert(100%);
  opacity: 1 !important;
}

/* line 81, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #left #prodGal-nav .slick-arrow.slick-prev {
  left: 0 !important;
}

/* line 83, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #left #prodGal-nav .slick-arrow.slick-prev:before {
  transform: translate(-50%, -50%) rotate(180deg);
}

/* line 87, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #left #prodGal-nav .slick-arrow.slick-next {
  right: 0 !important;
}

/* line 91, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #left #prodGal-nav .slick-list {
  padding: 0 !important;
  margin: 0 -7px !important;
}

/* line 96, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #left #prodGal-nav .slick-list .slick-track .slick-slide {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 11.666666666666668vw;
  margin: 0 7px !important;
}

/* line 107, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right {
  float: left;
  width: calc(100% - 36.51041666666667vw);
  padding: 0 0 0 4.479166666666667vw;
}

@media screen and (max-width: 767px) {
  /* line 107, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right {
    float: initial;
    width: 100%;
    padding: 0;
  }
}

/* line 116, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt {
  max-width: 28.697916666666668vw;
}

@media screen and (max-width: 1280px) {
  /* line 116, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt {
    max-width: 100%;
  }
}

/* line 121, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #listOptions {
  margin: 2.0833333333333335vw 0 0 0;
}

/* line 123, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #listOptions table {
  width: 100%;
}

/* line 126, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #listOptions table tr + tr {
  border-top: 1px solid #ccc;
}

/* line 129, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #listOptions table tr td {
  color: #002131;
  font-size: 0.8854166666666667vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  vertical-align: top;
  padding: 5px 0;
}

@media screen and (max-width: 1280px) {
  /* line 129, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt #listOptions table tr td {
    font-size: 13px;
    line-height: 18px;
  }
}

/* line 141, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #listOptions table tr td span {
  display: block;
  color: #002131;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
  text-transform: initial;
}

@media screen and (max-width: 1280px) {
  /* line 141, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt #listOptions table tr td span {
    font-size: 13px;
    line-height: 18px;
  }
}

/* line 157, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #bottom {
  display: table;
  width: 100%;
  margin: 3.125vw 0 0 0;
}

@media screen and (max-width: 767px) {
  /* line 157, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt #bottom {
    margin: 30px 0 0 0;
  }
}

@media screen and (max-width: 580px) {
  /* line 157, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt #bottom {
    display: table;
  }
}

/* line 167, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #bottom .partBottom {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

@media screen and (max-width: 580px) {
  /* line 167, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt #bottom .partBottom {
    display: block;
    float: left;
    width: 50%;
    margin: 0 0 30px;
  }
  /* line 176, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt #bottom .partBottom:nth-child(3) {
    width: 100%;
  }
}

/* line 180, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #bottom .partBottom img {
  display: block;
  margin: 0 auto 10px;
}

/* line 184, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #bottom .partBottom span {
  color: #3990ae;
  letter-spacing: 0.01em;
  display: block;
  line-height: 100%;
}

/* line 189, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #bottom .partBottom span:nth-child(2) {
  font-family: "robotoBold";
  font-size: 2.447916666666667vw;
}

@media screen and (max-width: 1280px) {
  /* line 189, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt #bottom .partBottom span:nth-child(2) {
    font-size: 31px;
  }
}

/* line 196, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #bottom .partBottom span:nth-child(3) {
  font-family: "robotoReg";
  font-size: 0.8854166666666667vw;
  text-transform: uppercase;
}

@media screen and (max-width: 1280px) {
  /* line 196, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt #bottom .partBottom span:nth-child(3) {
    font-size: 13px;
  }
}

/* line 213, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #tete h1 {
  color: #002131;
  font-size: 3.1770833333333335vw;
  font-family: "sabloReg";
  line-height: 4.0625vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 213, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt #tete h1 {
    font-size: 42px;
    line-height: 54px;
  }
}

/* line 224, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #tete .sous {
  color: #3990ae;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 224, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt #tete .sous {
    font-size: 13px;
    line-height: 19px;
  }
}

/* line 235, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #tete #bottom {
  position: relative;
  margin: 1.0416666666666667vw 0 0 0;
}

/* line 240, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #tete #bottom span#price {
  float: left;
  color: #3990ae;
  font-size: 2.447916666666667vw;
  font-family: "robotoBold";
  line-height: 3.697916666666667vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 240, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt #tete #bottom span#price {
    font-size: 31px;
    line-height: 47px;
  }
}

/* line 252, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #tete #bottom span#reserver {
  position: absolute !important;
  right: 0;
  top: 50%;
  cursor: pointer;
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
  transform: translate(0, -50%);
}

@media screen and (max-width: 1280px) {
  /* line 252, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt #tete #bottom span#reserver {
    font-size: 14px;
    height: 20px;
  }
}

/* line 274, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #tete #bottom span#reserver:before {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 0px;
  height: 0px;
  transition-property: width, height;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0.3s;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}

/* line 289, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #tete #bottom span#reserver:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 289, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
  #introCouv .wrap #right #rightInt #tete #bottom span#reserver:after {
    height: 0.20833333333333334vw;
  }
}

/* line 303, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #tete #bottom span#reserver:hover {
  color: #fff;
}

/* line 305, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #tete #bottom span#reserver:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

/* line 309, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-couv.scss */
#introCouv .wrap #right #rightInt #tete #bottom span#reserver:hover:after {
  width: 0;
}

/* line 1, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble {
  padding: 3.4375vw 0 2.65625vw 0;
}

/* line 3, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .vague-rond {
  position: absolute;
  top: 18.489583333333336vw;
  right: 0;
}

/* line 8, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble:after {
  display: table;
  content: "";
  clear: both;
}

/* line 13, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap {
  max-width: 82.55208333333334vw;
  margin: 0 auto;
}

/* line 16, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #left {
  float: left;
  width: 36.51041666666667vw;
}

@media screen and (max-width: 767px) {
  /* line 16, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #left {
    float: initial;
    width: 100%;
    margin: 0 0 30px 0;
  }
}

/* line 25, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #left #prodGal .slick-arrow {
  display: none !important;
  z-index: 9;
}

@media screen and (max-width: 767px) {
  /* line 25, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #left #prodGal .slick-arrow {
    display: block !important;
  }
}

/* line 31, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #left #prodGal .slick-arrow.slick-next {
  right: 0 !important;
}

/* line 34, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #left #prodGal .slick-arrow.slick-prev {
  left: 0 !important;
}

/* line 42, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #left #prodGal .slick-list .slick-track .slick-slide {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 36.51041666666667vw;
}

@media screen and (max-width: 767px) {
  /* line 42, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #left #prodGal .slick-list .slick-track .slick-slide {
    height: 300px;
  }
}

/* line 54, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #left #prodGal-nav {
  margin: 15px 0 0 0;
}

@media screen and (max-width: 767px) {
  /* line 54, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #left #prodGal-nav {
    display: none;
  }
}

/* line 59, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #left #prodGal-nav .slick-arrow {
  background: #3990ae;
  width: 38px;
  height: 38px;
  z-index: 1;
}

/* line 64, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #left #prodGal-nav .slick-arrow:before {
  background-image: url(../images/arrow-gal.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 21px;
  transform: translate(-50%, -50%);
  content: "" !important;
  filter: invert(100%);
  opacity: 1 !important;
}

/* line 79, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #left #prodGal-nav .slick-arrow.slick-prev {
  left: 0 !important;
}

/* line 81, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #left #prodGal-nav .slick-arrow.slick-prev:before {
  transform: translate(-50%, -50%) rotate(180deg);
}

/* line 85, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #left #prodGal-nav .slick-arrow.slick-next {
  right: 0 !important;
}

/* line 89, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #left #prodGal-nav .slick-list {
  padding: 0 !important;
  margin: 0 -7px !important;
}

/* line 94, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #left #prodGal-nav .slick-list .slick-track .slick-slide {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  height: 11.666666666666668vw;
  margin: 0 7px !important;
}

/* line 105, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right {
  float: left;
  width: calc(100% - 36.51041666666667vw);
  padding: 0 0 0 4.479166666666667vw;
}

@media screen and (max-width: 767px) {
  /* line 105, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right {
    float: initial;
    width: 100%;
    padding: 0;
  }
}

/* line 114, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt {
  max-width: 28.697916666666668vw;
}

@media screen and (max-width: 1280px) {
  /* line 114, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt {
    max-width: 100%;
  }
}

/* line 119, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #listOptions {
  margin: 2.0833333333333335vw 0 0 0;
}

/* line 121, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #listOptions table {
  width: 100%;
}

/* line 124, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #listOptions table tr + tr {
  border-top: 1px solid #ccc;
}

/* line 127, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #listOptions table tr td {
  color: #002131;
  font-size: 0.8854166666666667vw;
  font-family: "robotoBold";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  vertical-align: top;
  padding: 5px 0;
}

@media screen and (max-width: 1280px) {
  /* line 127, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt #listOptions table tr td {
    font-size: 13px;
    line-height: 18px;
  }
}

/* line 139, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #listOptions table tr td span {
  display: block;
  color: #002131;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  letter-spacing: 0.01em;
  text-transform: initial;
}

@media screen and (max-width: 1280px) {
  /* line 139, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt #listOptions table tr td span {
    font-size: 13px;
    line-height: 18px;
  }
}

/* line 155, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #bottom {
  display: table;
  width: 100%;
  margin: 3.125vw 0 0 0;
}

@media screen and (max-width: 767px) {
  /* line 155, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt #bottom {
    margin: 30px 0 0 0;
  }
}

@media screen and (max-width: 580px) {
  /* line 155, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt #bottom {
    display: table;
  }
}

/* line 165, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #bottom .partBottom {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

@media screen and (max-width: 580px) {
  /* line 165, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt #bottom .partBottom {
    display: block;
    float: left;
    width: 50%;
    margin: 0 0 30px;
  }
  /* line 174, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt #bottom .partBottom:nth-child(3) {
    width: 100%;
  }
}

/* line 178, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #bottom .partBottom img {
  display: block;
  margin: 0 auto 10px;
}

/* line 182, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #bottom .partBottom span {
  color: #3990ae;
  letter-spacing: 0.01em;
  display: block;
  line-height: 100%;
}

/* line 187, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #bottom .partBottom span:nth-child(2) {
  font-family: "robotoBold";
  font-size: 2.447916666666667vw;
}

@media screen and (max-width: 1280px) {
  /* line 187, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt #bottom .partBottom span:nth-child(2) {
    font-size: 31px;
  }
}

/* line 194, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #bottom .partBottom span:nth-child(3) {
  font-family: "robotoReg";
  font-size: 0.8854166666666667vw;
  text-transform: uppercase;
}

@media screen and (max-width: 1280px) {
  /* line 194, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt #bottom .partBottom span:nth-child(3) {
    font-size: 13px;
  }
}

/* line 211, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #tete h1 {
  color: #002131;
  font-size: 3.1770833333333335vw;
  font-family: "sabloReg";
  line-height: 4.0625vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 211, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt #tete h1 {
    font-size: 42px;
    line-height: 54px;
  }
}

/* line 222, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #tete .sous {
  color: #3990ae;
  font-size: 0.8854166666666667vw;
  font-family: "robotoReg";
  line-height: 1.3541666666666667vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 222, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt #tete .sous {
    font-size: 13px;
    line-height: 19px;
  }
}

/* line 233, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #tete #bottom {
  position: relative;
  margin: 1.0416666666666667vw 0 0 0;
}

/* line 238, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #tete #bottom span#price {
  float: left;
  color: #3990ae;
  font-size: 2.447916666666667vw;
  font-family: "robotoBold";
  line-height: 3.697916666666667vw;
  letter-spacing: 0.01em;
}

@media screen and (max-width: 1280px) {
  /* line 238, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt #tete #bottom span#price {
    font-size: 31px;
    line-height: 47px;
  }
}

/* line 250, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #tete #bottom span#reserver {
  position: absolute !important;
  right: 0;
  top: 50%;
  cursor: pointer;
  position: relative;
  display: table;
  color: #69e5f4;
  font-size: 1.09375vw;
  font-family: "robotoMed";
  letter-spacing: 0.01em;
  text-transform: uppercase;
  transition-property: color;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  z-index: 1;
  height: 1.6145833333333335vw;
  transform: translate(0, -50%);
}

@media screen and (max-width: 1280px) {
  /* line 250, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt #tete #bottom span#reserver {
    font-size: 14px;
    height: 20px;
  }
}

/* line 272, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #tete #bottom span#reserver:before {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 0px;
  height: 0px;
  transition-property: width, height;
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0.3s;
  z-index: -1;
  left: 50%;
  transform: translate(-50%, 0);
}

/* line 287, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #tete #bottom span#reserver:after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -0.625vw;
  width: 100%;
  height: 4px;
  transition-property: width;
  transition-duration: 0.3s;
}

@media screen and (min-width: 1920px) {
  /* line 287, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
  #introMeuble .wrap #right #rightInt #tete #bottom span#reserver:after {
    height: 0.20833333333333334vw;
  }
}

/* line 301, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #tete #bottom span#reserver:hover {
  color: #fff;
}

/* line 303, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #tete #bottom span#reserver:hover:before {
  width: calc(100% + 1.5625vw);
  height: calc(100% + (0.625vw * 2));
}

/* line 307, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-sing-meuble.scss */
#introMeuble .wrap #right #rightInt #tete #bottom span#reserver:hover:after {
  width: 0;
}

/* =======================================================================
CSS- Normal
========================================================================== */
/*********** GLOBAL ************/
/* line 5, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#garantieOldbuy .corpsSide #oldBuy .contenu span::before, #singCallAction .partAction .partInt a::before, #singCallAction .partAction .partInt span::before, .contenu a::before {
  display: none !important;
}

/* line 8, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt a:hover,
#garantieOldbuy .corpsSide #oldBuy .contenu span:hover,
#loadMore:hover,
#productPageUsage #products #left form input[type=submit]:hover,
#urgenceHome .corpsWrap .contenu #left .leftInt a:hover,
#aboutService .wrap #servListContent .slick-list .slick-track .slick-slide a:hover,
#urgenceServ .corpsWrap .contenu #left .leftInt a:hover, #loadMore:hover,
#garantieOldbuy .corpsSide #oldBuy .contenu span:hover, #productPageUsage #products #left form input[type=submit]:hover,
header #wrapHead nav .navInt ul li.current-menu-item a:hover, #introCouv .wrap #right #rightInt #tete #bottom span#reserver:hover, #introCouv .wrap #right #rightInt #tete #bottom span#reserver:before, #introUse .wrap #right #rightInt #tete #bottom span#reserver:hover {
  color: #002131 !important;
  background: none !important;
}

/* line 20, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#introUse .wrap #right #rightInt #tete #bottom span#reserver:hover:before {
  display: none;
}

/* line 23, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_footer input:hover {
  background: none;
  color: #fff;
}

/* line 27, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#popOld {
  margin-top: 5vw;
}

/* line 30, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#popOld #contenu #forClose, #popReserve #contenu #forClosea {
  top: 7%;
}

/* line 33, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
header #wrapHead nav {
  width: 100vw;
}

/* line 36, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
header #wrapHead nav .navInt ul#headMenu {
  width: 70vw;
}

/* line 39, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#intro .partIntro#left {
  background-position: 67% 100% !important;
}

/* line 42, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
header #wrapHead nav .navInt ul#menuPartRight {
  width: auto !important;
}

@media all and (max-width: 992px) {
  /* line 42, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  header #wrapHead nav .navInt ul#menuPartRight {
    margin-right: 20px;
  }
}

/* line 48, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
input.search-input {
  width: 0px;
  margin: 0;
  padding: 0;
  border: 0px;
}

/* line 54, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
button.search-submit {
  -webkit-appearance: none;
  background: none;
  border: 0px;
}

/* line 59, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#introUse {
  position: relative;
  z-index: 9999;
}

/* line 63, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
form.search {
  display: flex;
}

/* line 66, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search button {
  background-color: transparent;
  border: none;
  outline: none;
  height: 25px;
  width: 25px;
  padding: 0;
  position: relative;
}

/* line 78, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search button img {
  top: 5px;
  width: 1.63vw;
  height: 1.63vw;
  opacity: 1;
  transition: opacity 0.5s;
  position: absolute;
  left: 0;
}

@media all and (max-width: 992px) {
  /* line 78, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  .search button img {
    width: 36px;
    top: -3px;
    height: 36px;
  }
}

/* line 95, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search button::after {
  content: "";
  position: absolute;
  width: 1.63vw;
  height: 1.63vw;
  display: block;
  opacity: 0;
  pointer-events: none;
  background-image: url(../images/search.svg);
  background-repeat: no-repeat;
  background-size: contain;
  top: 5px;
  transition: opacity 0.5s;
}

@media all and (max-width: 992px) {
  /* line 95, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  .search button::after {
    width: 36px;
    height: 36px;
    top: -3px;
  }
}

/* line 115, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search button:hover::after {
  opacity: 1;
}

/* line 119, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search button:hover img {
  opacity: 0;
}

@media all and (max-width: 992px) {
  /* line 119, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  .search button:hover img {
    width: 36px;
    height: 36px;
  }
}

/* line 127, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search .search-input {
  border: 0px;
  width: 0;
  height: 1.36vw;
  background-color: transparent;
  border-bottom: 2px solid #69e5f4;
  border-radius: 0px;
  color: #ffffff;
  opacity: 0;
  font-family: 'Raleway', sans-serif;
  transition: all 0.5s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* line 143, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search .search-input.visible {
  width: 12.59vw;
  height: 100%;
  opacity: 1;
  font-size: 15px;
}

@media all and (max-width: 580px) {
  /* line 143, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  .search .search-input.visible {
    font-size: 3.8vw;
  }
}

/* line 153, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search-input.visible::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ffffff;
  opacity: 1;
}

/* line 159, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search-input.visible::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
  opacity: 1;
  transition: opacity 0.5s;
}

/* line 166, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search-input.visible:-ms-input-placeholder {
  /* IE 10+ */
  color: #ffffff;
  opacity: 1;
}

/* line 172, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search-input.visible:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
  opacity: 1;
}

/* line 178, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search-input:focus::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  opacity: 0;
}

/* line 183, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search-input:focus::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0;
}

/* line 188, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search-input:focus:-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0;
}

/* line 193, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.search-input:focus:-moz-placeholder {
  /* Firefox 18- */
  opacity: 0;
}

/* line 197, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#header_page_recherche {
  padding: 6.041666666666667vw 0 2.8125vw;
}

/* line 200, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#header_page_recherche #titleBox {
  background: #fff;
  position: relative;
  width: 45.520833333333336vw;
  height: 16.875vw;
  border: .8854166667vw solid #69e5f4;
  border-left: none !important;
}

/* line 208, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#header_page_recherche #titleBox:before {
  position: absolute;
  background-image: url(../images/vague-prod.jpg);
  background-size: contain;
  background-position: 100%;
  background-repeat: no-repeat;
  top: 50%;
  left: 14vw;
  display: table;
  content: "";
  width: 74.47916666666667vw;
  height: 200%;
  clear: both;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: -1;
}

/* line 226, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#header_page_recherche #titleBox:after {
  display: table;
  content: "";
  clear: both;
}

/* line 231, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#header_page_recherche .vague {
  position: absolute;
  right: 0;
  bottom: 0;
}

/* line 236, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#header_page_recherche h1 {
  position: absolute;
  top: 50%;
  right: 8.333333333333334vw;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 4.0625vw;
  font-family: sabloReg;
  line-height: 4.0625vw;
  color: #002131;
  text-align: right;
}

@media screen and (max-width: 420px) {
  /* line 236, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #header_page_recherche h1 {
    font-size: 8vw !important;
  }
}

/* line 253, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.content_results {
  width: 90%;
  margin: 0 auto;
  min-height: 10vw;
  padding: 20px;
}

/* line 259, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
section.content_results article {
  margin: 30px 0;
  position: relative;
}

/* line 260, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
section.content_results article h2 {
  font-size: 2.6vw;
}

@media all and (max-width: 767px) {
  /* line 260, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  section.content_results article h2 {
    font-size: 3.5vw;
  }
}

@media all and (max-width: 580px) {
  /* line 260, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  section.content_results article h2 {
    font-size: 5vw;
  }
}

/* line 272, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
section.content_results article::before {
  content: "•";
  position: absolute;
  left: -32px;
  top: 0;
  font-size: 31px;
  line-height: 1;
  color: #69e5f4;
  font-weight: bold;
}

/* line 282, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
section.content_results article .date, section.content_results article .author, section.content_results article .comments {
  display: none;
}

/* line 285, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#productPageUsage #topUsage.usage #titleBox:before {
  background-image: url(../images/vague-prod3.png) !important;
}

/* line 288, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.couvercles_page#productPageUsage #topUsage #titleBox:before {
  background-image: url(../images/vague-prod.png) !important;
}

/* line 292, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.meubles_ext#productPageUsage #topUsage #titleBox:before {
  background-image: url(../images/eau-meubles-exterieur.png) !important;
}

/* line 295, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.spas_nage_page#productPageUsage #topUsage #titleBox:before {
  background-image: url(../images/vague-prod2.png) !important;
}

/* line 298, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#garantieOldbuy:before {
  background-image: url(../images/vague_accueil-garantie.png) !important;
}

/* line 301, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.prodInt .contenu {
  text-align: center;
  margin: 0;
}

/* line 305, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.prodInt .contenu h5, .prodInt .contenu span.year {
  line-height: 1;
  margin: 5px 0;
}

/* line 309, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#loadMore {
  margin: 0 auto 50px !important;
  transition: all 500ms  !important;
  position: relative !important;
  font-size: 18px !important;
  line-height: 1.5 !important;
}

/* line 316, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#loadMore::after {
  background: #69e5f4;
  position: absolute;
  display: table;
  content: "";
  bottom: -.625vw;
  width: 100%;
  height: 4px;
  -webkit-transition-property: width;
  transition-property: width;
  -webkit-transition-duration: .3s;
  transition-duration: .3s;
}

/* line 329, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#loadMore:hover::after {
  width: 0px;
}

/* line 332, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.border_blue {
  width: 100%;
  max-width: 98px;
  height: 4px;
  background-color: #69e5f4;
  transition: all 500ms;
}

/* line 339, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#products #left form input[type=submit]:hover .border_blue {
  width: 0px;
}

/* line 342, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#productPageUsage #products #left #promoSlide #pSlide .slick-list .slick-track .slick-slide .slideInt {
  -webkit-font-smoothing: antialiased;
}

/* line 345, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
footer #wrapFooter #infos .partInfo#coords span {
  max-width: 300px;
}

/* line 348, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* line 353, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
.page-numbers {
  font-size: 18px;
  line-height: 1;
  padding: 0 5px;
}

/* line 358, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#aboutService .wrap #servList .slick-list .slick-track .slick-slide.slick-current span {
  background: #69e5f4;
  color: #fff;
  font-family: sabloReg;
}

/* line 363, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#aboutService:before {
  background-image: url(../images/vagues_services.png);
}

/* line 366, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
#blocVague:before {
  background-image: url(../images/vague_entreprise.png);
}

/* line 369, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
div#tete p {
  display: block;
  margin-top: 30px;
  color: #002131;
  font-size: .8854166666666667vw;
  font-family: robotoReg;
  letter-spacing: .01em;
  text-transform: none;
}

/*********** RESPONSIVE ************/
@media screen and (max-width: 1300px) {
  /* line 381, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #introUse #sceau {
    top: -7vw;
  }
}

@media screen and (max-height: 900px) {
  /* line 386, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #popReserve #contenu {
    top: 10vw;
  }
}

@media screen and (max-width: 991px) {
  /* line 391, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #montre #listNewSpas {
    height: auto;
    display: flex;
    flex-direction: column-reverse;
  }
  /* line 396, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #introNew #right #contenu #bottom .partBottom img {
    height: 40px;
    width: 40px;
    margin: 0 auto 10px;
  }
  /* line 401, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #introEntreprise .vague-rond-bleu {
    display: none;
  }
  /* line 405, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #introEntreprise #left {
    max-width: unset;
  }
  /* line 409, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  h1 {
    font-size: 30px !important;
    line-height: 1.2 !important;
    letter-spacing: normal !important;
  }
  /* line 415, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  p {
    font-size: 15px !important;
    line-height: 1.2 !important;
    letter-spacing: normal !important;
  }
  /* line 421, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #expert #blocExpert {
    position: relative;
    width: 90%;
    height: 50vw;
    float: none;
    top: 30px;
    right: 0;
    left: 5%;
  }
  /* line 431, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #salleMontre {
    margin: 0;
  }
  /* line 435, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #salleMontre #salleImg {
    width: 100%;
    height: 66.875vw;
  }
  /* line 440, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #salleMontre #contenu {
    background: #002131;
    position: relative;
    padding: 8.541666666666668vw 9.114583333333334vw 9.895833333333334vw 8.333333333333334vw;
    float: right;
    margin: -30px 0 0;
    z-index: 3;
    max-width: 95vw;
  }
  /* line 450, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #blocVague .circle-d,
  #inventaire .circle-d-f,
  #salleMontre #contenu .vague,
  #urgenceEntreprise .goutte {
    display: none;
  }
  /* line 457, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #inventaire #wrap {
    max-width: 90%;
  }
  /* line 461, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #inventaire #wrap h1 {
    color: #69e5f4;
    font-size: 34px !important;
    line-height: 1.5 !important;
    font-family: robotoBlack;
    text-transform: uppercase;
  }
  /* line 469, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #inventaire #wrap p {
    position: relative;
    top: auto;
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    padding-left: 9%;
    text-align: left;
  }
  /* line 479, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #blocVague #wrap {
    position: relative;
    max-width: 90%;
    margin: 0 auto;
    z-index: 2;
  }
  /* line 486, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #blocVague #wrap .partBloc {
    float: none;
    width: 100%;
  }
  /* line 491, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #blocVague #wrap .partBloc .blocInt {
    max-width: unset;
    margin: 50px 0;
  }
  /* line 496, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #blocVague #wrap .partBloc .blocInt h1 {
    font-size: 44px !important;
    line-height: 1 !important;
  }
  /* line 501, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  section#urgenceEntreprise #wrap {
    max-width: unset !important;
  }
  /* line 505, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #urgenceEntreprise #wrap h1 {
    font-size: 50px !important;
    position: relative;
    float: none;
    text-align: right;
    margin: 0;
    max-width: unset;
    padding-right: 30px;
  }
  /* line 515, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #urgenceEntreprise #wrap p {
    color: #fff;
    font-size: 1.3541666666666667vw;
    line-height: 2.03125vw;
    letter-spacing: 0.01em;
    max-width: 60%;
    text-align: right;
    padding: 15px;
    margin: -55px 0 50px 20px;
  }
  /* line 525, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #introContact {
    height: 400px;
  }
  /* line 528, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #introContact #left {
    width: 100%;
    height: 100%;
    float: none;
  }
  /* line 533, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #introContact #right, #formContact .circle-d, #introServ .circle-d, #introServ .vague-rond, #introServ:after, .goutte, .goutte-w, .vague-bleu, .circle-d, .vague-rond-bleu, .vague {
    display: none;
  }
  /* line 536, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #formContact .wrap .contenu #right {
    position: relative;
    top: auto;
    bottom: auto;
    right: auto;
    left: auto;
    width: 100%;
    transform: none;
  }
  /* line 545, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #formContact .wrap {
    position: relative;
    margin: 0 0 0 auto;
    max-width: unset;
  }
  /* line 550, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #formContact .wrap .contenu {
    background: none;
  }
  /* line 553, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #formContact .wrap h1 {
    padding-left: 5%;
  }
  /* line 556, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #formContact .wrap .contenu #left {
    width: 95%;
    background: #002131;
    margin: 0 0 0 5%;
    padding: 20px;
  }
  /* line 562, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #formContact .wrap .contenu #left h4 {
    font-size: 24px;
    margin: 15px 0;
  }
  /* line 566, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #formContact .wrap .contenu #left #coords span {
    display: block;
    color: #fff;
    font-size: 16px;
    font-family: robotoReg;
    line-height: 1.2;
    letter-spacing: normal;
  }
  /* line 574, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #formContact .wrap .contenu #left #coords #social h5 {
    display: block;
    color: #69e5f4;
    font-size: 20px;
    font-family: robotoMed;
    line-height: 1;
    letter-spacing: normal;
    text-transform: uppercase;
    margin: 15px 0;
  }
  /* line 584, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #formContact .wrap .contenu #left #coords #social ul li {
    width: 20px;
    height: 20px;
  }
  /* line 588, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li label {
    font-size: 16px;
    letter-spacing: normal;
  }
  /* line 592, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container input, #formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container select, #formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_body ul li .ginput_container textarea {
    font-size: 16px;
    line-height: 100%;
    letter-spacing: normal;
  }
  /* line 597, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #formContact .wrap .contenu #right #rightInt .gform_wrapper form .gform_footer input {
    font-size: 16px;
  }
  /* line 600, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #formContact #urgenceContact .corpsWrap .contenu #right {
    display: block;
  }
  /* line 607, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #popReserve #contenu, #popOld #contenu .gform_wrapper, #popOld #contenu {
    width: 90%;
    max-width: unset;
    margin-top: 22vw;
  }
  /* line 612, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #popReserve #contenu .gform_wrapper {
    max-width: unset;
    padding: 15px;
  }
  /* line 616, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #popReserve #contenu .gform_wrapper form .gform_body ul li, #popOld #contenu .gform_wrapper form .gform_body ul li {
    float: none;
    width: 100%;
    clear: none;
    padding: 0 10px;
    margin: 0 0 30px;
  }
  /* line 623, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #popReserve #contenu .gform_wrapper form .gform_footer input, #popOld #contenu .gform_wrapper form .gform_footer input {
    font-size: 16px;
    height: 20px;
    line-height: 1;
    padding-bottom: 5px;
  }
  /* line 629, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #popOld #contenu #forClose, #popReserve #contenu #forClosea {
    top: 4%;
    z-index: 999;
  }
  /* line 633, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #introServ .contenu {
    padding: 15px;
    max-width: unset;
  }
  /* line 637, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #introServ .contenu h1 {
    max-width: unset;
  }
  /* line 640, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #introServ #introImg {
    position: relative;
    width: 95%;
    height: 300px;
    right: auto;
    bottom: auto;
    margin: 50px 0 0 auto;
  }
  /* line 648, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #buyBroken {
    height: auto;
  }
  /* line 651, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #buyBroken .contenu {
    padding: 5%;
    padding-bottom: 150px;
  }
  /* line 655, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #buyBroken .contenu h1 {
    float: none;
    padding: 0;
    width: 100%;
    margin: 30px 0;
  }
  /* line 661, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #buyBroken .contenu #right, #conseil #right {
    float: none;
    width: 100%;
    max-width: unset;
  }
  /* line 666, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #buyBroken .contenu #right p, #conseil #right .contenu p {
    max-width: unset;
    margin: 12px 0;
  }
  /* line 670, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #buyBroken .contenu #right p + p, #conseil #right .contenu p + p {
    margin: 12px 0;
  }
  /* line 673, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #conseil {
    position: relative;
    padding: 0 0 200px 0;
    display: flex;
    flex-direction: column-reverse;
  }
  /* line 682, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #conseil #conseilImg {
    position: relative;
    top: auto;
    left: auto;
    width: 95%;
    height: 68vw;
    z-index: 2;
  }
  /* line 690, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #conseil #right .contenu h1 {
    margin: 30px 0 !important;
    max-width: 100%;
    float: none;
  }
  /* line 695, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #aboutService .wrap {
    width: 100%;
    max-width: unset;
    float: none;
    position: relative;
    display: flex;
    flex-direction: column-reverse;
  }
  /* line 703, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #aboutService .wrap #servListContent {
    width: 100%;
    float: none;
    left: auto;
  }
  /* line 708, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #aboutService .wrap #servList {
    background: transparent;
    width: 100%;
    position: relative;
    float: none;
    top: auto;
    left: auto;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: none;
  }
  /* line 719, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #aboutService .wrap #servList .slick-list .slick-track .slick-slide {
    width: 100% !important;
  }
  /* line 722, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #aboutService {
    padding: 0;
    background: none;
  }
  /* line 726, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #aboutService .wrap #servList .slick-list .slick-track .slick-slide span {
    padding: 10px;
    font-size: 15px;
  }
  /* line 730, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #servList .slick-slider .slick-track, #servList .slick-slider .slick-list {
    height: 100% !important;
  }
  /* line 733, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemInt {
    position: relative;
    max-width: unset;
    margin: 0;
    z-index: 1;
    padding: 15px;
  }
  /* line 740, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemTitle {
    bottom: 0%;
    left: 18px;
    max-width: 300px;
  }
  /* line 745, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #aboutService .wrap #servListContent .slick-list .slick-track .slick-slide .itemTitle span {
    font-size: 60px;
    line-height: 55px;
    max-width: unset;
  }
  /* line 750, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #financement {
    padding: 50px 0;
  }
  /* line 753, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #financement .contenu {
    position: relative;
    max-width: unset;
    margin: 0;
  }
  /* line 758, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #financement .contenu h1 {
    max-width: 95%;
    margin: 0 auto;
  }
  /* line 762, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #financement .contenu p {
    position: relative;
    top: auto;
    right: auto;
    transform: none;
    max-width: 80%;
    margin: -40px auto 0;
    text-align: left;
  }
  /* line 771, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #financement .contenu img {
    display: block;
    width: 50%;
    height: auto;
    max-height: 120px;
    right: auto;
    position: relative;
    top: auto;
    margin-top: 90px;
    margin-left: auto;
    margin-right: auto;
  }
  /* line 783, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #urgenceServ .corpsWrap {
    width: 100%;
    max-width: 95%;
    margin: 0;
    height: 100%;
    float: none;
  }
  /* line 790, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #urgenceServ .corpsWrap .contenu #right {
    display: block;
    width: 60vw;
    height: 40vw;
    right: -7vw;
  }
  /* line 796, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #necessaire {
    background: #3990ae;
    padding: 30px 0 60px;
  }
  /* line 800, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #necessaire .wrap {
    text-align: center;
    max-width: 95%;
    margin: 0 auto;
  }
  /* line 805, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #necessaire .wrap h1 {
    max-width: 95%;
    margin: 30px auto;
  }
  /* line 809, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #distributeurs {
    padding: 30px 0;
  }
  /* line 812, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #distributeurs .contenu {
    margin: 0 auto;
    max-width: 95%;
  }
  /* line 816, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #distributeurs .contenu .secTitle span {
    font-size: 18px;
  }
  /* line 819, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #distributeurs .contenu .secTitle {
    margin: 30px 0;
  }
  /* line 822, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #fonctionnalite .wrap h1 {
    font-size: 16px !important;
  }
  /* line 825, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer, footer #wrapFooter {
    height: auto;
    min-height: 24vw;
    padding: 60px 0 80px;
  }
  /* line 830, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer #wrapFooter #infos .partInfo#coords span {
    word-break: break-word;
  }
  /* line 833, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #similar #similarList .corpsSide .corpInt .sim-by-sim {
    width: 33.3333333333% !important;
  }
  /* line 836, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer #wrapFooter #logo {
    position: relative;
    top: auto;
  }
  /* line 840, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer #wrapFooter #logo a img {
    height: 80px;
    margin: 0 auto;
  }
  /* line 844, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer #wrapFooter #infos {
    position: relative;
    top: auto;
    max-width: 100%;
    height: auto;
  }
  /* line 850, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer #wrapFooter #infos .partInfo {
    position: relative;
    top: auto;
    left: auto !important;
    margin: 30px auto 0;
    display: table;
    text-align: center;
  }
  /* line 858, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer #wrapFooter #footBottom {
    position: relative;
    bottom: auto;
  }
  /* line 862, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer #wrapFooter #footBottom a, footer #wrapFooter #footBottom p {
    margin: 30px auto 0;
    text-align: center;
    display: table;
  }
  /* line 867, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #introUse #sceau {
    top: -7vw;
  }
}

@media screen and (max-width: 991px) and (min-width: 767px) {
  /* line 867, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #introUse #sceau {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  /* line 875, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #products #left {
    padding-top: 20px;
  }
  /* line 878, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer #wrapFooter #logo, #coords h5, footer #wrapFooter #infos .partInfo#store, footer #wrapFooter #infos .partInfo#information, footer #wrapFooter #infos .partInfo#accordD {
    display: none;
  }
  /* line 881, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer {
    padding: 0 !important;
  }
  /* line 884, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer, footer #wrapFooter {
    padding-top: 20px;
  }
  /* line 887, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer #wrapFooter #infos .partInfo#coords span {
    max-width: unset;
    line-height: 1.2;
  }
  /* line 892, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer #wrapFooter #infos .partInfo#coords #social ul li {
    width: 50px;
    height: 50px;
    margin: 0 10px;
  }
  /* line 897, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer #wrapFooter #infos .partInfo#coords #social ul li a i {
    font-size: 25px;
  }
  /* line 900, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  footer #wrapFooter #infos .partInfo#coords #social ul {
    margin: 30px 0;
    justify-content: center;
  }
  /* line 904, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #garantieOldbuy .corpsSide #oldBuy .contenu h2 {
    max-width: 100%;
    font-size: 38px !important;
    line-height: 1;
  }
  /* line 909, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #garantieOldbuy .corpsSide #oldBuy {
    height: auto;
    padding: 20px;
  }
  /* line 913, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #garantieOldbuy .corpsSide #oldBuy #oldimg {
    display: block;
    right: -30px;
    bottom: 0;
    -webkit-transform: translateY(50%);
    -ms-transform: translateY(50%);
    transform: translateY(50%);
  }
  /* line 921, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #garantieOldbuy .corpsSide #oldBuy .contenu {
    padding-bottom: 64px;
  }
  /* line 924, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #garantieHome .corpsWrap .contenu {
    max-width: 620px;
    display: flex;
    flex-direction: column-reverse;
  }
  /* line 929, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #garantieHome .corpsWrap .contenu #right {
    display: flex;
    width: auto;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 15px;
  }
  /* line 936, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #garantieHome .corpsWrap .contenu #right img {
    display: block;
    width: 25vw;
    height: auto;
    margin: 0;
  }
  /* line 942, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #urgenceHome .corpsWrap .contenu #right {
    display: block;
  }
  /* line 945, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #garantieHome .corpsWrap .contenu #right {
    justify-content: flex-start;
  }
}

@media screen and (max-width: 600px) {
  /* line 953, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  div#tete p {
    display: block;
    color: #002131;
    font-size: 4vw;
    font-family: robotoReg;
    letter-spacing: .01em;
    text-transform: none;
  }
  /* line 961, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #popOld #contenu #forClose, #popReserve #contenu #forClosea {
    top: 5%;
    z-index: 999;
  }
  /* line 965, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #navPanelMobile ul li a {
    display: block;
    color: #fff;
    font-size: 16px;
    font-family: robotoBold;
    letter-spacing: .01em;
    text-transform: uppercase;
    padding: 5px 25px;
  }
  /* line 974, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #navPanelMobile #adresseForNav span {
    font-size: 16px;
  }
  /* line 977, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #navPanelMobile #social ul li a {
    font-size: 35px;
    color: #fff;
  }
  /* line 981, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #navPanelMobile #adresseForNav {
    margin: 20px 0 0;
  }
  /* line 984, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #introUse #sceau {
    top: 0vw;
  }
  /* line 988, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #fonctionnalite {
    overflow: hidden;
  }
  /* line 991, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  header #wrapHead nav .navInt ul li#search {
    position: relative;
    width: auto;
    top: auto;
    left: auto;
    transform: none;
    margin-right: 30px !important;
  }
  /* line 999, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  .search .search-input.visible {
    width: 100%;
    opacity: 1;
    line-height: 1.2;
    height: 100%;
  }
  /* line 1005, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #header_page_recherche #titleBox {
    height: 30vw;
  }
}

@media screen and (max-width: 580px) {
  /* line 1013, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #similar #similarList .corpsSide .corpInt .sim-by-sim {
    width: 100% !important;
  }
  /* line 1017, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #marques .corpsWrap #listMarques .wrapMarque + .wrapMarque {
    border: none;
  }
}

@media screen and (max-width: 782px) {
  /* line 1027, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #garantieHome .corpsWrap .contenu #right {
    justify-content: flex-start;
  }
  /* line 1033, public_html/ew-frame/content/themes/ew/assets/src/scss/layout/_l-patch-css.scss */
  #garantieHome .corpsWrap .contenu #right img {
    width: 33vw;
  }
}
