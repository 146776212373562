
#wrapperPage{
	overflow:hidden;
}
#intro{
	height: calc(100vh - 140px);
	@media screen and (min-width: 1920px){
		height: calc(100vh - 7.291666666666667vw);
	}
	@media screen and (max-width: 991px){
// 		height: calc(100vh - 100px);
	display:none;
	}
	@media screen and (max-width: 767px){
		display: none;
	}
	img{
		position: absolute;
		display: block;
		top: 50%;
		left: 50%;
		z-index: 2;
		transform: translate(-50%, -50%);
		@media screen and (max-width: 991px){
			width: 100%;
			max-width: 80%;
			height: auto;
			margin: 0 auto;
		}
	}
	.partIntro{
		float: left;
		width: 50%;
		height: 100%;
		&#left{
			background-size: cover;
			background-repeat: no-repeat;
			background-position: right center;
			position: relative;
			&:after{
				background: rgba(0, 0, 0, 0.3);
				position: absolute;
				display: block;
				content: "";
				width: 100%;
				height: 100%;
			}
		}
		&#right{
			overflow: hidden;
			display:flex;
			video{
				width: 100%;
				height: 86vh;
				object-fit: cover;
			}
		}
	}
}

#promoMobile{
	display: none;
	height: auto;
	overflow:hidden;
	#promoListMob{
		height: 100%;
		.slick-dots{
			position: relative;
			display: table;
			width: auto;
			padding: 0;
			margin: 0 auto;
			top: initial;
			left: initial;
			right: initial;
			    bottom: -11px;
			li{
				background: transparent;
				border: 1px solid #000;
				width: 15px;
				height: 15px;
				border-radius: 15px;
				transition-property: background;
				transition-duration: 0.4s;
				margin: 0;
				& + li{
					margin: 0 0 0 10px;
				}
				button{

					&:before{
						display: none;
					}
				}
				&.slick-active{
					background: #000;
				}
			}
		}
		.slick-list{
			height: 100%;
			.slick-track{
				height: 100%;
				.slick-slide{
					@include bp('m'){
						    min-height: 55vw;
						    background-size: contain;
    				background-position: center;

					}
					background-repeat: no-repeat;
					background-position: center top;
					background-size: 100% auto;
					position: relative;
					height: 100%;
					min-height: 275px;

					.slideInt{
    padding: 16vw 15.9vw;
    margin: 1.36vw auto 0;
						border: 5px solid $bleu-f;
						max-width: 90%;
						text-align: center;

						h2{
							font-size: 28px;
							font-family: $r-bold;
							letter-spacing: 0.01em;
							line-height: 34px;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 767px){
		display: block;
	}
}
#refurbished{
	overflow:hidden;
}
#promotions{
	@media screen and (max-width: 767px){
		display: none;
	}
	#promoList{
		position: relative;
		height: 57.917vw;
		margin: 0 !important;
		.slick-dots{
			position: absolute;
			left: 8.958333333333334vw;
			bottom: 3.385416666666667vw;
			display: table;
			width: auto;
			padding: 0;
			margin: 0;
			li{
				background: transparent;
				border: 1px solid #000;
				width: 1.09375vw;
				height: 1.09375vw;
				border-radius: 1.09375vw;
				transition-property: background;
				transition-duration: 0.4s;
				margin: 0;
				& + li{
					margin: 0 0 0 0.78125vw;
				}
				button{

					&:before{
						display: none;
					}
				}
				&.slick-active{
					background: #000;
				}
			}
		}
		.slick-list{
			height: 100%;
			.slick-track{
				height: 100%;
				.slick-slide{
					background-repeat: no-repeat;
					background-position: center center;
					background-size: cover;
					position: relative;
					height: 100%;

					.toBloc{
						position: absolute;
						width: calc(100% - (8.958333333333334vw * 2));
						height: calc(100% - (6.041666666666667vw * 2));
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);

						.bloc{
							background: #fff;
							position: relative;
							border-width: 0.9375vw;
							border-style: solid;
							&:nth-child(1){
								width: 100%;
								max-width: 32.34375vw;
								height: 100%;
								border-color: $bleu-p;
								float: left;
								color: $bleu-p;
								.contenu{
									padding: 12.395833333333334vw 0 0 0;
									h2{
										max-width: 19.791666666666668vw;
										margin: 0 auto;
									}
									h3{
										max-width: 21.354166666666668vw;
										margin: 0 auto;
										&:before{
											background: $bleu-p;
										}
									}
								}
							}
							&:nth-child(2){
								background: $bleu-f;
								width: 100%;
								max-width: 44.739583333333336vw;
								height: calc(50% - 2.447916666666667vw);
								border-color: $bleu-f;
								float: right;
								color: #fff;
								border: none;
								.contenu{
									padding: 2.760416666666667vw 0 0 0;
									h2{
										max-width: 25.885416666666668vw;
										margin: 0 auto;
									}
									h3{

										&:before{
											background: #fff;
										}
									}
								}
							}
							&:nth-child(3){
								width: 100%;
								max-width: 44.739583333333336vw;
								height: calc(50% - 2.447916666666667vw);
								border-color: $bleu-t;
								position: absolute;
								right: 0;
								bottom: 0;
								color: $bleu-t;
								.contenu{
									padding: 3.125vw 0 0 0;
									h2{

									}
									h3{
										max-width: 29.166666666666668vw;
										margin: 0 auto;
										&:before{
											background: $bleu-t;
										}
									}
								}
							}

							.contenu{
								position: absolute;
								height: auto;
								width: 100%;
								top: 50%;
								left: 0;
								transform: translate(0, -50%);
								text-align: center;
								padding: 0 !important;
								h2{
									font-size: 2.7083333333333335vw;
									font-family: $r-bold;
									letter-spacing: 0.01em;
									text-transform: uppercase;
									line-height: 2.916666666666667vw;
								}
								h3{
									font-size: 1.7708333333333335vw;
									font-family: $r-reg;
									letter-spacing: 0.01em;
									line-height: 2.03125vw;
									&:before{
										position: relative;
										display: block;
										content: "";
										margin: 2.0833333333333335vw auto 1.3020833333333335vw;
										width: 5.208333333333334vw;
										height: 2px;
									}
								}
								p{
									position: absolute;
									bottom: 1.0416666666666667vw;
									text-align: center;
									padding: 0 3.125vw;
									font-size: 0.7291666666666667vw;
									font-family: $r-italic;
									line-height: 0.8854166666666667vw;
									@media screen and (max-width: 1280px){
										font-size: 1.1vw;
										line-height: 1.2vw;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

#refurbished{

	.corpsSide{
		max-width: calc((100% - 8.958333333333334vw) + 2.447916666666667vw);
		height: 59.375vw;
		@media screen and (max-width: 991px){
			max-width: calc((100% - 8.958333333333334vw * 2) + 2.447916666666667vw);
			margin: 0 auto;
			height: auto;
		}
		#left{
			background: $bleu-f;
			float: left;
			width: 41.35416666666667vw;
			height: 100%;
			@media screen and (max-width: 991px){
				    
				    width: 90vw;
				float: initial;
				height: auto;
				margin: 0 0vw 60px -10vw;
			}
			.contenu{
				width: calc(100% - 8.229166666666668vw);
				text-align: right;
				@media screen and (max-width: 991px){
					width: 100%;
					padding: 20px 60px 40px 0;
					position: relative;
				}
				#img{
					background-image: url(../images/remis-a-neufs-presentation.png);
					background-size: cover;
					background-repeat: no-repeat;
					background-position: left bottom;
					position: relative;
					width: 100%;
					height: 31.25vw;
					opacity: 0.25;
					margin: 0 0 3.385416666666667vw 0;
					@media screen and (max-width: 991px){
						position: absolute;
						left: 0;
						bottom: 0;
						width: 300px;
						height: 100%;
						margin: 0;
					}
					&:after{
						background: #002131;
						position: absolute;
						display: block;
						content: "";
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						opacity: 0.5;
					}
				}
				h3{
					color: #fff;
					font-size: 4.0625vw;
					font-family: $s-reg;
					letter-spacing: 0.01em;
					line-height: 4.0625vw;
					max-width: 17.96875vw;
					float: right;
					margin: 0 0 2.8645833333333335vw 0;
					@media screen and (max-width: 1280px){
						font-size: 52px;
						line-height: 52px;
						max-width: 230px;
					}
					@media screen and (max-width: 991px){

					}
					@media screen and (max-width: 420px){
						font-size: 47px;
						line-height: 47px;
					}
				}
				a{
					position: relative;
					display: table;
					color: $bleu-p;
					font-size: 1.09375vw;
					font-family: $r-med;
					letter-spacing: 0.01em;
					text-transform: uppercase;
					clear: both;
					float: right;
					transition-property: color;
					transition-duration: 0.3s;
					transition-delay: 0.3s;
					z-index: 1;
					height: 1.6145833333333335vw;
					@media screen and (max-width: 1280px){
						width: 162px;
    					font-size: 14px;
						height: 20px;
					}
					@media screen and (max-width: 991px){

					}
					&:before{
						background: $bleu-p;
						position: absolute;
						display: none;
						content: "";
						bottom: -0.625vw;
						width: 0px;
						height: 0px;
						transition-property: width, height;
						transition-duration: 0.3s, 0.3s;
						transition-delay: 0.3s, 0.3s;
						z-index: -1;
						left: 50%;
						transform: translate(-50%, 0);
						@media screen and (max-width: 1280px){
							bottom: -7px;
						}
					}
					&:after{
						background: $bleu-p;
						position: absolute;
						display: table;
						content: "";
						bottom: -0.625vw;
						width: 100%;
						height: 4px;
						transition-property: width;
						transition-duration: 0.3s;
						@media screen and (min-width: 1920px){
							height: 0.20833333333333334vw;

						}
						@media screen and (max-width: 1280px){
							bottom: -7px;
						}
					}
					&:hover{
						color: #fff;
						&:before{
							width: calc(100% + 1.5625vw);
							height: calc(100% + (0.625vw * 2));
							@media screen and (max-width: 1280px){
								width: 179px;
								height: 36px;
							}
						}
						&:after{
							width: 0;
						}
					}
				}
			}
		}
		#right{
			float: left;
			width: calc(100% - 41.35416666666667vw);
			padding: 0 0 0 2.447916666666667vw;
			@media screen and (max-width: 991px){
				float: initial;
				width: 100%;
				padding: 0;
			}
			#rightInt{

				.spa-by-spa{
					float: left;
					width: 50%;
					padding: 0 2.447916666666667vw;
					margin: 0 0 2.0833333333333335vw 0;
					@media screen and (max-width: 991px){
						margin: 0 0 30px 0;
					}
					@media screen and (max-width: 580px){
						width: 100%;

					}
					.spasImg{
						background-size: contain;
						background-position: center center;
						background-repeat: no-repeat;
						position: relative;
						width: 100%;
						height: 19.895833333333336vw;
						display: block;
						@media screen and (max-width: 991px){
							height: 250px;
						}
						@media screen and (max-width: 767px){
							height: 200px;
						}
						@media screen and (max-width: 420px){
							height: 175px;
						}
						.new{
							background-color: #f28136;
							display: block;
							position: absolute;
							width: 6.302083333333334vw;
							height: 6.302083333333334vw;
							border-radius: 6.302083333333334vw;
							top: 1.40625vw;
							right: calc(-0.5208333333333334vw);
							@media screen and (max-width: 991px){
								width: 80px;
								height: 80px;
								border-radius: 80px;
							}
							span{
								display: block;
								position: absolute;
								width: 100%;
								top: 50%;
								left: 0;
								transform: translate(0, -50%);
								text-align: center;
								color: #fff;
								font-size: 1.1458333333333335vw;
								line-height: 1.1458333333333335vw;
								letter-spacing: 0.01em;
								@media screen and (max-width: 991px){
									font-size: 16px;
									line-height: 16px;
								}
							}
						}
					}
					.contenu{
						text-align: center;
						margin: 1.0416666666666667vw 0 0 0;
						h5{
							color: $bleu-f;
							font-size: 0.9895833333333334vw;
							font-family: $r-bold;
							letter-spacing: 0.01em;
							@media screen and (max-width: 1280px){
								font-size: 14px;
							}
						}

						span{
							display: block;
							&.year{
								color: $bleu-f;
								font-size: 0.9895833333333334vw;
								font-family: $r-reg;
								letter-spacing: 0.01em;
								@media screen and (max-width: 1280px){
									font-size: 14px;
								}
							}
							&.price{
								color: $bleu-p;
								font-size: 0.9895833333333334vw;
								font-family: $r-bold;
								letter-spacing: 0.01em;
								@media screen and (max-width: 1280px){
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}
	}
}

#montre{
	    margin-left: 10vw;
	position: relative;
	margin: -8.020833333333334vw 0 0 0;
	padding: 22.135416666666668vw 0 0 0;
	clear: initial;
	@media screen and (max-width: 991px){
		margin: 0;
	}
	&:before{
		background-image: url(../images/vague-bg.png);
		background-size: 100% auto;
		background-position: center top;
		background-repeat: no-repeat;
		position: absolute;
		display: block;
		content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
	&:after{
		background-color: $bleu-t;
		position: absolute;
		display: block;
		content: "";
		left: 0;
		bottom: 0;
		width: 100%;
		height: calc(100% - 9.895833333333334vw);
		z-index: -1;
	}
	#desc{
		position: relative;
		max-width: 55.78125vw;
		margin: 0 auto;
		@media screen and (max-width: 1280px){
			max-width: 714px;
		}
		@media screen and (max-width: 991px){
			padding: 0 40px;
		}
		p{
			position: absolute;
			width: 100%;
			height: auto;
			right: 0;
			top: 50%;
			transform: translate(0, -50%);
			text-align: center;
			max-width: 46.614583333333336vw;
			font-size: 1.3541666666666667vw;
			font-family: $s-reg;
			line-height: 2.03125vw;
			z-index: 2;
			color: #fff;
			@media screen and (max-width: 1280px){
				font-size: 17px;
				line-height: 26px;
				max-width: 598px;
			}
			@media screen and (max-width: 767px){
				padding: 0 40px;
			}
		}
		#descTitle{

			span{
				display: block;
				color: $bleu-p;
				font-size: 6.770833333333334vw;
				font-family: $r-black;
				letter-spacing: 0.01em;
				text-transform: uppercase;
				opacity: 0.25;
				line-height: 6.770833333333334vw;
				@media screen and (max-width: 1280px){
					font-size: 86px;
					line-height: 86px;
				}
				@media screen and (max-width: 580px){
					font-size: 60px;
					line-height: 60px;
				}
			}
		}
	}
	#listNewSpas{
		position: relative;
		height: 60vw;
		margin: 11.979166666666668vw 0 0 0;
		z-index: 1;
		.vague{
			position: absolute;
			right: 0;
			top: calc(-182px - 65px);
		}
		#left{
			background: #fff;
			width: 66.19791666666667vw;
			height: 100%;
			padding: 5.989583333333334vw 0 0 0;
			@media screen and (max-width: 991px){
				width: 100%;
				padding: 0;
				height: auto;
				padding: 60px 0 30px;
			}
			#leftIn{
				max-width: calc(100% - (8.854166666666668vw * 2));
				margin: 0 auto;
				.spa-by-spa{
					float: left;
					width: 50%;
					margin: 0 0 2.0833333333333335vw 0;
					@media screen and (max-width: 580px){
						width: 100%;
						margin: 0 0 40px 0;
					}
					.spasInt{
						max-width: 19.947916666666668vw;
						width: 100%;
						display: block;
						@media screen and (max-width: 991px){
							max-width: 90%;
							margin: 0 auto;
						}
						.spasImg{
							background-size: contain;
							background-position: center center;
							background-repeat: no-repeat;
							position: relative;
							width: 100%;
							height: 18.229166666666668vw;
							@media screen and (max-width: 991px){
								height: 250px;
							}
							@media screen and (max-width: 767px){
								height: 200px;
							}
							@media screen and (max-width: 420px){
								height: 175px;
							}
							.new{
								background: #f28136;
								display: block;
								position: absolute;
								width: 6.302083333333334vw;
								height: 6.302083333333334vw;
								border-radius: 6.302083333333334vw;
								top: 1.40625vw;
								left: calc(19.947916666666668vw - 6.302083333333334vw + 0.5208333333333334vw);
								@media screen and (max-width: 991px){
									width: 80px;
									height: 80px;
									border-radius: 80px;
									left: initial;
									right: 0;
								}
								span{
									display: block;
									position: absolute;
									width: 100%;
									top: 50%;
									left: 0;
									transform: translate(0, -50%);
									text-align: center;
									color: #fff;
									font-size: 1.1458333333333335vw;
									line-height: 1.1458333333333335vw;
									letter-spacing: 0.01em;
									@media screen and (max-width: 991px){
										font-size: 16px;
										line-height: 16px;
									}
								}
							}
						}
						.contenu{
							text-align: center;
							margin: 1.0416666666666667vw 0 0 0;
							h5{
								color: $bleu-f;
								font-size: 0.9895833333333334vw;
								font-family: $r-bold;
								letter-spacing: 0.01em;
								@media screen and (max-width: 1280px){
									font-size: 14px;
								}
							}

							span{
								display: block;
								&.year{
									color: $bleu-f;
									font-size: 0.9895833333333334vw;
									font-family: $r-reg;
									letter-spacing: 0.01em;
									@media screen and (max-width: 1280px){
										font-size: 14px;
									}
								}
								&.price{
									color: $bleu-p;
									font-size: 0.9895833333333334vw;
									font-family: $r-bold;
									letter-spacing: 0.01em;
									@media screen and (max-width: 1280px){
										font-size: 14px;
									}
								}
							}
						}
					}
				}
			}
		}
		#right{
			background: $bleu-f;
			position: absolute;
			top: 50%;
			right: 0;
			width: 41.35416666666667vw;
			height: calc(100% - (6.197916666666667vw * 2));
			transform: translate(0, -50%);
			@media screen and (max-width: 991px){

				margin-left:10vw;
				position: relative;
				top: initial;
				right: 0;
				    transform: initial;
				height: auto;
				width: 90vw;

			}
			.contenu{
				width: calc(100% - 3.8020833333333335vw);
				text-align: left;
				float: right;
				@media screen and (max-width: 991px){
					padding: 20px 60px 40px 0;
					 
    display: flex;
    flex-direction: column;
				}
				#img{
					background-image: url(../images/remis-a-neufs-presentation.png);
					background-size: cover;
					background-repeat: no-repeat;
					background-position: right bottom;
					position: relative;
					width: 100%;
					height: 26.510416666666668vw;
					opacity: 0.25;
					margin: 0 0 3.385416666666667vw 0;
					@media screen and (max-width: 991px){
						position: absolute;
						top: 0;
						left: 0;
						width: 300px;
						height: 100%;
					}
					&:after{
						background: #002131;
						position: absolute;
						display: block;
						content: "";
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						opacity: 0.5;
					}
				}
				h3{
					color: #fff;
					font-size: 4.0625vw;
					font-family: $s-reg;
					letter-spacing: 0.01em;
					line-height: 4.0625vw;
					max-width: 10.416666666666668vw;
					margin: 0 0 2.8645833333333335vw 0;
					@media screen and (max-width: 1280px){
						font-size: 52px;
						line-height: 52px;
						max-width: 135px;
					}
					@media screen and (max-width: 991px){
						float: right;
					}
					@media screen and (max-width: 420px){
						font-size: 47px;
						line-height: 47px;
					}
				}
				a{
					position: relative;
					display: table;
					color: $bleu-p;
					font-size: 1.09375vw;
					font-family: $r-med;
					letter-spacing: 0.01em;
					text-transform: uppercase;
					clear: both;
					transition-property: color;
					transition-duration: 0.3s;
					transition-delay: 0.3s;
					z-index: 1;
					height: 1.6145833333333335vw;
					@media screen and (max-width: 1280px){
						font-size: 14px;
						height: 20px;
					}
					@media screen and (max-width: 991px){
						float: right;
						    width: 159px;
    					font-size: 14px;
					}
					&:before{
						background: $bleu-p;
						position: absolute;
						display: table;
						content: "";
						bottom: -0.625vw;
						width: 0px;
						height: 0px;
						transition-property: width, height;
						transition-duration: 0.3s, 0.3s;
						transition-delay: 0.3s, 0.3s;
						z-index: -1;
						left: 50%;
						transform: translate(-50%, 0);
					}
					&:after{
						background: $bleu-p;
						position: absolute;
						display: table;
						content: "";
						bottom: -0.625vw;
						width: 100%;
						height: 4px;
						transition-property: width;
						transition-duration: 0.3s;
						@media screen and (min-width: 1920px){
							height: 0.20833333333333334vw;
						}
					}
					&:hover{
						color: #fff;
						&:before{
							width: calc(100% + 1.5625vw);
							height: calc(100% + (0.625vw * 2));
						}
						&:after{
							width: 0;
						}
					}
				}
			}
		}
	}
}

#marques{
	background: $bleu-t;
	padding: 11.875vw 0 10.677083333333334vw 0;
	.goutte{
		position: absolute;
		top: -1.5104166666666667vw;
		left: -0.78125vw;
	}
	.vague-rond-bleu{
		position: absolute;
		right: 0;
		bottom: 70px;
	}
	.corpsWrap{
		max-width: calc(100% - (9.010416666666668vw * 2));
		margin: 0 auto;

		#listMarques{
		@media screen and (max-width: 580px){
						display:flex;
			flex-wrap:wrap;
					}

			
			.wrapMarque{
						@media screen and (max-width: 580px){
								width:48%;
					}
			

				&:after{
					display: table;
					content: "";
					clear: both;
				}

				& + .wrapMarque{
					border-top: 1px solid #fff;

											@media screen and (max-width: 580px){
								&:nth-child(2){
						border-top: none;
						margin:1vw;
					}
					
					}
				}
				.marque-par-marque{
					position: relative;
					float: left;
					width: 25%;
					height: 19.010416666666668vw;
					
					&:first-child{
						&:after{
							background: #fff;
							position: absolute;
							display: block;
							content: "";
							width: 1px;
							height: 100%;
							right: 0;
							@media screen and (max-width: 580px){
								background:none;
							}

						}
					}
					&:nth-child(3){
						&:before{
							background: #fff;
							position: absolute;
							display: block;
							content: "";
							width: 1px;
							height: 100%;
							left: 0;
						}
						&:after{
							background: #fff;
							position: absolute;
							display: block;
							content: "";
							width: 1px;
							height: 100%;
							right: 0;
						}
					}
					@media screen and (max-width: 991px){
						width: 33.3333333333%;
						&:nth-child(3){
							&:after{
								display: none;
							}
						}
					}
					@media screen and (max-width: 767px){
						width: 50%;
						&:nth-child(3){
							&:after,
							&:before{
								display: none;
							}
						}
					}
					@media screen and (max-width: 580px){
						width:100%;
					}

					.marqueInt{
						position: absolute;
						width: 100%;
						top: 50%;
						transform: translate(0, -50%);
						
						h2{
							color: #fff;
							font-size: 4.0625vw;
							font-family: $s-reg;
							letter-spacing: 0.01em;
							line-height: 4.0625vw;
							padding: 0 3.125vw 0 0;
							@media screen and (max-width: 991px){
								font-size: 6vw;
								line-height: 6vw;
							}
							@media screen and (max-width: 767px){
								font-size: 7vw;
								line-height: 7vw;
							}
	
						}
						img{
							display: block;
							max-width: 80%;
							height: auto;
							margin: 0 auto;
						}
					}
				}
			}
		}
	}
}

#garantieHome{
	background: $bleu-t;
	padding: 4.427083333333334vw 0 0 0;
	height: 35.78125vw;
	@media screen and (max-width: 991px){
		height: auto;
	}
	.corpsWrap{
		max-width: calc(100% - (9.010416666666668vw * 2));
		margin: 0 auto;
		height: 100%;
		.contenu{
			max-width: calc(100% - 4.6875vw);
			margin: 0 auto;
			height: 100%;
			@media screen and (max-width: 991px){
				max-width: 620px;
			}
			#left{
				position: relative;
				float: left;
				width: 48.54166666666667vw;
				height: 100%;
				@media screen and (max-width: 991px){
					width: 100%;
					margin: 0 0 100px 0;
				}
				@media screen and (max-width: 767px){
					margin: 0 0 90px 0;
				}
				@media screen and (max-width: 580px){
					margin: 0 0 80px 0;
				}
				@media screen and (max-width: 420px){
					margin: 0 0 60px 0;
				}
				h1{
					position: absolute;
					right: 0;
					text-align: right;
					font-size: 6.770833333333334vw;
					font-family: $r-black;
					letter-spacing: 0.01em;
					text-transform: uppercase;
					color: $bleu-p;
					line-height: 6.770833333333334vw;
					max-width: 37.76041666666667vw;
					@media screen and (max-width: 991px){
						    top: -20vw;
						font-size: 86px;
						line-height: 86px;
						max-width: 80%;
						opacity: 0.2;
						font-size: 8vw!important;
						width:60%;
						line-height: 60px;
					}
					@media screen and (max-width: 767px){
						width:50%;
					}
					@media screen and (max-width: 580px){
						
					}
				}
				p{
					position: absolute;
					max-width: 26.40625vw;
					top: 7.291666666666667vw;
					left: 0;
					text-align: right;
					color: #fff;
					font-size: 1.3541666666666667vw;
					font-family: $s-reg;
					letter-spacing: 0.01em;
					line-height: 2.03125vw;
					@media screen and (max-width: 991px){
						font-size: 17px;
						line-height: 26px;
						max-width: 340px;
						position: relative;
						top: initial;
						z-index: 2;
						max-width:100%;
					}
					@media screen and (max-width: 767px){
						
					}
				}
			}
			#right{
				float: left;
				width: calc(100% - 48.54166666666667vw);
				@media screen and (max-width: 991px){
					display: none;
				}
				img{
					display: block;
					width: auto;
					height: 15.989583333333334vw;
					margin: -0.5208333333333334vw 0 0 4.166666666666667vw;
				}
			}
		}
	}
}

#urgenceHome{
	background: $bleu-t;
	padding: 0 0 7.1875vw 0;
	overflow:hidden;
	.corpsWrap{
		@include bp('m'){
			float:inherit;
			margin-left:0;
		}
		width: 100%;
		max-width: calc(100% - (9.010416666666668vw));
		margin: 0 auto;
		height: 100%;
		float: right;
		.contenu{
			position: relative;
			height: 35.78125vw;
			@media screen and (max-width: 1280px){
				height: auto;
			}
			#left{
				background: #fff;
				position: relative;
				width: 65.36458333333334vw;
				height: calc(100% - 1.8229166666666667vw);
				.goutte{
					position: absolute;
					top: calc(-15.46875vw / 2);
					right: 8.333333333333334vw;
				}
				@media screen and (max-width: 1280px){
					height: auto;
					padding: 60px 0;
				}
				@media screen and (max-width: 1200px){
					width: 80%;
					height: 643px;
					overflow:hidden;
				}
				@media screen and (max-width: 767px){
					width: 100%;
					    height: auto;
					        padding-bottom: 370px;
				}
								@media screen and (max-width: 580px){
					width: 100%;
					    height: auto;
					        padding-bottom: 80vw;
				}
				.leftInt{
					position: absolute;
					top: 50%;
					left: 4.114583333333334vw;
					transform: translate(0, -50%);
					max-width: 34.375vw;
					@media screen and (max-width: 1280px){
						position: relative;
						top: initial;
						transform: initial;
					}
					@media screen and (max-width: 1200px){
						max-width: 70%;
					}
					@media screen and (max-width: 767px){
						max-width: 80%;
					}
					h3{
						color: $color-text;
						font-size: 4.0625vw;
						font-family: $s-reg;
						letter-spacing: 0.01em;
						line-height: 4.0625vw;
						@media screen and (max-width: 1280px){
							font-size: 52px;
							line-height: 52px;
						}
					}
					p{
						color: $color-text;
						font-size: 0.8854166666666667vw;
						font-family: $r-reg;
						letter-spacing: 0.01em;
						line-height: 1.3541666666666667vw;
						margin: 3.4375vw 0 4.583333333333334vw 0;
						@media screen and (max-width: 1280px){
							font-size: 15px;
							line-height: 24px;
							margin: 40px 0 60px 0;
						}
					}
					a{
						position: relative;
						display: table;
						color: $bleu-p;
						font-size: 1.09375vw;
						font-family: $r-med;
						letter-spacing: 0.01em;
						text-transform: uppercase;
						clear: both;
						transition-property: color;
						transition-duration: 0.3s;
						transition-delay: 0.3s;
						z-index: 1;
						height: 1.6145833333333335vw;
						@media screen and (max-width: 1280px){
							font-size: 14px;
							height: 20px;
						}
						&:before{
							background: $bleu-p;
							position: absolute;
							display: table;
							content: "";
							bottom: -0.625vw;
							width: 0px;
							height: 0px;
							transition-property: width, height;
							transition-duration: 0.3s, 0.3s;
							transition-delay: 0.3s, 0.3s;
							z-index: -1;
							left: 50%;
							transform: translate(-50%, 0);
						}
						&:after{
							background: $bleu-p;
							position: absolute;
							display: table;
							content: "";
							bottom: -0.625vw;
							width: 100%;
							height: 4px;
							transition-property: width;
							transition-duration: 0.3s;
							@media screen and (min-width: 1920px){
								height: 0.20833333333333334vw;
							}
						}
						&:hover{
							color: #fff;
							&:before{
								width: calc(100% + 1.5625vw);
								height: calc(100% + (0.625vw * 2));
							}
							&:after{
								width: 0;
							}
						}
					}

				}

			}
			#right{
				background-image: url(../images/camion.png);
				background-size: auto 100%;
				background-position: left center;
				background-repeat: no-repeat;
				position: absolute;
				width: 49.739583333333336vw;
				height: 33.85416666666667vw;
				right: 0;
				bottom: 0;
				@media screen and (max-width: 1200px){
// 					transform: translateX(14vw);
				}
				@media screen and (max-width: 767px){
					overflow:hidden;
					display: none;
					    right: -9vw;
					        height: 44vw;
    width: 64vw;
					    

				}
				@media screen and (max-width: 580px){


					        height: 64vw;
    width: 84vw;
					    

				}
			}
		}
	}
}
