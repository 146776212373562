#vagueTopi{
	background-image: url(../images/eau-entreprise.png);
	background-position: left top;
	background-repeat: no-repeat;
	background-size: contain;
	position: absolute;
	top: 50%;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 57.29166666666667vw;
	animation-name: imgmoveS;
	animation-duration: 5s;
	animation-iteration-count: infinite;
}

#introEntreprise{
	z-index: 2;
	.vague-rond-bleu{
		position: absolute;
		top: 21.5625vw;
		right: 0;
	}
	#left{
		background: $bleu-t;
		width: 100%;
		max-width: 49.6875vw;
		padding: 6.510416666666667vw 4.010416666666667vw 7.03125vw 9.0625vw;
		h1{
			margin: 0 0 3.125vw 0;
			color: #fff;
			font-size: 4.0625vw;
			line-height: 4.0625vw;
			font-family: $s-reg;
			letter-spacing: 0.01em;
		}
		p{
			color: #fff;
			font-size: 0.8854166666666667vw;
			line-height: 1.3541666666666667vw;
			font-family: $r-reg;
			letter-spacing: 0.01em;
			& + p{
				margin: 1.0416666666666667vw 0 0 0;
			}
		}
	}
}

#expert{

	#blocExpert{
		position: relative;
		width: 48.85416666666667vw;
		height: 38.4375vw;
		float: right;
		top: -10vw;
		right: 9.0625vw;
		&:before{
			background: transparent;
			position: absolute;
			display: block;
			content: "";
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;
			border-top: 0.9375vw solid $bleu-p;
			border-left: 0.9375vw solid $bleu-p;
		}
		&:after{
			background: transparent;
			position: absolute;
			display: block;
			content: "";
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 3;
			border-right: 0.9375vw solid $bleu-p;
			border-bottom: 0.9375vw solid $bleu-p;
		}
		.blocInt{
			position: absolute;
			top: 50%;
			left: 0;
			width: 100%;
			text-align: center; 
			transform: translate(0, -50%);
			z-index: 4;
			span{
				display: block;
				color: $bleu-p;
				text-transform: initial;
				&:nth-child(1){
					font-size: 6.09375vw;
					font-family: $r-black;
					line-height: 6.302083333333334vw;
					letter-spacing: 0.01em;
					text-transform: uppercase;
				}
				&:nth-child(2){
					font-size: 4.166666666666667vw;
					font-family: $s-reg;
					line-height: 4.635416666666667vw;
					letter-spacing: 0.01em;
				}
				&:nth-child(3){
					font-size: 5.416666666666667vw;
					font-family: $r-black;
					line-height: 5.46875vw;
					letter-spacing: 0.01em;
					text-transform: uppercase;
				}
			}
		}
		#imgWithBloc{
			background-image: url(../images/eau-expert.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			background-position: center center;
			position: absolute;
			top: 50%;
			right: -9.0625vw;
			width: 49.739583333333336vw;
			height: 56.35416666666667vw;
			transform: translate(0, -50%);
			z-index: 2;
			animation-name: imgmoveS;
			animation-duration: 5s;
			animation-iteration-count: infinite;
		}
	}
}

#salleMontre{
	position: relative;
	margin: -43.489583333333336vw 0 0 0;
	clear: initial;
	z-index: 2;
	#salleImg{
		//background-image: url(../images/batisse.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		backgorund-position: 100% 100%;
		position: relative;
		width: 57.708333333333336vw;
		height: 66.875vw;
	}
	#contenu{
		background: $bleu-f;
		position: relative;
		padding: 8.541666666666668vw 9.114583333333334vw 9.895833333333334vw 8.333333333333334vw;
		float: right;
		margin: -23.75vw 0 0 0;
		z-index: 3;
		max-width: 74.47916666666667vw;
		.vague{
			position: absolute;
			left: -11.979166666666668vw;
			bottom: 4.114583333333334vw;
		}
		#contenuInt{
			h1{
				color: #fff;
				font-size: 4.0625vw;
				line-height: 4.0625vw;
				letter-spacing: 0.01em;
				font-family: $s-reg;
				margin: 0 0 3.385416666666667vw;
			}
			p{
				color: #fff;
				font-size: 0.8854166666666667vw;
				line-height: 1.3541666666666667vw;
				letter-spacing: 0.01em;
				font-family: $r-reg;
				word-break: break-all;
			}
		}
	}
}


#inventaire{
	padding: 8.75vw 0 16.145833333333336vw 0;
 	.circle-d-f{
		position: absolute;
		right: 13.020833333333334vw;
		bottom: 10.78125vw;
	}
	#wrap{
		position: relative;
		max-width: 75.3125vw;
		margin: 0 auto;
		&:after{
			display: table;
			content: "";
			clear: both;
		}
		h1{
			color: $bleu-p;
			font-size: 6.770833333333334vw;
			line-height: 6.770833333333334vw;
			font-family: $r-black;
			text-transform: uppercase;
		}
		p{
			position: absolute;
			top: 50%;
			right: 0;
			width: 100%;
			max-width: 57.03125vw;
			transform: translate(0, -50%);
			color: $bleu-f;
			font-size: 1.3541666666666667vw;
			font-family: $s-reg;
			line-height: 2.03125vw;
			letter-spacing: 0.01em;
			text-align: center;
		}
	}
}

#blocVague{
	background-color: $bleu-t;
	padding: 20.833333333333336vw 0 13.802083333333334vw 0;
	.circle-d{
		position: absolute;
		left: 9.0625vw;
		bottom: 3.90625vw;
	}
	&:before{
		background-image: url(../images/vague-bg.png);
		background-repeat: no-repeat;
		background-size: 100% auto;
		background-position: center top;
		position: absolute;
		display: block;
		content: "";
		    top: -9.552083vw;
		left: 0;
		width: 100%;
		height: 100%;
	}
	#wrap{
		position: relative;
		max-width: 82.8125vw;
		margin: 0 auto;
		z-index: 2;
		&:after{
			display: table;
			content: "";
			clear: both;
		}
		.partBloc{
			float: left;
			width: 50%;
			.blocInt{
				max-width: 36.458333333333336vw;
				&:after{
					display: table;
					content: "";
					clear: both;
				}
				&:nth-child(2){
					float: right;
				}
				h1{
					margin: 0 0 4.166666666666667vw 0;
					text-align: center;
					color: #fff;
					font-size: 4.0625vw;
					line-height: 4.0625vw;
					letter-spacing: 0.01em;
					font-family: $s-reg;
					margin: 0 0 4.427083333333334vw;
				}
				p{
					color: #fff;
					font-size: 0.8854166666666667vw;
					line-height: 1.3541666666666667vw;
					letter-spacing: 0.01em;
					font-family: $r-reg;
					& + p{

						margin: 0.78125vw 0 0 0;
					}
				}
			}
		}
	}
}

#urgenceEntreprise{
	background: $bleu-t;
	padding: 0 0 14.322916666666668vw;
	.goutte{
		position: absolute;
		top: 2.604166666666667vw;
		right: -0.9895833333333334vw;
	}
	#wrap{
		max-width: 57.5vw;
		margin: 0 auto;
		position: relative;
		&:after{
			display: table;
			content: "";
			clear: both;
		}
		h1{
			color: $bleu-p;
			font-size: 6.770833333333334vw;
			line-height: 6.770833333333334vw;
			font-family: $r-black;
			text-transform: uppercase;
			opacity: 0.25;
			max-width: 36.458333333333336vw;
			float: right;
			text-align: right;
			position: absolute;
			right: 0;
			top: 0;
		}
		p{
			color: #fff;
			font-size: 1.3541666666666667vw;
			line-height: 2.03125vw;
			letter-spacing: 0.01em;
			max-width: 39.895833333333336vw;
			text-align: right;
			padding: 13.541666666666668vw 0 0 0;
		}
	}
}