#introMeuble{
	padding: 3.4375vw 0 2.65625vw 0;
	.vague-rond{
		position: absolute;
		top: 18.489583333333336vw;
		right: 0;
	}
	&:after{
		display: table;
		content: "";
		clear: both;
	}
	.wrap{
		max-width: 82.55208333333334vw;
		margin: 0 auto;
		#left{
			float: left;
			width: 36.51041666666667vw;
			@media screen and (max-width: 767px){
				float: initial;
				width: 100%;
				margin: 0 0 30px 0;
			}
			#prodGal{
				.slick-arrow{
					display: none !important;
					z-index: 9;
					@media screen and (max-width: 767px){
						display: block !important;
					}
					&.slick-next{
						right: 0 !important;
					}
					&.slick-prev{
						left: 0 !important;
					}
				}
				.slick-list{

					.slick-track{
						
						.slick-slide{
							background-size: contain;
							background-repeat: no-repeat;
							background-position: center center;
							height: 36.51041666666667vw;
							@media screen and (max-width: 767px){
								height: 300px;
							}
						}
					}
				}
			}
			#prodGal-nav{
				margin: 15px 0 0 0;
				@media screen and (max-width: 767px){
					display: none;
				}
				.slick-arrow{
					background: $bleu-t;
					width: 38px;
					height: 38px;
					z-index: 1;
					&:before{
						background-image: url(../images/arrow-gal.svg);
						background-repeat: no-repeat;
						background-size: 100% 100%;
						background-position: center center;
						position: absolute;
						top: 50%;
						left: 50%;
						width: 10px;
						height: 21px;
						transform: translate(-50%, -50%);
						content: "" !important;
						filter: invert(100%);
						opacity: 1 !important;
					}
					&.slick-prev{
						left: 0 !important;
						&:before{
							transform: translate(-50%, -50%) rotate(180deg);
						}
					}
					&.slick-next{
						right: 0 !important;
					}
				}
				.slick-list{
					padding: 0 !important;
					margin: 0 -7px !important;
					.slick-track{
						
						.slick-slide{
							background-size: contain;
							background-repeat: no-repeat;
							background-position: center center;
							height: 11.666666666666668vw;
							margin: 0 7px !important;
						}
					}
				}
			}
		}
		#right{
			float: left;
			width: calc(100% - 36.51041666666667vw);
			padding: 0 0 0 4.479166666666667vw;
			@media screen and (max-width: 767px){
				float: initial;
				width: 100%;
				padding: 0;
			}
			#rightInt{
				max-width: 28.697916666666668vw;
				@media screen and (max-width: 1280px){
					max-width: 100%;
				}
				#listOptions{
					margin: 2.0833333333333335vw 0 0 0;
					table{
						width: 100%;
						tr{
							& + tr{
								border-top: 1px solid #ccc;
							}
							td{
								color: $bleu-f;
								font-size: 0.8854166666666667vw;
								font-family: $r-bold;
								letter-spacing: 0.01em;
								text-transform: uppercase;
								vertical-align: top;
								padding: 5px 0;
								@media screen and (max-width: 1280px){
									font-size: 13px;
									line-height: 18px;
								}
								span{
									display: block;
									color: $bleu-f;
									font-size: 0.8854166666666667vw;
									font-family: $r-reg;
									letter-spacing: 0.01em;
									text-transform: initial;
									@media screen and (max-width: 1280px){
										font-size: 13px;
										line-height: 18px;
									}
								}
							}
						}
					}
				}
				#bottom{
					display: table;
					width: 100%;
					margin: 3.125vw 0 0 0;
					@media screen and (max-width: 767px){
						margin: 30px 0 0 0;
					}
					@media screen and (max-width: 580px){
						display: table;
					}
					.partBottom{
						display: table-cell;
						vertical-align: middle;
						text-align: center;
						@media screen and (max-width: 580px){
							display: block;
							float: left;
							width: 50%;
							margin: 0 0 30px;
							&:nth-child(3){
								width: 100%;
							}
						}
						img{
							display: block;
							margin: 0 auto 10px;
						}
						span{
							color: $bleu-t;
							letter-spacing: 0.01em;
							display: block;
							line-height: 100%;
							&:nth-child(2){
								font-family: $r-bold;
								font-size: 2.447916666666667vw;
								@media screen and (max-width: 1280px){
									font-size: 31px;
								}
							}
							&:nth-child(3){
								font-family: $r-reg;
								font-size: 0.8854166666666667vw;
								text-transform: uppercase;
								@media screen and (max-width: 1280px){
									font-size: 13px;
								}
							}
						}
					}
				}
				#popReserve{
					#contenu{

					}
				}
				#tete{
					h1{
						color: $bleu-f;
						font-size: 3.1770833333333335vw;
						font-family: $s-reg;
						line-height: 4.0625vw;
						letter-spacing: 0.01em;
						@media screen and (max-width: 1280px){
							font-size: 42px;
							line-height: 54px;
						}
					}
					.sous{
						color: $bleu-t;
						font-size: 0.8854166666666667vw;
						font-family: $r-reg;
						line-height: 1.3541666666666667vw;
						letter-spacing: 0.01em;
						@media screen and (max-width: 1280px){
							font-size: 13px;
							line-height: 19px;
						}
					}
					#bottom{
						position: relative;
						margin: 1.0416666666666667vw 0 0 0;
						span{

							&#price{
								float: left;
								color: $bleu-t;
								font-size: 2.447916666666667vw;
								font-family: $r-bold;
								line-height: 3.697916666666667vw;
								letter-spacing: 0.01em;
								@media screen and (max-width: 1280px){
									font-size: 31px;
									line-height: 47px;
								}
							}
							&#reserver{
								position: absolute !important;
								right: 0;
								top: 50%;
								cursor: pointer;
								position: relative;
								display: table;
								color: $bleu-p;
								font-size: 1.09375vw;
								font-family: $r-med;
								letter-spacing: 0.01em;
								text-transform: uppercase;
								transition-property: color;
								transition-duration: 0.3s;
								transition-delay: 0.3s;
								z-index: 1;
								height: 1.6145833333333335vw;
								transform: translate(0, -50%);
								@media screen and (max-width: 1280px){
									font-size: 14px;
									height: 20px;
								}
								&:before{
									background: $bleu-p;
									position: absolute;
									display: table;
									content: "";
									bottom: -0.625vw;
									width: 0px;
									height: 0px;
									transition-property: width, height;
									transition-duration: 0.3s, 0.3s;
									transition-delay: 0.3s, 0.3s;
									z-index: -1;
									left: 50%;
									transform: translate(-50%, 0);
								}
								&:after{
									background: $bleu-p;
									position: absolute;
									display: table;
									content: "";
									bottom: -0.625vw;
									width: 100%;
									height: 4px;
									transition-property: width;
									transition-duration: 0.3s;
									@media screen and (min-width: 1920px){
										height: 0.20833333333333334vw;
									}
								}
								&:hover{
									color: #fff;
									&:before{
										width: calc(100% + 1.5625vw);
										height: calc(100% + (0.625vw * 2));
									}
									&:after{
										width: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}