#introNew {
    padding: 3.4375vw 0 0 0;
    @media screen and (max-width: 767px) {
        padding: 15px 0 0 0;
    }
    .vague-rond-bleu {
        position: absolute;
        right: 0;
        top: 8.71875vw;
    }
    #left {

        width: 80%;
        max-width: 46.302083333333336vw;
        float: left;
        @media screen and (max-width: 1280px) {
            max-width: 37vw;
        }
        @media screen and (max-width: 767px) {
            max-width: 100%;
            float: initial;
            margin: 0 auto;
            width: 100%;
        }
        #partSlide {
            width: 100%;
            max-width: 37.239583333333336vw;
            float: right;
            @media screen and (max-width: 1280px) {
                max-width: 28vw;
            }
            @media screen and (max-width: 767px) {
                max-width: calc(100% - 80px);
                margin: 0 auto;
                float: initial;
            }
            &:after {
                display: table;
                content: "";
                clear: both;
            }
            #newSlide {
                .slick-arrow {
                    display: none !important;
                    z-index: 3;
                    @media screen and (max-width: 767px) {
                        display: block !important;
                    }
                    &.slick-next {
                        right: 0 !important;
                    }
                    &.slick-prev {
                        left: 0 !important;
                    }
                }
                .slick-list {

                    .slick-track {

                        .slick-slide {
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center center;
                            height: 36.51041666666667vw;
                            @media screen and (max-width: 1280px) {
                                height: 27vw;
                            }
                            @media screen and (max-width: 767px) {
                                height: 300px;
                            }
                        }
                    }
                }
            }
            #newSlideNav {
                max-width: calc(100% - 30px);
                margin: 15px auto 0;
                @media screen and (max-width: 767px) {
                    display: none;
                }
                .slick-arrow {
                    background: $bleu-t;
                    width: 38px;
                    height: 38px;
                    z-index: 1;
                    &:before {
                        background-image: url(../images/arrow-gal.svg);
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        background-position: center center;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 10px;
                        height: 21px;
                        transform: translate(-50%, -50%);
                        content: "" !important;
                        filter: invert(100%);
                        opacity: 1 !important;
                    }
                    &.slick-prev {
                        left: 0 !important;
                        &:before {
                            transform: translate(-50%, -50%) rotate(180deg);
                        }
                    }
                    &.slick-next {
                        right: 0 !important;
                    }
                }
                .slick-list {
                    padding: 0 !important;
                    margin: 0 -7px !important;
                    .slick-track {

                        .slick-slide {
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center center;
                            height: 11.666666666666668vw;
                            margin: 0 7px !important;
                            @media screen and (max-width: 1280px) {
                                height: 14vw;
                            }
                            &:before {
                                display: table;
                                content: "";
                                clear: both;
                            }
                        }
                    }
                }
            }
        }
        #garant {
            background: $bleu-t;
            clear: both;
            width: calc(100% - 15px);
            padding: 4.583333333333334vw 8.177083333333334vw 4.583333333333334vw 0;
            margin: 15px 0 0 0;
            @media screen and (max-width: 1280px) {
                padding: 70px 0 70px 0;
            }
            @media screen and (max-width: 767px) {
                display: none;
            }
            #contenu {
                max-width: 28.28125vw;
                float: right;
                h3 {
                    color: #fff;
                    font-size: 3.1770833333333335vw;
                    font-family: $s-reg;
                    line-height: 100%;
                    letter-spacing: 0.01em;
                    margin: 0 0 1.5625vw 0;
                    @media screen and (max-width: 1280px) {
                        font-size: 42px;
                    }
                    @media screen and (max-width: 767px) {
                        font-size: 20px;
                    }
                }
                p {
                    color: #fff;
                    font-size: 0.8854166666666667vw;
                    font-family: $r-reg;
                    line-height: 1.3541666666666667vw;
                    letter-spacing: 0.01em;
                    @media screen and (max-width: 1280px) {
                        font-size: 12px;
                        line-height: 19px;
                        padding: 0 30px 0 0;
                    }
                }
                ul {
                    margin: 10px 0 0 0;
                    li {
                        position: relative;
                        display: block;
                        padding: 0 0 0 30px;
                        color: #fff;
                        font-size: .8854166666666667vw;
                        font-family: robotoReg;
                        line-height: 1.3541666666666667vw;
                        letter-spacing: .01em;
                        &+li {
                            margin: 7px 0 0 0;
                        }
                        &:before {
                            background: #fff;
                            position: absolute;
                            display: block;
                            content: "";
                            float: left;
                            width: 15px;
                            height: 2px;
                            left: 0;
                            top: 50%;
                            transform: translate(0, -50%);
                        }
                    }
                }
            }
        }
    }
    #right {
        width: 44.739583333333336vw;
        float: left;
        position: relative;
        @media screen and (max-width: 1280px) {
            width: 54vw;
        }
        @media screen and (max-width: 767px) {
            min-height: auto !important;
            float: initial;
            width: 80%;
            margin: 0 auto;
        }
        @media screen and (max-width: 580px) {
            width: 90%;
        }
        #contenu {
            background: #fff;
            position: relative;
            max-width: 36.458333333333336vw;
            padding: 0 3.9583333333333335vw 4.114583333333334vw;
            z-index: 1;
            @media screen and (max-width: 1280px) {
                max-width: 45vw;
            }
            @media screen and (max-width: 991px) {
                max-width: 100%;
            }
            @media screen and (max-width: 767px) {
                padding: 60px 0;
            }
            #tete {
                margin: 0 0 2.0833333333333335vw 0;
                h1 {
                    color: $bleu-f;
                    font-size: 3.1770833333333335vw;
                    font-family: $s-reg;
                    line-height: 4.0625vw;
                    letter-spacing: 0.01em;
                    @media screen and (max-width: 1280px) {
                        font-size: 42px;
                        line-height: 54px;
                    }
                }
                span {
                    color: $bleu-t;
                    font-size: 0.8854166666666667vw;
                    font-family: $r-reg;
                    line-height: 1.3541666666666667vw;
                    letter-spacing: 0.01em;
                    @media screen and (max-width: 1280px) {
                        font-size: 13px;
                        line-height: 19px;
                    }
                }
            }
            p {
                color: $bleu-f;
                font-size: 0.8854166666666667vw;
                font-family: $r-reg;
                line-height: 1.3541666666666667vw;
                letter-spacing: 0.01em;
                @media screen and (max-width: 1280px) {
                    font-size: 13px;
                    line-height: 19px;
                }
            }
            .titleDispo {
                color: $bleu-f;
                font-size: 20px;
                font-family: $r-med;
                text-transform: uppercase;
                letter-spacing: 0.01em;
                margin: 30px 0 15px 0;
            }
            ul {

                li {
                    &+li {
                        margin: 30px 0 0 0;
                    }
                    h5 {
                        margin: 0 0 5px 0;
                        color: $bleu-f;
                        font-size: 16px;
                        font-family: $r-med;
                        letter-spacing: 0.01em;
                        text-transform: uppercase;
                    }
                    p {
                        margin: 0 0 5px 0;
                        color: $bleu-f;
                        font-size: 16px;
                        font-family: $r-reg;
                        letter-spacing: 0.01em;
                    }
                }
            }
            #dimensions {
                margin: 40px 0;
                span {
                    display: block;
                    &:nth-child(1) {
                        color: $bleu-f;
                        font-size: 0.8854166666666667vw;
                        font-family: $r-bold;
                        line-height: 100%;
                        letter-spacing: 0.01em;
                        text-transform: uppercase;
                        margin: 0 0 0.78125vw 0;
                        @media screen and (max-width: 1280px) {
                            font-size: 13px;
                        }
                    }
                    &:nth-child(2) {
                        color: $bleu-f;
                        font-size: 0.8854166666666667vw;
                        font-family: $r-reg;
                        line-height: 100%;
                        letter-spacing: 0.01em;
                        @media screen and (max-width: 1280px) {
                            font-size: 13px;
                        }
                    }
                }
            }
            #colorDispo {
                color: $bleu-t;
                font-size: 0.8854166666666667vw;
                font-family: $r-italic;
                line-height: 100%;
                letter-spacing: 0.01em;
                @media screen and (max-width: 1280px) {
                    font-size: 13px;
                }
            }
            .secMobile {
                display: none;
                @media screen and (max-width: 767px) {
                    display: block;
                }
                &#first {
                    margin: 30px 0 20px 0;
                    #newSlideM {
                        .slick-list {

                            .slick-track {

                                .slick-slide {
                                    background-size: contain;
                                    background-repeat: no-repeat;
                                    background-position: center center;
                                    height: 250px;
                                }
                            }
                        }
                    }
                }
                &#second {
                    background: $bleu-t;
                    padding: 40px 0;
                    margin: 40px 0 0 0;
                    .contenu {
                        padding: 0 40px;
                        h3 {
                            color: #fff;
                            font-size: 3.1770833333333335vw;
                            font-family: $s-reg;
                            line-height: 100%;
                            letter-spacing: 0.01em;
                            @media screen and (max-width: 1280px) {
                                font-size: 42px;
                            }
                        }
                        p {
                            color: #fff;
                            font-size: 0.8854166666666667vw;
                            font-family: $r-reg;
                            line-height: 1.3541666666666667vw;
                            letter-spacing: 0.01em;
                            @media screen and (max-width: 1280px) {
                                font-size: 12px;
                                line-height: 19px;
                            }
                        }
                    }
                }
            }
   #bottom {
                  display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 3.125vw 0 0;
                    @media screen and (max-width: 767px) {
                      
                    }
                    @media screen and (max-width: 580px) {
                       
                    }
                    .partBottom {
                             text-align: center;
    display: flex;

    flex-direction: column;
                        @media screen and (max-width: 580px) {
//                             display: block;
//                             float: left;
//                             width: 50%;
//                             margin: 0 0 30px;
                            &:nth-child(3) {
                              
                            }
                        }
                        img {
                               display: block;
                         height: 63px;
                         @include center;
                         margin: auto;
                        }
                        span {
                            color: $bleu-t;
                            letter-spacing: 0.01em;
                            display: block;
                            line-height: 100%;
                            &:nth-child(2) {
                                font-family: $r-bold;
                                font-size: 2.447916666666667vw;
                                @media screen and (max-width: 1280px) {
                                    font-size: 31px;
                                }
                            }
                            &:nth-child(3) {
                                font-family: $r-reg;
                                font-size: 0.8854166666666667vw;
                                text-transform: uppercase;
                                @media screen and (max-width: 1280px) {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
        }
        #secondImg {
            background-size: cover;

            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            width: 100%;
            height: 45vw;
            //        top: 16.7vw;
            bottom: 0;
            @media screen and (max-width: 767px) {
                display: none;
            }
        }
    }
}


#introUse {
    padding: 3.4375vw 0 2.65625vw 0;
        @media screen and (max-width: 1281px) {
                padding-top:8vw;
       

        }
    @media screen and (max-width: 767px) {
        padding: 15px 0 10px 0;
    }
    &:after {
        display: table;
        content: "";
        clear: both;
    }
    #sceau {
        background-image: url(../images/sceau-garantie.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-position: center center;
        position: absolute;
        width: 13.854166666666668vw;
        height: 11.354166666666668vw;
        top: 2.34375vw;
        right: 8.333333333333334vw;
        @media screen and (max-width: 767px) {
            width: 140px;
            height: 115px;
            z-index: 3;
    top: 230px!important;
    right: 5vw;
        }
    }
    .vague-rond {
        position: absolute;
        top: 18.489583333333336vw;
        right: 0;
    }
    .wrap {
        max-width: 82.55208333333334vw;
        margin: 0 auto;
        #left {
            float: left;
            width: 36.51041666666667vw;
            @media screen and (max-width: 767px) {
                float: initial;
                width: 100%;
                margin: 0 0 30px 0;
            }
            #prodGal {
                .slick-arrow {
                    display: none !important;
                    z-index: 3;
                    @media screen and (max-width: 767px) {
                        display: block !important;
                    }
                    &.slick-next {
                        right: 0 !important;
                    }
                    &.slick-prev {
                        left: 0 !important;
                    }
                }
                .slick-list {

                    .slick-track {

                        .slick-slide {
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center center;
                            height: 36.51041666666667vw;
                            @media screen and (max-width: 767px) {
                                height: 300px;
                            }
                        }
                    }
                }
            }
            #prodGal-nav {
                margin: 15px 0 0 0;
                @media screen and (max-width: 767px) {
                    display: none;
                }
                .slick-arrow {
                    background: $bleu-t;
                    width: 38px;
                    height: 38px;
                    z-index: 1;
                    &:before {
                        background-image: url(../images/arrow-gal.svg);
                        background-repeat: no-repeat;
                        background-size: 100% 100%;
                        background-position: center center;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 10px;
                        height: 21px;
                        transform: translate(-50%, -50%);
                        content: "" !important;
                        filter: invert(100%);
                        opacity: 1 !important;
                    }
                    &.slick-prev {
                        left: 0 !important;
                        &:before {
                            transform: translate(-50%, -50%) rotate(180deg);
                        }
                    }
                    &.slick-next {
                        right: 0 !important;
                    }
                }
                .slick-list {
                    padding: 0 !important;
                    margin: 0 -7px !important;
                    .slick-track {

                        .slick-slide {
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-position: center center;
                            height: 11.666666666666668vw;
                            margin: 0 7px !important;
                        }
                    }
                }
            }
        }
        #right {
            float: left;
            width: calc(100% - 36.51041666666667vw);
            padding: 0 0 0 4.479166666666667vw;
            @media screen and (max-width: 767px) {
                float: initial;
                width: 100%;
                padding: 0;
            }
            #rightInt {
                max-width: 28.697916666666668vw;
                @media screen and (max-width: 1280px) {
                    max-width: 100%;
                }
                #listOptions {
                    margin: 2.0833333333333335vw 0 0 0;
                    table {
                        width: 100%;
                        tr {
                            &+tr {
                                border-top: 1px solid #ccc;
                            }
                            td {
                                color: $bleu-f;
                                font-size: 0.8854166666666667vw;
                                font-family: $r-bold;
                                letter-spacing: 0.01em;
                                text-transform: uppercase;
                                vertical-align: top;
                                padding: 5px 0;
                                @media screen and (max-width: 1280px) {
                                    font-size: 13px;
                                    line-height: 18px;
                                }
                                span {
                                    display: block;
                                    color: $bleu-f;
                                    font-size: 0.8854166666666667vw;
                                    font-family: $r-reg;
                                    letter-spacing: 0.01em;
                                    text-transform: initial;
                                    @media screen and (max-width: 1280px) {
                                        font-size: 13px;
                                        line-height: 18px;
                                    }
                                }
                            }
                        }
                    }
                }
                #bottom {
                  display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 3.125vw 0 0;
                    @media screen and (max-width: 767px) {
                      
                    }
                    @media screen and (max-width: 580px) {
                       
                    }
                    .partBottom {
                             text-align: center;
    display: flex;

    flex-direction: column;
                        @media screen and (max-width: 580px) {
//                             display: block;
//                             float: left;
//                             width: 50%;
//                             margin: 0 0 30px;
                            &:nth-child(3) {
                              
                            }
                        }
                        img {

                            @include bp('l'){
                                    height: 40px;
    width: 40px;
    margin: 0 auto 10px;
                            }
                               display: block;
                         height: 63px;
                         @include center;
                         margin: auto;
                        }
                        span {
                            color: $bleu-t;
                            letter-spacing: 0.01em;
                            display: block;
                            line-height: 100%;
                            &:nth-child(2) {
                                font-family: $r-bold;
                                font-size: 2.447916666666667vw;
                                @media screen and (max-width: 1280px) {
                                    font-size: 31px;
                                }
                            }
                            &:nth-child(3) {
                                font-family: $r-reg;
                                font-size: 0.8854166666666667vw;
                                text-transform: uppercase;
                                @media screen and (max-width: 1280px) {
                                    font-size: 13px;
                                }
                            }
                        }
                    }
                }
                #popReserve {
                    #contenu {}
                }
                #tete {
                    h1 {
                        color: $bleu-f;
                        font-size: 3.1770833333333335vw;
                        font-family: $s-reg;
                        line-height: 4.0625vw;
                        letter-spacing: 0.01em;
                        @media screen and (max-width: 1280px) {
                            font-size: 42px;
                            line-height: 54px;
                        }
                    }
                    .sous {
                        color: $bleu-t;
                        font-size: 0.8854166666666667vw;
                        font-family: $r-reg;
                        line-height: 1.3541666666666667vw;
                        letter-spacing: 0.01em;
                        @media screen and (max-width: 1280px) {
                            font-size: 13px;
                            line-height: 19px;
                        }
                    }
                    #bottom {
                        min-height:5vw;
                        position: relative;
                        margin: 1.0416666666666667vw 0 0 0;
                        span {

                            &#price {
                                float: left;
                                color: $bleu-t;
                                font-size: 2.447916666666667vw;
                                font-family: $r-bold;
                                line-height: 3.697916666666667vw;
                                letter-spacing: 0.01em;
                                @media screen and (max-width: 1280px) {
                                    font-size: 31px;
                                    line-height: 47px;
                                }
                                 @media screen and (max-width: 400px) {
                                        font-size: 7vw;
                                }
                               
                            }
                            &#reserver {
                                position: absolute !important;
                                right: 0;
                                top: 50%;
                                cursor: pointer;
                                position: relative;
                                display: table;
                                color: $bleu-p;
                                font-size: 1.09375vw;
                                font-family: $r-med;
                                letter-spacing: 0.01em;
                                text-transform: uppercase;
                                transition-property: color;
                                transition-duration: 0.3s;
                                transition-delay: 0.3s;
                                z-index: 1;
                                height: 1.6145833333333335vw;
                                transform: translate(0, -50%);
                                @media screen and (max-width: 1280px) {
                                    font-size: 14px;
                                    height: 20px;
                                }
                                &:before {
                                    background: $bleu-p;
                                    position: absolute;
                                    display: table;
                                    content: "";
                                    bottom: -0.625vw;
                                    width: 0px;
                                    height: 0px;
                                    transition-property: width, height;
                                    transition-duration: 0.3s, 0.3s;
                                    transition-delay: 0.3s, 0.3s;
                                    z-index: -1;
                                    left: 50%;
                                    transform: translate(-50%, 0);
                                }
                                &:after {
                                    background: $bleu-p;
                                    position: absolute;
                                    display: table;
                                    content: "";
                                    bottom: -0.625vw;
                                    width: 100%;
                                    height: 4px;
                                    transition-property: width;
                                    transition-duration: 0.3s;
                                    @media screen and (min-width: 1920px) {
                                        height: 0.20833333333333334vw;
                                    }
                                }
                                &:hover {
                                    color: #fff;
                                    &:before {
                                        width: calc(100% + 1.5625vw);
                                        height: calc(100% + (0.625vw * 2));
                                    }
                                    &:after {
                                        width: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#usageSlide {
    .goutte {
        position: absolute;
        z-index: 3;
        top: 50%;
        left: 4.947916666666667vw;
        transform: translate(0, -50%);
    }
    #sUsage {
        .slick-arrow {
            background-color: $bleu-f;
            background-image: url(../images/arrow-slide-use.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 1.3541666666666667vw 52px;
            z-index: 1;
            width: 4.895833333333334vw;
            height: 4.895833333333334vw;
            @media screen and (max-width: 991px) {
                width: 100px;
                height: 100px;
                    background-size: 22px 43px;
            }
            &:before {
                display: none;
            }
            &.slick-prev {
                right: 0 !important;
                left: initial !important;
                top: calc(50% - (5.833333333333334vw / 2)) !important;
                transform: translate(0, -50%) rotate(180deg);
                @media screen and (max-width: 991px) {
                           top: inherit!important;
    bottom: -51px;
    right: 102px!important;
                }
            }
            &.slick-next {
                right: 0 !important;
                top: initial !important;
                bottom: calc(50% - (5.833333333333334vw / 2)) !important;
                transform: translate(0, 50%);
                @media screen and (max-width: 991px) {
                    top: inherit!important;
    bottom: 49px!important;
                }
            }
        }
        .slick-list {
            padding: 0 !important;
            margin: 0 !important;
            .slick-track {

                .slick-slide {
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center center;
                    height: 36.97916666666667vw;
                    margin: 0 !important;
                    @media screen and (max-width: 991px) {
                            height: 460px;
                    }
                    @media screen and (max-width: 580px) {
                               height: 738px;
                    }
                    .slideInt {

                        position: absolute;
                        width: 100%;
                        max-width: 66.40625vw;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        &:after {
                            display: table;
                            content: "";
                            clear: both;
                        }
                        @media screen and (max-width: 991px) {
                                max-width: 83.40625vw;
                            top: 40%;
                                    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: right;
    align-items: flex-end;
      
                            // max-width: 660px;
                            // position: relative;
                            // top: initial;
                            // left: initial;
                            // transform: initial;
                            // margin: 0 auto;
                            // padding: 80px 0;
                        }
                        @media screen and (max-width: 767px) {
                            // padding: 80px 40px;
                        }
                        @media screen and (max-width: 580px) {
                            // padding: 40px;
                        }
                        h1 {
                            position: absolute;
                            color: $bleu-p;
                            font-size: 6.822916666666667vw;
                            font-family: $r-black;
                            letter-spacing: 0.01em;
                            line-height: 6.822916666666667vw;
                            text-transform: uppercase;
                            @media screen and (max-width: 1280px) {
                                // opacity: 0.25;
                                // font-size: 76px;
                                // line-height: 76px;
                            }
                            @media screen and (max-width: 991px) {
                                // top: 80px !important;
                                font-size: 50px!important;
         max-width: 268px!important;
    position: static;
                            }
                            @media screen and (max-width: 580px) {
                                // top: 40px !important;
                            }
                        }
                        p {
                            color: #fff;
                            font-size: 1.3541666666666667vw;
                            font-family: $s-reg;
                            line-height: 2.03125vw;
                            letter-spacing: 0.1em;
                            @media screen and (max-width: 1280px) {
                                // font-size: 16px;
                                // line-height: 25px;
                            }
                            @media screen and (max-width: 991px) {
                                // margin: 0 !important;
                                    margin-top: 1vw!important;
    font-size: 21px!important;
    max-width: 100%!important;
                            }
                            @media screen and (max-width: 580px) {
                                    font-size: 5vw!important;
                                // float: initial !important;
                            }
                        }
                    }
                    &.s0 {
                        .slideInt {
                            h1 {
                                width: 100%;
                                  max-width: 33vw;
                                top: 0;
                                left: 0;
                                @media screen and (max-width: 1280px) {
//                                     max-width: 450px;
                                }
                            }
                            p {
                                margin: 6.5625vw 0 0 0;
                                max-width: 43.69791666666667vw;
                                float: right;
                                @media screen and (max-width: 1280px) {
                                    // max-width: 80%;
                                    &:after {
                                        // display: table;
                                        // content: "";
                                        // clear: both;
                                    }
                                }
                                @media screen and (max-width: 580px) {
                                    // max-width: 90%;
                                }
                            }
                        }
                    }
                    &.s1 {
                        .slideInt {
                            h1 {
                                width: 100%;
                                max-width: 500px;
                                right: 0;
                                text-align: right;
                                font-size: 5.989583333333334vw;
                                line-height: 5.989583333333334vw;
                                @media screen and (max-width: 1280px) {
                                    // opacity: 0.25;
                                    // font-size: 76px;
                                    // line-height: 76px;
                                    // max-width: 450px;
                                }
                            }
                            p {
                                margin: 6.770833333333334vw 0 0 0;
                                max-width: 36.458333333333336vw;
                                text-align: right;
                                font-size: 1.1458333333333335vw;
                                line-height: 1.8229166666666667vw;
                                @media screen and (max-width: 1280px) {
                                    // font-size: 16px;
                                    // line-height: 25px;
                                    // max-width: 80%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#fonctionnalite {
    padding: 5.208333333333334vw 0 9.895833333333334vw 0;
    margin-bottom: 15vw;
    .image{
        @media screen and (max-width: 580px) {
            height:50vw;
        }
     margin: 1vw 0;
    height: 200px;
    width: 100%;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
         
    }
    .circle-d {
        position: absolute;
        left: 2.447916666666667vw;
        bottom: -8vw;
    }
    .tab_content {
        display: flex;
        flex-wrap: wrap;
    }
    .wrap {
        &.fonctionalite {}
        &.options {
                h1 {
                   
                position: relative;
                left: 70vw;
            }

            }
        

        &.specification {
            .tab_content {
                flex-direction: column;
                width: 100%;
            }
            .tab_text {
                border-bottom: 0.15vw #e1e1e1 solid;
                width: 100%;
                margin: 0;
            }
            .foncInt {
            	margin:0.3vw 0!important;
                width: 100%;
                display: flex;
                flex-direction: row;
                h4 {
                    width: 33vw;
                    margin: 0!important;
                }
                p {
                    width: 50%;
                }
            }
            h1 {
                position: relative;
                left: 36vw;
            }
        }





        margin:0 9.1vw 5vw;
        position:absolute;
        width: 82vw;
        display: flex;
        flex-wrap: wrap;

        h1 {
            color:#e1e1e1;
            font-size: 3.1770833333333335vw;
            line-height: 4.0625vw;
            letter-spacing: 0.01em;
            display: block;
            clear: both;
            margin: 0 0 3.125vw 0;
            width: 100%;
            font-family: $s-reg;
            @media screen and (max-width: 1280px) {
                margin: 0 0 60px 0;
                font-size: 40px;
                line-height: 100%;
            }
            @media screen and (max-width: 991px) {
                margin: 0 0 40px 0;
            }
        }

        .fonc-by-fonc {
            width: 25%;
            margin: 0 0 3.125vw 0;
            @media screen and (max-width: 1280px) {
                width: 33.3333333333%;
            }
            @media screen and (max-width: 991px) {
                width: 50%;
                margin: 0 0 30px;
            }
            @media screen and (max-width: 767px) {
                width: 100%;
            }
            .foncInt {
                max-width: calc(100% - 20px);
                margin: 0 auto;

                .imgFonc {
                    background-size: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                    height: 13.333333333333334vw;
                    @media screen and (max-width: 1280px) {
                        height: 250px;
                    }
                }
                h4 {
                    color: $bleu-f;
                    font-size: 0.8854166666666667vw;
                    line-height: 1.3541666666666667vw;
                    font-family: $r-bold;
                    letter-spacing: 0.01em;
                    margin: 1.0416666666666667vw 0;
                    @media screen and (max-width: 1280px) {
                        font-size: 13px;
                        line-height: 19px;
                    }
                }
                p {
                    color: $bleu-f;
                    font-size: 0.8854166666666667vw;
                    line-height: 1.3541666666666667vw;
                    font-family: $r-reg;
                    letter-spacing: 0.01em;
                    @media screen and (max-width: 1280px) {
                        font-size: 13px;
                        line-height: 19px;
                    }
                }
            }
        }
    }
}